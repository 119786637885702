import { isEmpty } from 'ramda';

export const isEmptyCustom = value =>
    value === null ||
    typeof value === 'undefined' ||
    isEmpty(value) ||
    (typeof value === 'string' && isEmpty(value.trim()));

export const tryParseJson = jsonString => {
    try {
        let parsedJson = JSON.parse(jsonString);

        if (parsedJson && typeof parsedJson == 'object') {
            return parsedJson;
        }
    } catch (e) {
        /* empty */
    }

    return false;
};

export const objectsMatch = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
        return false;
    }
    const aKeys = Object.keys(a);
    const aLength = aKeys.length;
    const bLength = Object.keys(b).length;

    if (aLength !== bLength) return false;

    const matches = aKeys.map(key => {
        return a[key] === b[key];
    });
    return !matches.includes(false);
};

// Clusivity options
export const EXCLUDE = 'EXCLUDE';
// export const INCLUDE = 'INCLUDE';

// export const NA = 'NA';
// export const CLUSIVITY_OPTIONS = {
//     [INCLUDE]: { context: 'success', bdColorContext: 'suc' },
//     [EXCLUDE]: { context: 'danger', bdColorContext: 'dan' },
//     [NA]: { context: 'warning', bdColorContext: 'war' },
// };
// export const BROWSER_TYPE_OPTIONS = [
//     { value: 'Browser', name: 'Desktop Browser' },
//     { value: 'Mobile browser', name: 'Mobile browser' },
//     { value: 'Tablet', name: 'Tablet' },
// ];

//entity types
// export const ZONE = 'zone';

// Restriction Types
// export const BROWSER_PROFILE = 'BROWSER_PROFILE';
// export const BUSINESS_UNIT_ID = 'BUSINESS_UNIT_ID';
// export const COUNTRY = 'COUNTRY';
// export const METRO_CODE = 'METRO_CODE';
// export const RESTRICTION_TYPES_SCHEMA = {
//     [BUSINESS_UNIT_ID]: { idVar: 'id', nameVar: 'name' },
//     [COUNTRY]: { idVar: 'code', nameVar: 'name' },
//     [METRO_CODE]: { idVar: 'code', nameVar: 'name' },
// };

// const _formatBrowserProfile = (restriction: {
//     type: any;
//     osFamily: any;
//     osName: any;
//     browserFamily: any;
//     browserName: any;
// }) =>
//     `${restriction.type} > ${restriction.osFamily} > ${restriction.osName} > ${restriction.browserFamily} > ${restriction.browserName}`;

// export const CUSTOM_RESTRICTION_TYPES_DISPLAY_SCHEMA = {
//     [BROWSER_PROFILE]: {
//         formatMethod: _formatBrowserProfile,
//     },
// };

// export const RESTRICTION_TYPES = [
//     {
//         name: 'ADVERTISER_DOMAIN',
//         description: 'Advertiser Domain',
//     },
//     {
//         name: 'ADVERTISER_ID',
//         description: 'Advertiser',
//     },
//     {
//         name: 'AD_BUY_ID',
//         description: 'Ad Line',
//     },
//     {
//         name: 'BROWSER_PROFILE',
//         description: 'Browser Profile',
//     },
//     {
//         name: 'BUSINESS_UNIT_ID',
//         description: 'Business Unit',
//     },
//     {
//         name: 'CAMPAIGN_ID',
//         description: 'Campaign',
//     },
//     { name: 'CITY', description: 'City' },
//     { name: 'COUNTRY', description: 'Country' },
//     { name: 'DEMAND_SOURCE_ID', description: 'Demand Source' },
//     { name: 'GLOBAL_ADVERTISER_ID', description: 'Global Advertiser' },
//     {
//         name: 'IAB_CATEGORY_ID_V1',
//         description: 'IAB Category V1',
//     },
//     { name: 'INDUSTRY_CATEGORY_ID', description: 'Industry Category' },
//     { name: 'INDUSTRY_ID', description: 'Industry' },
//     { name: 'METRO_CODE', description: 'Metro Code' },
//     { name: 'REGION', description: 'Region' },
// ];

import { useDeleteRestrictionsMutation } from '../blocks/restrictionApiSlice';
import { PUBLISHERS, ZONES } from '../../constants/app';
import { useAppSelector } from '../../store';
import { IABBlockedCategory } from '../../types/IABCategories';
import { useParams } from 'react-router-dom';

const useDeleteIABRestriction = (): {
    deleteRestrictions: (
        deletedCategories: IABBlockedCategory[],
        categoryIds: string[],
    ) => Promise<void>;
    isLoading: boolean;
} => {
    const { zoneId } = useParams();
    const [deleteRestrictions, { isLoading }] = useDeleteRestrictionsMutation();
    const publisherId = useAppSelector(state => state.publisher.id);

    const deleteIABRestriction = async (deletedCategories: IABBlockedCategory[]) => {
        await deleteRestrictions({
            entity: zoneId ? ZONES : PUBLISHERS,
            entityId: zoneId || publisherId,
            restrictionIds: deletedCategories.map(category => category.advertisingRestrictionId),
        });
    };

    return { deleteRestrictions: deleteIABRestriction, isLoading };
};

export default useDeleteIABRestriction;

// External libraries
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import storage from 'redux-persist/lib/storage';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

// RTK slices
import { baseApi } from './api/api';
import { authApi } from './features/auth/authApiSlice';

// Middleware
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import notificationMiddleware from './components/notifications/notificationMiddleware';

// Reducers
import authReducer from './features/auth/authReducer';
import publisherReducer from './features/Browse/publisherReducer';
import publishersReducer from './features/Browse/publishersReducer';
import loadingReducer from './reducers/loadingReducer';
import notificationsReducer from './components/notifications/notificationsReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'publisher', 'publishers'], // selects ONLY reducers to persist
};

const persistedReducer = persistCombineReducers(persistConfig, {
    auth: authReducer,
    notifications: notificationsReducer,
    publisher: publisherReducer,
    publishers: publishersReducer,
    loading: loadingReducer,
    [authApi.reducerPath]: authApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
    reducer: persistedReducer,
    // @ts-ignore
    enhancers: getDefaultEnhancers => {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
    // @ts-ignore
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(baseApi.middleware)
            .concat(authApi.middleware)
            .concat(notificationMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

import { IABParentCategory } from '../../types/IABCategories';
// @ts-ignore
import { Accordion } from 'gumdrops';
import Category from './Modal/Category/Category';

interface CategoriesListProps {
    iabCategories: IABParentCategory[];
    onChange: (selectedCategories: string[]) => void; //sets All selected categories!
    selectedCategories: string[];
}

const CategoriesList = ({ iabCategories, onChange, selectedCategories }: CategoriesListProps) => {
    const onChangeHandler = (categoryId: string) => {
        const parentId = categoryId.split('-')[0];
        let categories = [...selectedCategories];

        if (categories.includes(categoryId)) {
            // remove main category or category if not all categories selected
            categories = categories.filter(category => category !== categoryId);
        } else if (!categoryId.includes('-')) {
            // select main category
            categories = categories.filter(category => !category.startsWith(`${parentId}-`));
            categories.push(categoryId);
        } else {
            const parentCategory = iabCategories.find(category => category.id === parentId);
            const subCategories = parentCategory?.subCategories || [];

            if (categories.includes(parentId)) {
                // remove category if main category is selected
                const ids = subCategories
                    .filter(subCategory => subCategory.id !== categoryId)
                    .map(subCategory => subCategory.id);
                categories = categories.filter(category => category !== parentId);
                categories = [...categories, ...ids];
            } else {
                // select category
                categories.push(categoryId);
                const allSubCategories = subCategories.length;
                const selectedSubCategories = categories.filter(category =>
                    category.startsWith(`${parentId}-`),
                ).length;

                // if all categories selected, check main category
                if (allSubCategories === selectedSubCategories) {
                    categories = categories.filter(
                        category => !category.startsWith(`${parentId}-`),
                    );
                    categories.push(parentId);
                }
            }
        }
        onChange(categories);
    };

    return (
        <>
            <Accordion context={'white'} size={'sm'} style={{ overflow: 'scroll' }}>
                {iabCategories.map((category, index) => (
                    <Category
                        key={index}
                        iabCategory={category}
                        onChange={onChangeHandler}
                        selectedCategories={selectedCategories}
                    />
                ))}
            </Accordion>
        </>
    );
};

export default CategoriesList;

import cx from 'classnames';
// @ts-ignore
import ReactModal from 'react-modal';
import { ReactNode } from 'react';

interface ModalProps {
    onClose?: () => void;
    isOpen: boolean;
    shouldCloseOnOverlayClick: boolean;
    className: string;
    overlayClassName?: string;
    style?: React.CSSProperties;
    md: string;
    children: ReactNode;
}
// TODO: add shouldCloseOnOverlayClick on gumdrops and remove this component
const Modal = ({
    isOpen,
    children,
    md = '12',
    onClose,
    shouldCloseOnOverlayClick = false,
    className = '',
    overlayClassName = '',
    style = {},
}: ModalProps) => (
    <ReactModal
        className={cx('gds-modal', className, '-float-none', {
            [`gds-layout__column--md-${md}`]: md,
        })}
        overlayClassName={cx('gds-modal__overlay', 'gds-modal--shown', overlayClassName)}
        contentLabel="default"
        onRequestClose={onClose}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        style={style}
        ariaHideApp={false}>
        {children}
    </ReactModal>
);

export default Modal;

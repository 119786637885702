import Button from 'gumdrops/Button';
import Card from 'gumdrops/Card';
import Column from 'gumdrops/Column';
import LayoutContainer from 'gumdrops/LayoutContainer';
// Components
import Row from 'gumdrops/Row';
import Tooltip from 'gumdrops/Tooltip';
import { clone } from 'ramda';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QueryableTableWrapper from '../../../components/common/QueryableTableWrapper';
import TablePaginationFooter from '../../../components/common/TablePaginationFooter';
// Helpers
import { getSelectedValues } from '../../../helpers/mapDataMultiSelect';
import useQuery from '../../../hooks/useQuery';
import ZoneDomainIdentifierList from '../ZoneDomainIdentifierList';
// Hooks
import {
    useCreateZoneDomainSelfIdentifiersMutation,
    useGetSelfIdentifiersQuery,
    useGetZoneDomainSelfIdentifiersQuery,
} from './inclusiveInventoryApiSlice';
import { useAppSelector } from '../../../store';

const DEFAULT_QUERY_PARAMS = {
    page: '1',
    size: '20',
};

const InclusiveInventoryProgram = () => {
    const queryParams = useQuery(DEFAULT_QUERY_PARAMS);
    const { t } = useTranslation();
    const { page, size } = queryParams;
    const [isEditing, setIsEditing] = useState(false);
    const [zonesSelfIdentifiers, setZoneSelfIdentifiers] = useState([]);

    const publisherId = useAppSelector(state => state.publisher.id);

    const { data: selfIdentifiers = [] } = useGetSelfIdentifiersQuery();
    const { data: inclusiveInventoryData = {}, isLoading } = useGetZoneDomainSelfIdentifiersQuery(
        {
            publisherId,
            filters: {
                ...queryParams,
                page: page ? parseInt(page) - 1 : 0,
            },
        },
        {
            skip: !publisherId,
        },
    );

    const [createZoneDomainSelfIdentifiers] = useCreateZoneDomainSelfIdentifiersMutation();

    const handleSelfIdentifiersChange = (zoneDomainId, list) => {
        const selfIdentifierIds = list ? getSelectedValues(list) : [];
        const currentSelfIdentifiers = clone(zonesSelfIdentifiers);
        const newSelfIdentifiers = currentSelfIdentifiers.filter(
            item => item.zoneDomainId !== zoneDomainId,
        );

        setZoneSelfIdentifiers([
            ...newSelfIdentifiers,
            {
                zoneDomainId,
                selfIdentifierIds,
            },
        ]);
    };

    const _toggleEdit = () => {
        setIsEditing(!isEditing);
        setZoneSelfIdentifiers([]);
    };

    const _resetEdit = () => {
        setIsEditing(false);
        setZoneSelfIdentifiers([]);
    };

    const _handleSubmit = async () => {
        await createZoneDomainSelfIdentifiers({ publisherId, zonesSelfIdentifiers });

        _toggleEdit();
    };

    return (
        <QueryableTableWrapper queryParams={queryParams}>
            {({ handlePageChange, handleSizeChange }) => (
                <LayoutContainer>
                    <Row>
                        <Column md={12}>
                            <h1 className="gds-text--header-lg -m-b-4 -m-b-3-sm -float-left -float-none--sm">
                                {t('accountSettings.inclusiveInventory.title')}
                            </h1>
                            <div className="gds-flex gds-flex--justify-end">
                                <div className="buttons">
                                    {!isEditing && (
                                        <Tooltip
                                            text={t(
                                                'accountSettings.inclusiveInventory.tooltipEdit',
                                            )}
                                            position="left"
                                            size="xs">
                                            <Button
                                                name="edit"
                                                context="primary"
                                                className="-m-l-1"
                                                size="sm"
                                                onClick={_toggleEdit}
                                                data-cy="inclusive-inventory-program-edit-button">
                                                {t('accountSettings.inclusiveInventory.editButton')}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {isEditing && (
                                        <>
                                            <Button
                                                name="cancelEdit"
                                                context="secondary"
                                                className="-m-l-1"
                                                size="sm"
                                                onClick={_toggleEdit}
                                                data-cy="inclusive-inventory-program-cancel-button">
                                                {t(
                                                    'accountSettings.inclusiveInventory.cancelButton',
                                                )}
                                            </Button>
                                            <Tooltip
                                                text={t(
                                                    'accountSettings.inclusiveInventory.tooltipSave',
                                                )}
                                                position="bottom"
                                                size="xs">
                                                <Button
                                                    name="submit"
                                                    className="-m-l-1"
                                                    context="primary"
                                                    size="sm"
                                                    disabled={!zonesSelfIdentifiers.length}
                                                    onClick={_handleSubmit}
                                                    data-cy="inclusive-inventory-program-save-button">
                                                    {t(
                                                        'accountSettings.inclusiveInventory.saveButton',
                                                    )}
                                                </Button>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        <Card>
                            <ZoneDomainIdentifierList
                                zoneDomainIdentifiers={inclusiveInventoryData}
                                isEditMode={isEditing}
                                selfIdentifiersList={selfIdentifiers}
                                selectedZoneSelfIdentifiers={zonesSelfIdentifiers}
                                handleSelfIdentifiersChange={handleSelfIdentifiersChange}
                                isLoading={isLoading}
                            />
                            {!isEditing && (
                                <TablePaginationFooter
                                    totalPages={inclusiveInventoryData.totalPages}
                                    totalElements={inclusiveInventoryData.totalElements}
                                    handlePageChange={page => {
                                        _resetEdit();
                                        handlePageChange(page);
                                    }}
                                    handleSizeChange={size => {
                                        _resetEdit();
                                        handleSizeChange(size);
                                    }}
                                    activePage={parseInt(page)}
                                    size={parseInt(size)}
                                />
                            )}
                        </Card>
                    </Row>
                </LayoutContainer>
            )}
        </QueryableTableWrapper>
    );
};

export default InclusiveInventoryProgram;

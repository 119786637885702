import PropTypes from 'prop-types';

import Breadcrumbs from './Breadcrumbs';

const BreadcrumbWrapper = ({ navData }) => (
    <nav className="gds-breadcrumb" aria-label="Breadcrumb" data-cy="breadcrumb">
        <ol className="gds-breadcrumb__list">
            <Breadcrumbs navData={navData} />
        </ol>
    </nav>
);

BreadcrumbWrapper.propTypes = {
    navData: PropTypes.object.isRequired,
};

export default BreadcrumbWrapper;

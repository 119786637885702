import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import parseJWT from '../../helpers/parseJWT';
import { isEmptyCustom } from '../../helpers/utils';
import { useAppSelector } from '../../store';
import { setUserData } from '../auth/authReducer';
import { sendNotification } from '../../components/notifications/notificationsReducer';

const useTokenValidator = (): { verifyToken: () => boolean } => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { id: userId, jwt: storedJwt } = useAppSelector(state => state.auth);
    const internalUserJwt = new URLSearchParams(location.search).get('jwt');

    const verifyToken = () => {
        const jwt = internalUserJwt || storedJwt;
        const parsedToken = parseJWT(jwt);
        const currentTimeSeconds = Math.floor(Date.now() / 1000);
        const isJwtExpired = currentTimeSeconds > parsedToken?.exp;

        if (!jwt || isJwtExpired) {
            // dispatch(logout());
            return false;
        } else {
            if (!isEmptyCustom(parsedToken) && !isEmptyCustom(parsedToken.user)) {
                if (!parsedToken.user.enabled) {
                    dispatch(
                        sendNotification({
                            text: 'Your account is locked. Please reach out to the Advertising Apps team.',
                            context: 'danger',
                            msToClose: 0,
                        }),
                    );

                    return false;
                }
            }
            // if fresh login (user isn't in store)
            if (!userId) {
                dispatch(
                    setUserData({
                        id: parsedToken.user.id,
                        email: parsedToken.user.email,
                        name: parsedToken.user.name,
                        isInternal: parsedToken.isInternal,
                        ...(parsedToken.user.roles ? { roles: parsedToken.user.roles } : {}),
                        jwt,
                    }),
                );
            }
        }
        return !isJwtExpired;
    };

    return { verifyToken };
};

export default useTokenValidator;

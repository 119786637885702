// @ts-ignore
import { Checkbox, FormGroup } from 'gumdrops';

interface LabelCheckBoxProps {
    categoryId: string;
    name: string;
    label: string;
    checked: boolean;
    onChange: (categoryId: string) => void;
}
const LabelCheckBox = ({ categoryId, name, label, checked, onChange }: LabelCheckBoxProps) => {
    const onChangeHandler = () => {
        onChange(categoryId);
    };
    return (
        <FormGroup>
            <Checkbox
                className={'category-checkbox'}
                name={name}
                label={label}
                checked={checked}
                onChange={onChangeHandler}
            />
        </FormGroup>
    );
};

export default LabelCheckBox;

// External libraries
import * as Sentry from '@sentry/react';

// React
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Helpers
import { isEmptyCustom } from '../../helpers/utils';

// Components
import MainContent from './MainContent';
import ForgotPasswordPage from '../auth/ForgotPassword/ForgotPasswordPage';
import LoginPage from '../auth/Login/LoginPage';
import ResetPasswordPage from '../auth/ResetPassword/ResetPasswordPage';
import NotificationsManager from '../../components/notifications/NotificationsManager';
import ProtectedRoute from './ProtectedRoute';

// Actions
import useTokenValidator from './useTokenValidator';

// Store
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';

// Reducers and actions
import { clearPublisher } from '../Browse/publisherReducer';
import { clearPublishers } from '../Browse/publishersReducer';
import { clearNotifications } from '../../components/notifications/notificationsReducer';
import { logout } from '../auth/authReducer';

const EXTERNAL_ROUTES = ['/login', '/logout', '/external-logout', '/auth', '/forgot'];

const AppRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const dispatch = useDispatch();

    const [checkIfInternalUser, setCheckIfInternalUser] = useState(false);

    const internalUserJwt = new URLSearchParams(location.search).get('jwt');
    const isExternalRoute = EXTERNAL_ROUTES.includes(path);

    if (internalUserJwt && !checkIfInternalUser) {
        dispatch(clearPublisher());
        dispatch(clearPublishers());
        dispatch(clearNotifications());
        dispatch(logout());
        setCheckIfInternalUser(true);
    }

    const publisher = useAppSelector(state => state.publisher);

    const { verifyToken } = useTokenValidator();

    useEffect(() => {
        if (isExternalRoute) {
            return;
        }

        if (verifyToken()) {
            // redirect to publisher select if no publisher stored or initial internal login
            if (isEmptyCustom(publisher) || !isEmptyCustom(internalUserJwt)) {
                navigate('/browse');
            }
            if (!isEmptyCustom(publisher) && path === '/') {
                navigate('/home/reporting');
            }
        }
    }, []);

    /**
     * - By default a route is considered as exact
     * - They can be nested
     <Route path="/the-path" element={<Element />}>
     <Route path="/sub-path" element={<Element />} /> //the-path/sub-path
     </Route>
     OR use * to signify non-exact path
     * - component={Component} => element={<Element />}
     */
    return (
        <>
            <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route path="forgot" element={<ForgotPasswordPage />} />
                <Route path="auth" element={<ResetPasswordPage />} />
                {/* Protected routes */}
                <Route
                    path="*"
                    element={
                        <ProtectedRoute
                            element={<MainContent />}
                            redirectPath={'/login'}
                            isAuthenticated={verifyToken()}
                        />
                    }
                />
            </Routes>
            <NotificationsManager isLoggedIn={verifyToken()} />
        </>
    );
};

export default Sentry.withProfiler(AppRoutes);

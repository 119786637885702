// Components
import Snackbar from 'gumdrops/Snackbar';
import SnackbarNotification from 'gumdrops/SnackbarNotification';
import { useDispatch } from 'react-redux';
// Actions
import { dismissNotification } from './notificationsReducer';
import styles from './NotificationsManager.module.scss';
import { useAppSelector } from '../../store';

const NotificationsManager = ({ isLoggedIn }) => {
    const dispatch = useDispatch();
    const notifications = useAppSelector(state => state.notifications);
    const positionToDisplay = isLoggedIn ? '' : styles.notLoggedIn;
    return (
        <Snackbar className={positionToDisplay}>
            {notifications.map(({ id, text, context, msToClose = 0, isInnerHtml = false }) => (
                <SnackbarNotification
                    key={id}
                    id={id}
                    context={context}
                    text={
                        isInnerHtml ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        ) : (
                            text
                        )
                    }
                    msToClose={msToClose}
                    callback={() => dispatch(dismissNotification(id))}
                />
            ))}
        </Snackbar>
    );
};

export default NotificationsManager;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';

import TableData from 'gumdrops/TableData';
import Tooltip from 'gumdrops/Tooltip';

import { sendNotification } from '../../components/notifications/notificationsReducer';
import TableWithLoading from '../../components/common/TableWithLoading';

const SlotsList = ({ slots, getSlotCodeConfig, isLoading }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _nameCellDecorator = (slotName, key) => <TableData key={key}>{slotName}</TableData>;

    const _implementationTypeDecorator = (currentType, key) => (
        <TableData key={key}>{currentType.name}</TableData>
    );

    const _slotSizeDecorator = (currentSize, key) => (
        <TableData key={key}>{currentSize.name}</TableData>
    );

    const _editCellDecorator = (cellData, key) => {
        const { message: clipboardText, code: slotCode } = getSlotCodeConfig(cellData);

        return (
            <TableData key={key}>
                <Tooltip text={clipboardText}>
                    <Clipboard
                        className="gds-button gds-button--secondary gds-button--xs -m-l-1"
                        data-clipboard-text={slotCode}
                        onSuccess={() =>
                            dispatch(
                                sendNotification({
                                    text: t('zone.slots.copy'),
                                    context: 'success',
                                    msToClose: 3000,
                                }),
                            )
                        }>
                        <span className="fa fa-copy" style={{ fontSize: 14 }} />
                    </Clipboard>
                </Tooltip>
            </TableData>
        );
    };

    /**
     * Table data and column config
     */
    const _getData = () =>
        slots.map(slot => ({
            id: slot.id, // not displayed in the table, but necessary for each row
            name: slot.name,
            slotSize: slot.slotSize,
            implementationType: {
                id: slot.implementationType.id,
                name: slot.implementationType.name,
            },
            inSlotPlacement: slot.inSlotPlacement && {
                id: slot.inSlotPlacement.id,
                name: slot.inSlotPlacement.name,
            },
            copyCode: slot,
            actions: slot,
        }));

    const _getColumnWidth = key =>
        ({
            id: '100px',
            name: '400px',
            slotSize: '150px',
            implementationType: '200px',
            inSlotPlacement: '200px',
            actions: '80px',
        })[key];

    const _getColumns = () => [
        {
            children: 'Id',
            key: 'id',
            headingProps: {
                style: {
                    width: _getColumnWidth('id'),
                },
            },
        },
        {
            children: t('common.name'),
            key: 'name',
            dataCellDecorator: _nameCellDecorator,
            headingProps: {
                style: {
                    width: _getColumnWidth('name'),
                },
            },
        },
        {
            children: 'Size',
            key: 'slotSize',
            dataCellDecorator: _slotSizeDecorator,
            disableSorting: true,
            headingProps: {
                style: {
                    width: _getColumnWidth('slotSize'),
                },
            },
        },
        {
            children: 'Implementation Type',
            key: 'implementationType',
            dataCellDecorator: _implementationTypeDecorator,
            disableSorting: true,
            headingProps: {
                style: {
                    width: _getColumnWidth('implementationType'),
                },
            },
        },
        {
            children: 'Actions',
            key: 'actions',
            dataCellDecorator: _editCellDecorator,
            disableSorting: true,
            headingProps: {
                style: {
                    width: _getColumnWidth('actions'),
                },
            },
        },
    ];

    return (
        <div className="gds-container -p-a-0" data-cy="slots-list">
            <TableWithLoading
                data={_getData()}
                columns={_getColumns()}
                tableName="slots"
                isStriped
                isResponsive
                isLoading={isLoading}
                className="gds-table--responsive -bor-rad-a-1"
            />
        </div>
    );
};

SlotsList.propTypes = {
    slots: PropTypes.array.isRequired,
    getSlotCodeConfig: PropTypes.func.isRequired,
};

SlotsList.defaultProps = {
    slots: [],
};

export { SlotsList };

export default SlotsList;

// External libraries
import PropTypes from 'prop-types';

// Constants
import { ZONE_TYPES } from '../../constants/app';

// Hooks
import { useGetZoneQuery } from './zoneApiSlice';

// Components
import ZoneBlockUrls from './ZoneBlockUrls';
import ZoneDetails from './ZoneDetails';
import ZoneEmbedCodes from './ZoneEmbedCodes';

const ZoneOverview = ({ zoneId }) => {
    const { data: zone } = useGetZoneQuery(zoneId);
    const appZoneTypeId = ZONE_TYPES.find(({ key }) => key === 'app').id;
    const isApp = parseInt(zone?.zoneType?.id) === appZoneTypeId;

    return (
        <>
            <ZoneDetails zoneId={zoneId} isApp={isApp} />
            <ZoneBlockUrls zoneId={zoneId} />
            {!isApp && <ZoneEmbedCodes zoneId={zoneId} isApp={isApp} />}
        </>
    );
};

ZoneOverview.propTypes = {
    zoneId: PropTypes.string.isRequired,
};

ZoneOverview.displayName = 'ZoneOverview';

export default ZoneOverview;

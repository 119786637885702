// Components
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Card from 'gumdrops/Card';

// @ts-ignore
import BlockAdvertiserDomains from '../blocks/BlockAdvertiserDomains';
// @ts-ignore
import BlockAdvertisers from '../blocks/BlockAdvertisers';
import BlockedIABCategories from '../BlockedIABCategories/BlockedIABCategories';

interface BlockingProps {
    zoneId?: string;
}

const Blocking = ({ zoneId }: BlockingProps) => {
    const { t } = useTranslation();

    const baseUrl = zoneId ? `/zones/edit/${zoneId}/blocking` : '/global-advertiser-blocks';

    const blockComponents = (
        <div className="">
            <BlockedIABCategories />
            <BlockAdvertisers basePath={baseUrl} zoneId={zoneId} />
            <BlockAdvertiserDomains basePath={baseUrl} zoneId={zoneId} />
        </div>
    );

    return zoneId ? (
        blockComponents
    ) : (
        <section className="gds-persist-nav__main-content gds-layout__container -p-t-4">
            <h3 className="gds-text--header-md -m-r-2 -m-b-2">
                {t('blocking.global.title') as string}
            </h3>
            <p className="gds-text--body-sm -m-b-2">
                {t('blocking.global.globalBlocksMessage') as string}
            </p>
            <Card className={'-p-a-3 -m-b-3'}>{blockComponents}</Card>
        </section>
    );
};

export default Blocking;

import Card from 'gumdrops/Card';
import Checkbox from 'gumdrops/Checkbox';
import Tag from 'gumdrops/Tag';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableWithLoading from '../../components/common/TableWithLoading';

const BlockAdvertisersList = ({
    zoneId,
    customKey,
    advertiserRestrictions,
    selectedEntriesIds,
    handleSelectEntry,
    isLoading,
}) => {
    const { t } = useTranslation();
    const _getColumns = () => [
        {
            children: ' ',
            key: customKey,
            disableSorting: true,
            dataCellDecorator: selectedDecorator,
        },
        {
            children: t('common.name'),
            key: 'name',
            disableSorting: true,
            dataCellDecorator: nameDecorator,
        },
    ];

    /* eslint-disable react/no-multi-comp */
    const selectedDecorator = (value, key, rowData) => (
        <td
            key={key}
            style={{ width: 30, paddingLeft: 15 }}
            data-cy="block-advertisers-list-checkbox">
            {zoneId && rowData.tag ? (
                ''
            ) : (
                <Checkbox
                    size="xs"
                    onChange={handleSelectEntry}
                    checked={selectedEntriesIds.includes(value.toString())}
                    value={value}
                />
            )}
        </td>
    );

    const nameDecorator = (name, key, rowData) => (
        <td key={key}>
            <div className="gds-flex">
                <span className="gds-flex__item">{name}</span>
                <div className="gds-flex__item gds-flex--justify-end -text-right">
                    {!rowData.tag
                        ? ''
                        : zoneId && (
                              <Tag className="gds-tag gds-tag--xs -m-b-0" text={rowData.tag} />
                          )}
                </div>
            </div>
        </td>
    );

    return (
        <Card className="gds-card--has-header" style={{ maxHeight: 500, overflowY: 'auto' }}>
            <TableWithLoading
                data={advertiserRestrictions}
                columns={_getColumns()}
                size="sm"
                isStriped
                isLoading={isLoading}
            />
        </Card>
    );
};

BlockAdvertisersList.propTypes = {
    zoneId: PropTypes.string,
    customKey: PropTypes.string.isRequired,
    advertiserRestrictions: PropTypes.array.isRequired,
    selectedEntriesIds: PropTypes.array.isRequired,
    handleSelectEntry: PropTypes.func.isRequired,
};

BlockAdvertisersList.defaultProps = {
    advertiserRestrictions: [],
    selectedEntriesIds: [],
};

export default BlockAdvertisersList;

import Button from 'gumdrops/Button';
import PropTypes from 'prop-types';

import Icon from './Icon';

const IconButton = ({ icon, tooltipText, tooltipPosition, wrapperStyle, ...rest }) => (
    <span
        className={`gds-tooltip--${tooltipPosition}`}
        data-tooltip={tooltipText}
        style={wrapperStyle}>
        <Button {...rest}>
            <Icon icon={icon} />
        </Button>
    </span>
);

IconButton.propTypes = {
    context: PropTypes.oneOf(['primary', 'secondary', 'danger', 'default', 'success', 'outline'])
        .isRequired,
    icon: PropTypes.string,
    tooltipText: PropTypes.string,
    tooltipPosition: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
    wrapperStyle: PropTypes.object,
};

IconButton.defaultProps = {
    context: 'outline',
    icon: 'plus',
    tooltipText: '',
    tooltipPosition: 'left',
    wrapperStyle: {},
};

export default IconButton;

import { PRODUCTION_HOSTNAME, STAGE_HOSTNAME } from '../constants/app';

export const getEnv = () => {
    const { hostname } = window.location;
    return hostname === PRODUCTION_HOSTNAME
        ? 'production'
        : hostname === STAGE_HOSTNAME
          ? 'staging'
          : 'development';
};

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NavOption } from '../../constants/navOptions';

interface NavItemProps {
    key: string;
    item: NavOption;
}

const NavItem = ({ item: { icon, title, path, subPaths, children } }: NavItemProps) => {
    const { pathname: currentPath } = useLocation();

    // const { id } = useParams();
    const styledTitle = icon ? (
        <>
            <i className={`fas fa-${icon} gds-nav__link-icon`} />
            <span className="gds-nav__link-text">{title}</span>
        </>
    ) : (
        title
    );

    const matchPath = currentPath.match(/^\/([^/]+)(?:\/([^/]+))?/);
    const isLinkActive = matchPath && matchPath[1] === path.slice(1);

    return (
        <li>
            {!path && <span className="gds-persist-nav__link">{styledTitle}</span>}
            {path && path.startsWith('http') ? (
                <a
                    className="gds-nav__link"
                    href={path}
                    target="_blank"
                    rel="noreferrer"
                    title={title}>
                    {styledTitle}
                </a>
            ) : (
                path && (
                    <Link
                        to={children ? path + children[0].path : path}
                        className={`gds-nav__link ${isLinkActive ? 'active' : ''}`}
                        title={title}>
                        {styledTitle}
                    </Link>
                )
            )}
            {subPaths && (
                <ul className="gds-persist-nav__sub-nav">
                    {subPaths.map(item => (
                        <NavItem key={item.path} item={item} />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default NavItem;

import { useTranslation } from 'react-i18next';
import { Validator } from './types';

const usePasswordValidator = () => {
    const { t } = useTranslation();

    const validateNotEmpty = (value: string | null) => value && value.length >= 8;

    const validateUpperCase = (value: string | null) => value && /[A-Z]/.test(value);

    const validateLowerCase = (value: string | null) => value && /[a-z]/.test(value);

    const validateNumber = (value: string | null) => value && /[0-9]/.test(value);

    const validateSpecialChar = (value: string | null) =>
        value && /[!@#$%^&*()_+\-\\=\\{\\}[\]:"|;'\\<>?,./~]/.test(value);

    return [
        {
            validate: validateNotEmpty,
            onValid: t('auth.forgotOrResetPassword.validations.password.tooShort'),
            onError: t('auth.forgotOrResetPassword.validations.password.tooShort'),
        },
        {
            validate: validateUpperCase,
            onValid: t('auth.forgotOrResetPassword.validations.password.upperCase'),
            onError: t('auth.forgotOrResetPassword.validations.password.upperCase'),
        },
        {
            validate: validateLowerCase,
            onValid: t('auth.forgotOrResetPassword.validations.password.lowerCase'),
            onError: t('auth.forgotOrResetPassword.validations.password.lowerCase'),
        },
        {
            validate: validateNumber,
            onValid: t('auth.forgotOrResetPassword.validations.password.number'),
            onError: t('auth.forgotOrResetPassword.validations.password.number'),
        },
        {
            validate: validateSpecialChar,
            onValid: t('auth.forgotOrResetPassword.validations.password.specialChar'),
            onError: t('auth.forgotOrResetPassword.validations.password.specialChar'),
        },
    ] as Validator[];
};
export default usePasswordValidator;

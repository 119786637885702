// External libraries
import PropTypes from 'prop-types';

// Constants
import { API_URL_ADV_V2 } from '../../api/constants';

// Hooks
import { useGetZoneQuery } from './zoneApiSlice';
import { useAppSelector } from '../../store';
import { useCreateSlotsBulkMutation, useGetSlotsQuery } from '../slots/slotsApiSlice';

// Components
import SlotsManager from '../slots/SlotsManager';

const ZoneSlots = ({ zoneId }) => {
    const publisherName = useAppSelector(state => state.publisher.name);
    const { data: zone = { name: '' } } = useGetZoneQuery(zoneId);
    const { data: slots = [], isLoading } = useGetSlotsQuery(zoneId);
    const [createSlotsBulk] = useCreateSlotsBulkMutation();

    const handleSubmit = (zoneId, slots, afterSubmit) => {
        createSlotsBulk({ zoneId, slots });
        afterSubmit && afterSubmit();
    };

    const publisherAndZone = `${publisherName} - ${zone.name}`;
    return (
        <SlotsManager
            entityId={zoneId}
            entityType="trackingId"
            entityName="zones"
            entityLabel={publisherAndZone} // Used for slot code
            exportEndpoint={`${API_URL_ADV_V2}/external/zones/${zoneId}/slots/embed-codes/export?fileType=CSV`}
            slots={slots}
            isLoading={isLoading}
            saveSlots={handleSubmit}
        />
    );
};

ZoneSlots.propTypes = {
    zoneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { ZoneSlots };

export default ZoneSlots;

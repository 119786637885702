// Components
import Card from 'gumdrops/Card';
import LayoutContainer from 'gumdrops/LayoutContainer';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import Tabs from '../../components/common/Tabs';
// Constants
import { zoneTabOptions } from '../../constants/tabOptions';
import Blocking from '../blocks/Blocking';
// Hooks
import ZoneName from './ZoneName';
import ZoneOverview from './ZoneOverview';
import ZoneSlots from './ZoneSlots';

const Zone = () => {
    const { pathname } = useLocation();
    const { zoneId } = useParams();

    return (
        <LayoutContainer>
            <ZoneName zoneId={zoneId} />
            <Tabs topPath={`/zones/edit/${zoneId}`} location={pathname} options={zoneTabOptions} />
            <section>
                <Card
                    className="-p-a-3"
                    style={{
                        borderTopRightRadius: '0px',
                        borderTopLeftRadius: '0px',
                        top: '-1px',
                    }}>
                    <Routes>
                        <Route path="overview" element={<ZoneOverview zoneId={zoneId} />} />
                        <Route path="slots/*" element={<ZoneSlots zoneId={zoneId} />} />
                        <Route path="blocking/*" element={<Blocking zoneId={zoneId} />} />
                    </Routes>
                </Card>
            </section>
        </LayoutContainer>
    );
};

Zone.displayName = 'Zone';

export default Zone;

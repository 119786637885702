import cx from 'classnames';
// Gumdrops
import Button from 'gumdrops/Button';
import CardBlock from 'gumdrops/CardBlock';
import TextArea from 'gumdrops/TextArea';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/common/Alert';
import LoadingOverlay from '../../components/common/LoadingOverlay';
// Components
import ModalWrapper from '../../components/common/ModalWrapper';
// Constants
import { ADVERTISER_DOMAIN, PUBLISHERS, ZONES } from '../../constants/app';
// Helpers
import { getFormattedTextAreaData } from '../../helpers/textarea';
import { isValidDomain } from '../../helpers/validations';
// Hooks
import { useCreateRestrictionsMutation } from './restrictionApiSlice';
import { useAppSelector } from '../../store';

const BlockAdvertiserDomainModal = ({ zoneId, onClose, blockedRestrictions }) => {
    const { t } = useTranslation();
    const publisherId = useAppSelector(state => state.publisher.id);
    const [showAlert, setShowAlert] = useState(false);
    const [domains, setDomains] = useState([]);

    const [createRestrictions, { isLoading: isCreatingRestrictions }] =
        useCreateRestrictionsMutation();

    const handleClose = (message = '') => onClose(message);

    const handleChange = e => setDomains(getFormattedTextAreaData(e.target.value));

    const toggleAlert = () => setShowAlert(!showAlert);

    const invalidAdvertiserDomains = () => domains.some(item => !isValidDomain(item));

    const handleSubmit = async () => {
        if (invalidAdvertiserDomains()) {
            toggleAlert();
            return;
        }
        const formattedDomains = domains.map(item => (item.startsWith('*.') ? item : `*.${item}`));
        const payload = {
            entity: zoneId ? ZONES : PUBLISHERS,
            entityId: zoneId || publisherId,
            restrictionIds: formattedDomains,
            restrictionType: ADVERTISER_DOMAIN,
        };

        const areRestrictionsAlreadyExisting = formattedDomains.every(name =>
            blockedRestrictions.some(restriction => restriction.name === name),
        );

        if (!areRestrictionsAlreadyExisting) {
            await createRestrictions(payload);
        }

        handleClose(areRestrictionsAlreadyExisting ? t('blocking.advertisers.alreadyBlocked') : '');
    };

    const isSubmitDisabled = isCreatingRestrictions || domains.length === 0;

    return (
        <ModalWrapper
            bodyStyles={{ minHeight: 300 }}
            containerClassName="-overflow-visible --modal-override"
            bodyClassName=" -p-h-4 -p-t-4"
            title={t('blocking.domains.create')}
            isOpen
            onClose={handleClose}
            hideFooter>
            <CardBlock className="gds-flex gds-flex--justify-end">
                <TextArea
                    size="md"
                    name="advertiser-domains"
                    className="-m-b-2"
                    resize="resize-v"
                    placeholder={t('blocking.domains.placeHolder')}
                    style={{ height: '170px' }}
                    onChange={handleChange}
                />
                <Button
                    disabled={isSubmitDisabled}
                    context="primary"
                    className={cx('gds-flex__item gds-flex__item--grow-0', {
                        '-disabled': isSubmitDisabled,
                    })}
                    size="sm"
                    onClick={handleSubmit}>
                    {t('common.add')}
                </Button>
                {isCreatingRestrictions && <LoadingOverlay showOverlay />}
                {showAlert && (
                    <Alert
                        title={t('blocking.domains.invalidDomains')}
                        body={t('blocking.domains.errorMessage')}
                        onAccept={toggleAlert}
                        acceptButtonText={t('common.accept')}
                        hasRejectOption={false}
                        context="danger"
                    />
                )}
            </CardBlock>
        </ModalWrapper>
    );
};

BlockAdvertiserDomainModal.propTypes = {
    zoneId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default BlockAdvertiserDomainModal;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Column } from 'gumdrops';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Layout from '../Basic/Layout';
import styles from './AuthorizationLayout.module.scss';

interface UserAuthorizationLayoutProps {
    children: React.ReactNode;
}

const AuthorizationLayout = ({ children }: UserAuthorizationLayoutProps) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <header className={styles.logoContainer}>
                <Link to="/">
                    <img className={styles.logo} src="/assets/logo.svg" />
                </Link>
            </header>
            <Column className={styles.mottoContainer} xs={12} sm={12} md={12} lg={6} xl={7}>
                <h2>{t('auth.login.branding.motto') as string}</h2>
                <div className={styles.graphic} />
            </Column>
            {/*<Column className={styles.graphicContainer} xs={12} sm={12} md={12} lg={6} xl={7}>*/}
            {/*    */}
            {/*</Column>*/}
            <Column className={styles.formContainer} xs={12} sm={12} md={12} lg={6} xl={5}>
                {children}
            </Column>
        </Layout>
    );
};

export default AuthorizationLayout;

// External Libraries
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

// Gumdrops Components
import { Button, Card, CardBlock, Column, Icon, LayoutContainer, Row } from 'gumdrops';

// Constants
import { API_URL_ADV_V2 } from '../../api/constants';
import { BUSINESS_UNIT } from '../../constants/app';

// Helpers
import { getEnv } from '../../helpers/getEnv';

// Hooks
import useQuery from '../../hooks/useQuery';
import { useGetPublisherPaymentLedgerQuery } from './paymentsApiSlice';
import { useGetTipaltiUrlQuery } from './tipaltiApiSlice';

// Local Components
import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import ContentHeader from '../../components/common/ContentHeader';
import QueryableTableWrapper from '../../components/common/QueryableTableWrapper';
import TablePaginationFooter from '../../components/common/TablePaginationFooter';
import ExportFile from '../../components/export/ExportFile';
import PreviousPaymentsList from './PreviousPaymentsList';

// Store
import { useAppSelector } from '../../store';

const isProduction = getEnv() === 'production';

const DEFAULT_QUERY_PARAMS = {
    page: '1',
    size: '20',
};

const Payments = ({ isLegacyJustPremium }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const queryParams = useQuery(DEFAULT_QUERY_PARAMS);
    const { page, size, direction } = queryParams;
    const { id: publisherId, businessUnitId } = useAppSelector(state => state.publisher);
    const jwt = useAppSelector(state => state.auth.jwt);

    const { data: tipaltiUrl } = useGetTipaltiUrlQuery({
        publisherId,
        businessUnitId,
        reportType: 'payments',
    });
    const { data: paymentsData = [], isLoading } = useGetPublisherPaymentLedgerQuery({
        publisherId,
        filters: {
            ...DEFAULT_QUERY_PARAMS,
            ...queryParams,
            direction,
            page: page ? parseInt(page) - 1 : 0,
        },
    });

    const _renderHeader = () => {
        const isCurrentPaymentsPath = pathname === '/payments';
        const buttonLink = isCurrentPaymentsPath ? '/payments/previous' : '/payments';
        const buttonLabel = isCurrentPaymentsPath
            ? t('payments.seePrevious')
            : t('payments.seeCurrent');

        const headerButton =
            !isLegacyJustPremium && isProduction ? (
                <Link to={buttonLink} data-cy="payments-button">
                    <Button context="primary" size="xs">
                        {buttonLabel}
                    </Button>
                </Link>
            ) : null;

        return <ContentHeader headerButton={headerButton} />;
    };

    const _renderFooter = () => (
        <Row>
            <Column md={12} className="-m-b-6">
                <h2 className="gds-text--header-md -m-b-3" data-cy="payments-important-info-title">
                    {t('payments.importantInfo')}
                </h2>
                <Card className="gds-card--no-border -p-a-2">
                    <CardBlock>
                        <ul data-cy="payments-important-info-list">
                            <li className="gds-text__list-item--disc">
                                {t('payments.importantInfoMsg1')}
                                {parseInt(businessUnitId) !== BUSINESS_UNIT.US && (
                                    <i> {t('payments.importantInfoMsg1Disclaimer')}</i>
                                )}
                            </li>
                            <li className="gds-text__list-item--disc">
                                {t('payments.importantInfoMsg2')}
                            </li>
                            <li className="gds-text__list-item--disc">
                                {t('payments.importantInfoMsg3')}
                                {parseInt(businessUnitId) !== BUSINESS_UNIT.US && (
                                    <i> {t('payments.importantInfoMsg3Disclaimer')}</i>
                                )}
                            </li>
                            <li className="gds-text__list-item--disc">
                                {t('payments.importantInfoMsg4')}
                            </li>
                        </ul>
                    </CardBlock>
                </Card>
            </Column>
        </Row>
    );

    const _renderTipaltiIframe = () => (
        <Column md={12} className="-m-b-4" style={{ height: '810px' }}>
            {tipaltiUrl ? (
                <iframe
                    style={{
                        height: '100%',
                        width: '100%',
                        border: 'none',
                    }}
                    src={tipaltiUrl}
                    data-cy="payments-frame"
                />
            ) : (
                <div className="gds-loading">
                    <div className="gds-loading__dot" />
                </div>
            )}
        </Column>
    );

    const _renderPaymentHistory = () => {
        const showExport = paymentsData.content && paymentsData.content.length > 0;

        return (
            <QueryableTableWrapper queryParams={queryParams}>
                {({ handlePageChange, handleSizeChange }) => (
                    <LayoutContainer>
                        <Row>
                            <Card>
                                <PreviousPaymentsList
                                    publisherPaymentList={paymentsData.content}
                                    isLoading={isLoading}
                                />
                                <TablePaginationFooter
                                    totalPages={paymentsData.totalPages}
                                    totalElements={paymentsData.totalElements}
                                    handlePageChange={page => {
                                        handlePageChange(page);
                                    }}
                                    handleSizeChange={size => {
                                        handleSizeChange(size);
                                    }}
                                    activePage={parseInt(page)}
                                    size={parseInt(size)}
                                />
                                {showExport && (
                                    <div className="gds-form-group gds-flex__item gds-flex gds-flex--justify-end">
                                        {/*<div>
                                            <a
                                                href={`/ads-api/exportPublisherPaymentLedger?publisherId=${publisherId}&fileType=xls`}>
                                                <Button
                                                    className="-m-r-1"
                                                    size="sm"
                                                    context="default">
                                                    {intl.formatMessage(
                                                        this.messages.exportToExcel
                                                    )}
                                                </Button>
                                            </a>
                                        </div>*/}
                                        <div>
                                            <ExportFile
                                                context="outline"
                                                title="Export to CSV"
                                                fileName="GumGum - Report Earnings"
                                                fileExt="csv"
                                                disabled={!paymentsData.content.length}
                                                jwt={jwt}
                                                endpoint={`${API_URL_ADV_V2}/external/publishers/${publisherId}/payment-ledger/export?fileType=CSV`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </Row>
                    </LayoutContainer>
                )}
            </QueryableTableWrapper>
        );
    };

    return (
        <>
            {_renderHeader()}
            {(isProduction && (
                <Routes>
                    <Route path="*" element={<Row>{_renderTipaltiIframe()}</Row>} />
                    <Route
                        path="previous"
                        element={
                            <AuthorizedRoute
                                isAuthorized={!isLegacyJustPremium}
                                element={<Row>{_renderPaymentHistory()}</Row>}
                            />
                        }
                    />
                </Routes>
            )) || (
                <Card className="-p-a-3">
                    <Icon fontSize={30} icon="info-circle" className="-va-sub -color-tx-pri-lt-1" />
                    <span style={{}} className="-m-l-2">
                        {t('payments.paymentsNotAvailable')}
                    </span>
                </Card>
            )}
            {_renderFooter()}
        </>
    );
};

Payments.displayName = 'Payments';

export default Payments;

/**
 * Returns an iterator that gives you unique-sequential IDs
 * E.G. const idGen = idGenerator();
 * gen.next().value // 0
 * gen.next().value // 1
 *
 * @returns {Iteator}
 */
const idGenerator = function* () {
    let index = 0;
    while (true) yield index++;
};

export default idGenerator;

// Components
import Card from 'gumdrops/Card';
import { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ContentHeader from '../../components/common/ContentHeader';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import LookerNotesLink from '../../components/common/LookerNotesLink';
import Tabs from '../../components/common/Tabs';

// Constants
import { LOOKER_REPORTS } from '../../constants/reporting';
import {
    inVideoReportOptions,
    passbackOption,
    reportingTabOptions,
    slotRevenueOption,
} from '../../constants/tabOptions';
// Hooks
import { useGetLookerReportUrlQuery } from './reportingApiSlice';
import { useAppSelector } from '../../store';
import { useGetPublisherByIdMutation } from '../Browse/publisherApiSlice';
import { useDispatch } from 'react-redux';
import { setPublisher } from '../Browse/publisherReducer';

const ReportingTabs = ({ isLegacyJustPremium }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { reportingType } = useParams();
    const { id: userId, name } = useAppSelector(state => state.auth);
    const [getPublisherById] = useGetPublisherByIdMutation();
    const {
        id: publisherId,
        hasSlotRevenue,
        passbackCampaigns,
        passbackAdvertiser,
        hasInVideoZones: showInVideoReports,
    } = useAppSelector(state => state.publisher);
    const hasPassbackCampaigns = passbackAdvertiser?.id && passbackCampaigns.length;

    const {
        data: lookerReportUrl,
        refetch: handleReportingUrlRefetch,
        isUninitialized,
    } = useGetLookerReportUrlQuery(
        {
            userId,
            name,
            publisherId,
            lookerReportId: LOOKER_REPORTS[reportingType],
        },
        {
            skip: !publisherId,
        },
    );

    const updateInVideoTabs = async () => {
        // publisherId is not available in the state when the page is refreshed
        if (!publisherId) {
            return;
        }
        const currentPublisher = await getPublisherById(publisherId).unwrap();
        if (showInVideoReports !== currentPublisher.hasInVideoZones) {
            dispatch(
                setPublisher({
                    ...currentPublisher,
                    hasInVideoZones: currentPublisher.hasInVideoZones,
                }),
            );
        }
    };

    useEffect(() => {
        showInVideoReports ? updateInVideoTabs() : null;
        if (!isUninitialized) {
            handleReportingUrlRefetch();
        }
    }, [isUninitialized, handleReportingUrlRefetch]);

    const tabs = [
        ...reportingTabOptions,
        ...(hasSlotRevenue && !isLegacyJustPremium ? [slotRevenueOption] : []),
        ...(hasPassbackCampaigns && !isLegacyJustPremium ? [passbackOption] : []),
        ...(showInVideoReports ? inVideoReportOptions : []),
    ];

    return (
        <Fragment>
            {/* <UserAwareness businessUnitId={businessUnitId} /> TODO */}
            <ContentHeader />
            <Tabs
                topPath="/home"
                location={pathname}
                options={tabs}
                onClick={handleReportingUrlRefetch}
            />
            <Card>
                {lookerReportUrl ? (
                    <iframe
                        src={lookerReportUrl}
                        width="100%"
                        height="1300px"
                        frameBorder="0"
                        data-cy="reporting-frame"
                    />
                ) : (
                    <Card className="-p-h-3" style={{ minHeight: '60px' }}>
                        <LoadingOverlay showOverlay showDots loadingDotsClass="-m-t-3">
                            <h1
                                className="loading-overlay gds-text--header-sm gds-text--bold -p-b-4"
                                style={{ flexFlow: 'unset' }}>
                                Loading reporting
                            </h1>
                        </LoadingOverlay>
                    </Card>
                )}
            </Card>
            {[
                'reporting',
                'advertiser-spend',
                'custom',
                'slot-revenue',
                'passback-campaigns',
                'in-video-dashboard',
                'in-video-custom',
            ].includes(reportingType) && <LookerNotesLink />}
        </Fragment>
    );
};

export default ReportingTabs;

/**
 * @example
 {
    title: "Section title",
    path: "/section",
    children: ["/section/edit", "/section/create"],
    subpaths: [
        {
            title: "Sub Section",
            path: "/section/deep",
            subpaths: [
                {
                    title: "Sub Sub Section",
                    path: "/section/deep/deeper"
                    [...]
                }
            ]
        }
    ]
 }
 *
 * Children are pages accessible only through the parent page.
 * Adding them under a menu item will highlight it when the user is navigating on these pages.
 */
import i18n from '../translations/i18n';
import { accountSettingsTabOptions, reportingTabOptions } from './tabOptions';

export interface NavOption {
    name?: string;
    title: string;
    path: string;
    icon?: string;
    subPaths?: NavOption[];
    children?: NavOption[];
}

const navOptions: NavOption[] = [
    {
        name: i18n.t('reporting.home'),
        title: i18n.t('reporting.title'),
        path: '/home',
        icon: 'chart-bar',
        children: reportingTabOptions,
    },
    {
        title: i18n.t('payments.title'),
        path: '/payments',
        icon: 'credit-card',
    },
    {
        title: i18n.t('zones.title'),
        path: '/zones',
        icon: 'layer-group',
        // subPaths: zoneTabOptions,
    },
    {
        title: i18n.t('blocking.global.title'),
        path: '/global-advertiser-blocks',
        icon: 'ban',
    },
    {
        title: i18n.t('accountSettings.title'),
        path: '/account-settings',
        icon: 'user-cog',
        children: accountSettingsTabOptions,
    },
];

export default navOptions;

import { clone, isEmpty } from 'ramda';

import {
    PUBLISHER_DIRECTNESS_BOTH,
    PUBLISHER_DIRECTNESS_DIRECT,
    PUBLISHER_DIRECTNESS_RESELLER,
} from '../constants/app';
import { isValidDomain } from './validations';

const BOOLEAN_FIELDS = ['ott'];

export const NA = 'n/a';

export const isEmptyCustom = value =>
    value === null || typeof value === 'undefined' || isEmpty(value);

export const removeEmptyProperties = obj => {
    const clonedObject = clone(obj);
    Object.keys(clonedObject).forEach(
        key => isEmptyCustom(clonedObject[key]) && delete clonedObject[key],
    );
    return clonedObject;
};

export const formatBooleanField = val => (val ? 'Yes' : 'No');

export const getPublisherDirectness = ({ direct, reseller }) =>
    direct && reseller
        ? PUBLISHER_DIRECTNESS_BOTH
        : reseller
          ? PUBLISHER_DIRECTNESS_RESELLER
          : direct
            ? PUBLISHER_DIRECTNESS_DIRECT
            : NA;

export function snakeToHuman(text) {
    return text
        .split('_')
        .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
        .join(' ');
}

export function toCurrency(val) {
    const symbol = '$';
    if (typeof val === 'string') {
        val = parseFloat(val);
    }

    if (typeof val !== 'number') return;

    if (val < 0) {
        return `-${symbol}${Number(Math.abs(val)).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`;
    }

    val = Number(val).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return `${symbol}${val}`;
}

export function formatField(field, value) {
    if (isEmptyCustom(value)) {
        return NA;
    }

    if (BOOLEAN_FIELDS.includes(field)) {
        return value ? 'Yes' : 'No';
    }

    return value;
}

export const getFormattedDomainUrl = domain =>
    domain && isValidDomain(domain) && domain != 'MULTIPLE'
        ? 'https://' + domain.replace('*.', '').replace('http://', '').replace('https://', '')
        : '';

/**
 * Transform a string from camel case to human friendly text
 * @example myString => My String
 */
export function camelToHuman(text) {
    return text.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import BulkSelector from './BulkSelector';
import SearchInput from '../common/SearchInput';

// This component displays a list of entries with checkboxes with the ability to search in them
const SearchSelectableListWrapper = ({
    entries,
    handleUpdateEntries,
    renderHeaderChildren,
    children,
    customName,
    customId,
    getItemTitle,
    zoneId,
    toggle,
    isFocused,
    ...BulkSelectorProps
}) => {
    const [filteredEntries, setFilteredEntries] = useState(entries);

    useEffect(() => setFilteredEntries(entries), [entries]);

    const onSearchChange = term => {
        const trimmedTerm = term ? term.trim() : '';
        const newFilteredEntries = trimmedTerm
            ? entries.filter(item => {
                  const title = getItemTitle ? getItemTitle(item) : item[customName];
                  return title.toLowerCase().includes(trimmedTerm.toLowerCase());
              })
            : entries;
        setFilteredEntries(newFilteredEntries);
    };

    return (
        <BulkSelector
            entries={filteredEntries}
            isEntriesPaginated={false}
            isEntriesDynamic
            handleAfterChange={handleUpdateEntries}
            zoneId={zoneId}
            toggle={toggle}
            renderHeaderChildren={() => (
                <div
                    className="gds-flex"
                    style={{ width: '100%' }}
                    data-cy="search-selectable-list-input">
                    <div className="gds-flex__item">
                        <SearchInput
                            style={{ width: '100%' }}
                            size="xs"
                            onChange={onSearchChange}
                            isFocused={isFocused}
                            hasCloseOption
                            onCloseOptionClick={() => onSearchChange('')}
                        />
                    </div>
                    {renderHeaderChildren && (
                        <div className="gds-flex__item gds-flex--justify-end gds-flex__item--grow-0 -m-l-2">
                            {renderHeaderChildren()}
                        </div>
                    )}
                </div>
            )}
            customId={customId}
            {...BulkSelectorProps}>
            {({ selectedEntries, handleChangeEntry, handleClearSelected }) =>
                children({
                    entries: filteredEntries,
                    selectedEntries,
                    handleChangeEntry,
                    handleClearSelected,
                })
            }
        </BulkSelector>
    );
};

SearchSelectableListWrapper.propTypes = {
    entries: PropTypes.array.isRequired,
    selectedEntriesValues: PropTypes.array,
    handleUpdateEntries: PropTypes.func,
    children: PropTypes.func.isRequired,
    customName: PropTypes.string,
    customId: PropTypes.string,
    getItemTitle: PropTypes.func,
    renderHeaderChildren: PropTypes.func,
    toggle: PropTypes.object,
    isFocused: PropTypes.bool,
};

SearchSelectableListWrapper.defaultProps = {
    selectedEntriesValues: [],
    customName: 'name',
    customId: 'value',
};

export default SearchSelectableListWrapper;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

import styles from './Layout.module.scss';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
    <div className={styles.container}>
        <div className={styles.content}>{children}</div>
    </div>
);

export default Layout;

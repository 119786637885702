import Button from 'gumdrops/Button';
// Components
import FormGroup from 'gumdrops/FormGroup';
import FormGroupLabel from 'gumdrops/FormGroupLabel';
import PropTypes from 'prop-types';

// Helpers
import { copyToClipboard } from '../../helpers/copyToClipboard';

const ZoneEmbedCodesTag = ({ title, tag }) => (
    <FormGroup className="-m-t-3">
        <FormGroupLabel text={title} />
        <div className="gds-container -color-bg-lt-1 gds-flex gds-flex--align-center gds-flex--justify-between gds-flex--wrap-no">
            <div
                className="gds-flex__item gds-text--body-sm -overflow-x-scroll -m-r-2"
                style={{ whiteSpace: 'nowrap' }}>
                {tag}
            </div>
            <div className="gds-flex__item gds-flex__item--grow-0">
                <div className="gds-tooltip gds-tooltip--top" data-tooltip="Copy Clipboard">
                    <Button size="xs" context="primary" onClick={() => copyToClipboard(tag)}>
                        <i className="fas fa-copy" />
                    </Button>
                </div>
            </div>
        </div>
    </FormGroup>
);

ZoneEmbedCodesTag.propTypes = {
    title: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
};

export default ZoneEmbedCodesTag;

ZoneEmbedCodesTag.displayName = 'ZoneEmbedCodesTag';

export { ZoneEmbedCodesTag };

import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import SubmitButton from '../../../components/common/SubmitButton';
import FormGroupInput from '../../../components/layouts/FormGroupInput';
import {
    clearNotifications,
    sendNotification,
} from '../../../components/notifications/notificationsReducer';
import { validateLinkTs } from '../../../helpers/authorization';
import { isEmptyCustom } from '../../../helpers/utils';
import useQuery from '../../../hooks/useQuery';
import { useAppSelector } from '../../../store';
import useConfirmPasswordValidator from '../../../validators/useConfirmPasswordValidator';
import useInputField from '../../../validators/useInputField';
import usePasswordValidator from '../../../validators/usePasswordValidator';
import { useResetPasswordMutation, useValidateTokenMutation } from '../authApiSlice';
import BasicForm from '../BasicForm/BasicForm';

const ResetPasswordForm = () => {
    const [oldPwd, setOldPwd] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tk: token, ts: timeStamp, ue } = useQuery();
    const [validateToken] = useValidateTokenMutation();
    const [resetPassword] = useResetPasswordMutation();
    const [userEmailFromLink, setUserEmailFromLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const passwordValidator = usePasswordValidator();
    const { onChange: onPasswordChange, inputState: passwordState } = useInputField({
        initialValue: '',
        validators: passwordValidator,
    });
    const confirmPasswordValidator = useConfirmPasswordValidator(passwordState.value);
    const { onChange: onConfirmPasswordChange, inputState: confirmPasswordState } = useInputField({
        initialValue: '',
        validators: confirmPasswordValidator,
        listeners: [passwordState.value],
    });

    const email = useAppSelector(state => state.auth.email);

    useEffect(() => {
        if (isEmptyCustom(token) || isEmptyCustom(ue) || isEmptyCustom(timeStamp)) {
            return;
        }

        if (!validateLinkTs(timeStamp)) {
            dispatch(
                sendNotification({
                    text: t('auth.forgotOrResetPassword.validations.linkExpired'),
                    neverFade: true,
                    context: 'danger',
                }),
            );
        }

        //This was added since there is an issue with encoding and + sign, will have to dig more into it
        const payload = {
            tokens: { tk: token?.replaceAll(' ', '+'), ue: ue?.replaceAll(' ', '+') },
        };

        validateToken(payload)
            .then(res => {
                if ('error' in res) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    throw Error(res.error.message);
                }
                setOldPwd(res.data.pwd);
                setUserEmailFromLink(res.data.email);
            })
            .catch(() => {
                dispatch(
                    sendNotification({
                        text: t('errorMessages.general'),
                        neverFade: true,
                        context: 'danger',
                    }),
                );
            });

        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    const isFormValid = passwordState.valid && confirmPasswordState.valid;

    const handlePasswordUpdate = async (e: FormEvent) => {
        e.preventDefault();

        if (isFormValid) {
            const payload = {
                //email can come from the user that is logged in or from the welcome email link (if link is there we use that)
                email: !isEmptyCustom(userEmailFromLink) ? userEmailFromLink : email,
                password: oldPwd,
                newPassword: passwordState.value,
            };

            setIsLoading(true);

            resetPassword(payload)
                .then(res => {
                    if ('error' in res && 'status' in res.error && res.error.status !== 200) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        throw Error(res.error);
                    }

                    dispatch(
                        sendNotification({
                            text: t('auth.forgotOrResetPassword.passwordChangedSuccess'),
                            context: 'success',
                            msToClose: 0,
                        }),
                    );
                    setIsLoading(false);
                    setOldPwd('');
                    setTimeout(() => navigate('/login'), 5000);
                })
                .catch(() => {
                    setIsLoading(false);
                    dispatch(
                        sendNotification({
                            text: t('auth.forgotOrResetPassword.validations.somethingWentWrong'),
                            context: 'danger',
                            msToClose: 0,
                        }),
                    );
                });
        }
    };

    return (
        <BasicForm title={t('auth.forgotOrResetPassword.resetTitle') as string} subtitle="">
            <form onSubmit={handlePasswordUpdate}>
                {/* NEW PASSWORD */}
                <FormGroupInput
                    name="newPassword"
                    type="password"
                    placeholder="*******"
                    value={passwordState.value}
                    inputState={passwordState}
                    label={t('auth.forgotOrResetPassword.form.newPass') as string}
                    onChange={onPasswordChange}
                />
                {/* CONFIRM NEW PASSWORD */}
                <FormGroupInput
                    name="confirmNewPassword"
                    type="password"
                    placeholder="*******"
                    value={confirmPasswordState.value}
                    inputState={confirmPasswordState}
                    label={t('auth.forgotOrResetPassword.form.confirmNewPass') as string}
                    onChange={onConfirmPasswordChange}
                />
                {/* SUBMIT */}
                <SubmitButton disabled={!isFormValid || isLoading}>
                    {isLoading
                        ? (t('common.loading') as string)
                        : (t('auth.forgotOrResetPassword.form.resetSubmitButton') as string)}
                </SubmitButton>
            </form>

            <Loader show={isLoading} />
        </BasicForm>
    );
};

export default ResetPasswordForm;

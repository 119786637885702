import { baseApi } from '../../api/api';

export const reportingApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getLookerReportUrl: builder.query({
            query: ({ userId, name, publisherId, lookerReportId }) => ({
                url: '/external/looker/publishers',
                method: 'POST',
                body: {
                    userId: userId.toString(),
                    firstName: name,
                    lastName: name,
                    entityId: publisherId.toString(),
                    lookerReportId,
                },
            }),
            transformResponse: ({ response }) => response,
        }),
    }),
});

export const { useGetLookerReportUrlQuery } = reportingApiSlice;

import React from 'react';
import cx from 'classnames';

interface NoDataProps {
    className?: string;
    hasBorder?: boolean;
    isInline?: boolean;
    message?: string;
    size?: string;
    style?: React.CSSProperties;
}

const NoData = ({
    message = 'No data to display.',
    isInline = false,
    hasBorder = false,
    size = 'lg',
    className = '',
    style = {},
}: NoDataProps) => (
    <div
        className={cx(className, '-text-center -p-a-3 -color-tx-lt-4', {
            'gds-well': hasBorder,
        })}
        style={style}>
        <i
            className={cx('fa fa-fw fa-exclamation-triangle -inline-block', {
                [`gds-text--header-${size}`]: size,
            })}
        />
        {!isInline && <br />}
        <span
            className={cx({
                '-m-l-2': isInline,
            })}>
            {message}
        </span>
    </div>
);

export default NoData;

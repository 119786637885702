import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import Footer from '../../components/footer/Footer';
import HeaderManager from '../../components/header/HeaderManager';
// Components
import NavMenu from '../../components/nav/NavMenu';
import Alert from '../../components/common/Alert';
// Constants
import { JUST_PREMIUM_BUSINESS_UNITS } from '../../constants/app';
import navOptions from '../../constants/navOptions';
import { accountSettingsTabOptions } from '../../constants/tabOptions';
import AccountSettings from '../../features/accountSettings/AccountSettings';
import Blocking from '../blocks/Blocking';
import Payments from '../../features/payments/Payments';
import ReportingTabs from '../../features/reporting/ReportingTabs';
import Zone from '../../features/zone/Zone';
import ZoneNew from '../../features/zone/ZoneNew';
import Zones from '../../features/zones/Zones';
import Browse from '../Browse/Browse';
// Store
import { useAppSelector } from '../../store';
import { useUpdateGDPRDateMutation } from './gdprApiSlice';
import { updatePublisher } from '../Browse/publisherReducer';
// Helpers
import { isInternalUser } from '../../helpers/authorization';

const MainContent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);
    const { id, businessUnitId, gdprAcceptedAt } = useAppSelector(state => state.publisher);
    const [updateGPDRDate] = useUpdateGDPRDateMutation();
    const [navOpen, setNavOpen] = useState(false);
    const [isGDPRAccepted, setIsGDPRAcceptedAt] = useState(true);

    useEffect(() => {
        if (id) {
            setIsGDPRAcceptedAt(!!gdprAcceptedAt);
        }
    }, [gdprAcceptedAt]);

    const handleGDPRAccept = () => {
        updateGPDRDate({
            publisherId: id,
        }).then(response => {
            if (response.data.gdprAcceptedAt) {
                dispatch(updatePublisher({ gdprAcceptedAt: response.data.gdprAcceptedAt }));
                setIsGDPRAcceptedAt(true);
            }
        });
    };

    const isLegacyJustPremium = JUST_PREMIUM_BUSINESS_UNITS.includes(parseInt(businessUnitId));

    const restrictedTabOptions = accountSettingsTabOptions.filter(tabOption =>
        isLegacyJustPremium
            ? ['/billing', '/password-update', '/authorized-digital-sellers'].includes(
                  tabOption.path,
              )
            : tabOption,
    );

    const restrictedNavOptions = navOptions.filter(navOption =>
        isLegacyJustPremium
            ? !['/zones', '/global-advertiser-blocks'].includes(navOption.path)
            : navOption,
    );

    const _toggleNavOpen = () => setNavOpen(!navOpen);

    restrictedNavOptions[restrictedNavOptions.length - 1].children = restrictedTabOptions;

    return (
        <>
            <Routes>
                <Route path="/browse" element={<Browse />} />
                <Route
                    path="*"
                    element={
                        <div className="gds-app-layout__container">
                            <HeaderManager toggleNavOpen={_toggleNavOpen} />
                            <NavMenu
                                navOpen={navOpen}
                                navOptions={restrictedNavOptions}
                                toggleNavOpen={_toggleNavOpen}
                            />
                            <div className="gds-app-layout__main">
                                <Routes>
                                    <Route
                                        path="home/:reportingType"
                                        element={
                                            <ReportingTabs
                                                isLegacyJustPremium={isLegacyJustPremium}
                                            />
                                        }
                                    />
                                    <Route
                                        path="payments/*"
                                        element={
                                            <Payments isLegacyJustPremium={isLegacyJustPremium} />
                                        }
                                    />
                                    <Route
                                        path="zones/edit/:zoneId/*"
                                        element={
                                            <AuthorizedRoute
                                                isAuthorized={!isLegacyJustPremium}
                                                element={<Zone />}
                                            />
                                        }
                                    />
                                    <Route
                                        path="zones/new"
                                        element={
                                            <AuthorizedRoute
                                                isAuthorized={!isLegacyJustPremium}
                                                element={<ZoneNew />}
                                            />
                                        }
                                    />
                                    <Route
                                        path="zones"
                                        element={
                                            <AuthorizedRoute
                                                isAuthorized={!isLegacyJustPremium}
                                                element={<Zones />}
                                            />
                                        }
                                    />
                                    <Route
                                        path="global-advertiser-blocks/*"
                                        element={
                                            <div>
                                                <AuthorizedRoute
                                                    isAuthorized={!isLegacyJustPremium}
                                                    element={<Blocking />}
                                                />
                                            </div>
                                        }
                                    />
                                    <Route
                                        path={'account-settings/*'}
                                        element={
                                            <AccountSettings
                                                tabOptions={restrictedTabOptions}
                                                isLegacyJustPremium={isLegacyJustPremium}
                                            />
                                        }
                                    />
                                </Routes>
                                <Footer isLegacyJustPremium={isLegacyJustPremium} />
                            </div>
                            {!isInternal && !isGDPRAccepted && (
                                <Alert
                                    body={t('termsAndConditions.gdpr', {
                                        gdprUrl: t('termsAndConditions.gdprUrl'),
                                    })}
                                    onAccept={handleGDPRAccept}
                                    acceptButtonText={t('common.accept')}
                                    hasRejectOption={false}
                                    context="danger"
                                    isInnerHtml={true}
                                    icon={
                                        <i
                                            className="gds-alert__icon fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        />
                                    }
                                />
                            )}
                        </div>
                    }
                />
            </Routes>
        </>
    );
};

export default MainContent;

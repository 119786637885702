import Pagination from 'gumdrops/Pagination';
import Select from 'gumdrops/Select';
import PropTypes from 'prop-types';

import { DATA_REQUEST_SIZE_OPTIONS } from '../../constants/app';

const TablePaginationFooter = ({
    totalPages,
    totalElements,
    activePage,
    size,
    handlePageChange,
    handleSizeChange,
    hideSizeOption,
}) => {
    const showFooter = !!totalPages;
    const showPagination = totalPages && totalPages > 1;
    const minSize = parseInt(DATA_REQUEST_SIZE_OPTIONS[0].value);
    const showSizeOptions = !hideSizeOption && handleSizeChange && totalElements > minSize;

    return (
        showFooter && (
            <div className="gds-flex -m-a-1-xs gds-flex--justify-between -p-t-3 gds-card__block--divide-top -color-bd-lt-1">
                <div className="gds-text--body-xs gds-flex__item -m-h-1-xs -m-t-2 -m-b-1 -m-l-4">
                    {totalElements} Result{totalElements > 1 && 's'}
                </div>
                {showPagination && (
                    <div className="gds-flex__item">
                        <Pagination
                            activePage={activePage}
                            lastPage={totalPages}
                            boundaries
                            justify
                            onChange={handlePageChange}
                            size="xs"
                        />
                    </div>
                )}
                <div className="gds-form-group gds-flex__item gds-flex gds-flex--justify-end -m-h-1-xs -m-b-3 -m-r-4">
                    {showSizeOptions && (
                        <Select
                            name="size"
                            size="xs"
                            style={{ maxWidth: '70px' }}
                            options={DATA_REQUEST_SIZE_OPTIONS}
                            value={!size ? minSize : size}
                            onChange={handleSizeChange}
                        />
                    )}
                </div>
            </div>
        )
    );
};

TablePaginationFooter.propTypes = {
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    activePage: PropTypes.number,
    size: PropTypes.number,
    hideSizeOption: PropTypes.bool,
};

TablePaginationFooter.defaultProps = {
    activePage: 1,
    size: 10,
    totalPages: 0,
    totalElements: 0,
    hideSizeOption: false,
    handleSizeChange: null,
    handlePageChange: null,
};

export default TablePaginationFooter;

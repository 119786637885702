import { baseApi } from '../../../api/api';
import { getEntityTitle, removeEmptyProperties } from '../../../helpers/utils';
import { SelfIdentifier, ZoneDomain, ZoneDomainSelfIdentifiers } from '../../../types/Account';
import { Paginator } from '../../../types/Paginator';
import { PublisherFilters } from '../../../types/Publisher';

export const inclusiveInventoryApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getSelfIdentifiers: builder.query<SelfIdentifier[], null>({
            query: () => ({ url: '/external/self-identifiers' }),
        }),
        getZoneDomainSelfIdentifiers: builder.query<ZoneDomainSelfIdentifiers, PublisherFilters>({
            query: ({ publisherId, filters }) => ({
                url: `/external/publishers/${publisherId}/zone-domain-self-identifiers`,
                params: removeEmptyProperties(filters),
            }),
            providesTags: ['Self Identifier'],
            transformResponse: (
                response: Paginator & {
                    content: { zoneDomain: ZoneDomain; selfIdentifiers: SelfIdentifier[] }[];
                },
            ) => ({
                ...response,
                content: response.content.map(({ zoneDomain, selfIdentifiers }) => ({
                    ...zoneDomain,
                    zoneDomainName: zoneDomain.domain,
                    zoneDomainId: zoneDomain.id,
                    zoneDomainSelfIdentifiers: selfIdentifiers
                        .map(item => getEntityTitle(item))
                        .join(', '),
                    selfIdentifiers,
                })),
            }),
        }),
        createZoneDomainSelfIdentifiers: builder.mutation({
            query: ({ publisherId, zonesSelfIdentifiers }) => ({
                url: `/external/publishers/${publisherId}/zone-domain-self-identifiers`,
                method: 'POST',
                body: zonesSelfIdentifiers,
            }),
            invalidatesTags: ['Self Identifier'],
        }),
    }),
});

export const {
    useGetSelfIdentifiersQuery,
    useGetZoneDomainSelfIdentifiersQuery,
    useCreateZoneDomainSelfIdentifiersMutation,
} = inclusiveInventoryApiSlice;

import React, { CSSProperties } from 'react';

const WIDTH_BY_SIZE = {
    md: { width: '20px' },
    sm: { width: '20px' },
    xs: { width: '15px' },
};

interface InputSpinnerProps {
    size: 'xs' | 'sm' | 'md';
    style?: CSSProperties | undefined;
    stroke?: string | undefined;
}

const InputSpinner = ({ size = 'sm', style = {}, stroke = '#51bba7' }: InputSpinnerProps) => (
    <div className="gds-input-spinner-pos" style={style}>
        <svg
            className="gds-input-spinner"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            style={{ ...WIDTH_BY_SIZE[size] }}>
            <circle className="gds-input-spinner-inner" cx="10" cy="10" r="9" stroke={stroke} />
        </svg>
    </div>
);

export default InputSpinner;

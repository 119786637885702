import cx from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

// Components
import InputSpinner from './InputSpinner';

const SEARCH_ICON_STYLES = {
    sm: { top: '.6rem', right: '.4rem' },
    xs: { top: '.4rem', right: '.4rem' },
};
const CLOSE_ICON_STYLES = {
    sm: { border: '0px', background: 'transparent', top: '.3rem' },
    xs: { border: '0px', background: 'transparent', top: '.1rem' },
};
const INPUT_SPINNER_STYLES = {
    md: { top: '.4rem' },
    sm: { top: '.5rem', right: '3.1rem' },
    xs: { top: '.4rem', right: '3.1rem' },
};

class SearchInput extends Component {
    static propTypes = {
        placeholder: PropTypes.string.isRequired,
        size: PropTypes.oneOf(['xs', 'sm', 'md']),
        inputStyle: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        isTermControlled: PropTypes.bool.isRequired,
        searchTerm: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        isFocused: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        hasCloseOption: PropTypes.bool.isRequired,
        onCloseOptionClick: PropTypes.func,
        className: PropTypes.string,
        initialValue: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        placeholder: 'Search...',
        size: 'sm',
        inputStyle: {},
        name: 'search-input',
        isTermControlled: false,
        searchTerm: '',
        loading: false,
        hasCloseOption: false,
        initialValue: '',
    };

    state = { term: '' };

    componentDidMount() {
        const { initialValue } = this.props;
        const trimmedInitialValue = initialValue.trim();
        if (trimmedInitialValue.length > 0) {
            this.setTerm(trimmedInitialValue);
        }
    }

    componentDidUpdate() {
        const { isTermControlled, searchTerm } = this.props;
        const { term } = this.state;
        if (isTermControlled && searchTerm !== term) {
            this.setTerm(searchTerm);
        }
    }

    setTerm = term => this.setState({ term });

    onTermChange = ({ target: { value } }) => {
        this.setTerm(value);
        this.props.onChange(value);
    };

    onCloseOptionClick = () => {
        this.setState({ term: '' }, this.props.onCloseOptionClick);
    };

    render() {
        const {
            onBlur,
            placeholder,
            size,
            inputStyle,
            name,
            onClick,
            isFocused,
            loading,
            hasCloseOption,
            className,
            style,
        } = this.props;
        const { term } = this.state;
        const inputLoading = !!term && loading;
        const showCloseOption = !!term;

        return (
            <div className={cx('gds-search-select__control', className)} style={style}>
                <input
                    type="text"
                    placeholder={placeholder}
                    className={`gds-search-select__input--${size}`}
                    style={inputStyle}
                    autoComplete="off"
                    name={name}
                    onChange={this.onTermChange}
                    onBlur={onBlur}
                    value={term}
                    onClick={onClick}
                    autoFocus={isFocused}
                    data-cy="search-input"
                />
                <i
                    className="btl bt-search search-icon -color-tx-lt-4"
                    style={SEARCH_ICON_STYLES[size]}
                />
                {inputLoading && (
                    <InputSpinner
                        size={size}
                        style={{
                            right: `${showCloseOption ? '50px' : '40px'}`,
                            ...INPUT_SPINNER_STYLES[size],
                        }}
                    />
                )}
                <button
                    className={cx('gds-search-select__clear', {
                        '-none': !(hasCloseOption && showCloseOption),
                    })}
                    style={CLOSE_ICON_STYLES[size]}
                    type="button"
                    onClick={this.onCloseOptionClick}>
                    <i className="fas fa-times-circle fa-2x" />
                </button>
            </div>
        );
    }
}

export default SearchInput;

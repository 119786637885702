import { addIndex, assoc, compose, map } from 'ramda';

/*
 * Returns an assoc lambda that adds the 'selected' value to an object.
 *
 * @param   {boolean}   selected        True to set selected.
 * @param   {string}    selectedVar     The property to be used to store the selected status of the object.
 * @returns {function}
 */
export const addSelected = (selected = true, selectedVar) => assoc(selectedVar, selected);

/*
 * Transform an object into a mapped object with a value and a name.
 *
 * @param   {Object}    element     Original object
 * @param   {string}    valueVar    Param from the original object to be used on the value 'value' of final object. Default 'id'.
 * @param   {string}    nameVar     Param from the original object to be used on the value 'name' of final object. Default 'name'.
 * @param   {number}    key         Key of the indexed element
 * @returns {Object}
 */
const changeMappingsValue = (
    element,
    valueVar = 'id',
    nameVar = 'name',
    key,
    keepAllKeys = false,
    useValueAsKey = false,
) => {
    const mappingObject = {
        value: element[valueVar],
        name: element[nameVar],
        key: useValueAsKey ? element[valueVar] : key,
    };
    return keepAllKeys ? { ...element, ...mappingObject } : mappingObject;
};

// For Select
export const mapDataSelect = map(changeMappingsValue);

/**
 * Converts an array of objects into multiselect data.
 *
 * @param   {Object[]}  options     Array of objects to convert.
 * @param   {Object}    config      (Optional) Object with options:
 *                                      valueVar: Param from the original object to be used on the value 'value' of final object. Default 'id'.
 *                                      nameVar: Param from the original object to be used on the value 'name' of final object. Default 'name'.
 *                                      selectedVar: The property to be used to store the selected status of the object. Default 'selected'
 *                                      selectedValue: True to set default selected to true.
 *                                      keepAllKeys: True to keep all the keys in each element.
 * @returns {Object[]}
 */
export const mapDataMultiSelect = (options, config = {}) => {
    const {
        valueVar = 'id',
        nameVar = 'name',
        selectedVar = 'selected',
        selectedValue = false,
        keepAllKeys = false,
        useValueAsKey = false,
    } = config;

    return addIndex(map)(
        compose(
            el => addSelected(selectedValue, selectedVar)(el, selectedVar, selectedValue),
            (el, key) =>
                changeMappingsValue(el, valueVar, nameVar, key, keepAllKeys, useValueAsKey),
        ),
        options,
    );
};

/**
 * From an array of options of a select element, return the selected values in an array.
 *
 * @param   {Object[]}  options     Array of options from a select
 * @returns {(string|number)[]}
 */
export const getSelectedValues = (options, selectedVar = 'selected', valueVar = 'value') =>
    options.filter(item => item[selectedVar] === true).map(item => item[valueVar]);

/**
 * Preselects items in an array of options
 *
 * @param   {Object[]}  options     Array of objects to update.
 * @param   {Object[]}  preSelectedValues     Array of pre selected values.
 * @param   {Object}    config      (Optional) Object with options:
 *                                      valueVar: Param from the original object to be used on the value 'value' of final object. Default 'value'.
 *                                      selectedVar: The property to be used to store the selected status of the object. Default 'selected'
 * @returns {Object[]}
 */
export const setPreSelected = (options, preSelectedValues, config = {}) => {
    const { valueVar = 'value', selectedVar = 'selected' } = config;
    const preSelectedStringValues = preSelectedValues.map(item => item.toString());

    return options.map(item => ({
        ...item,
        [selectedVar]: preSelectedStringValues.includes(item[valueVar].toString())
            ? true
            : item[selectedVar],
    }));
};

import Button from 'gumdrops/Button';
import Select from 'gumdrops/Select';
// Components
import Table from 'gumdrops/Table';
import TableData from 'gumdrops/TableData';
import TextInput from 'gumdrops/TextInput';
import Tooltip from 'gumdrops/Tooltip';
import PropTypes from 'prop-types';
import { clone } from 'ramda';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '../../components/common/IconButton';
// Constants
import { EMPTY_SLOT, SLOT_FLEX_SIZE_ID } from '../../constants/app';

const SlotsCreateForm = ({
    toggleCreateForm,
    createSlots,
    slotImplementationTypes,
    slotSizes,
    slots,
}) => {
    const { t } = useTranslation();
    const [slotsList, setSlotsList] = useState(clone([EMPTY_SLOT]));
    const [selectedSlotSizeIds, setSelectedSlotSizeIds] = useState([]);

    useEffect(() => {
        let selectedSizes = [];
        slotSizes.map(slotSize => {
            if (selectedSlotSizeIds.includes(slotSize.id)) {
                selectedSizes.push(slotSize);
            }
        });

        setNewSlotRows(selectedSizes, {});
    }, [selectedSlotSizeIds]);

    const addRow = () => setSelectedSlotSizeIds([EMPTY_SLOT.sizeId]);

    const setNewSlotRows = (selectedSlotSizeIds, slotDetails = {}) => {
        const newSlotsList = selectedSlotSizeIds.map(slotSize => ({
            ...EMPTY_SLOT,
            ...slotDetails,
            sizeId: slotSize.id,
            name: '',
        }));

        setSlotsList([...slotsList, ...newSlotsList]);
    };

    const _handleSlotSizeChange = ({ target: { value, name } }, position) => {
        const newSlotsList = clone(slotsList);
        newSlotsList[position][name] = value;
        newSlotsList[position].placementId = '';

        setSlotsList(newSlotsList);
    };

    const handleChange = ({ target: { name, value } }, rowId) =>
        setSlotsList(
            slotsList.map((slot, key) => {
                if (key === rowId) {
                    slot[name] = value;
                }
                return slot;
            }),
        );

    const removeRow = positionToRemove =>
        setSlotsList(slotsList.filter((_, key) => key !== positionToRemove));

    const handleCreate = () => {
        const nameCounts = {};

        slots.forEach(slot => {
            const nameBase = slot.name.split('-')[0];
            const number = slot.name.includes('-') ? parseInt(slot.name.split('-')[1], 10) : 0;

            if (
                !isNaN(number) &&
                (nameCounts[nameBase] === undefined || number > nameCounts[nameBase])
            ) {
                nameCounts[nameBase] = number;
            }
        });

        const updatedSlotsList = slotsList.map(slot => {
            const updatedSlot = { ...slot };
            const correspondingSlotSize = slotSizes.find(
                size => size.id === Number(updatedSlot.sizeId),
            );

            if (!updatedSlot.name && correspondingSlotSize) {
                updatedSlot.name = correspondingSlotSize.name.replace(/\s/g, '');
                if (nameCounts[updatedSlot.name] !== undefined) {
                    nameCounts[updatedSlot.name]++;
                    updatedSlot.name += `-${nameCounts[updatedSlot.name]}`;
                } else {
                    nameCounts[updatedSlot.name] = 0;
                }
            }

            return updatedSlot;
        });

        return createSlots(updatedSlotsList);
    };

    /**
     * Cells Decorators
     */
    const _nameCellDecorator = (value, key, row) => (
        <TableData key={key}>
            <TextInput
                size="xs"
                name="name"
                placeholder="Name"
                value={value}
                onChange={event => handleChange(event, row.id)}
            />
        </TableData>
    );
    const _sizeCellDecorator = (value, key, row) => (
        <TableData key={key}>
            <Select
                size="xs"
                name="sizeId"
                options={slotSizes}
                onChange={event => _handleSlotSizeChange(event, row.id)}
                value={value}
                placeholder="select a size"
                customValue="id"
            />
        </TableData>
    );

    const _implementationTypeCellDecorator = (value, key, row) => (
        <TableData key={key}>
            <Select
                size="xs"
                className="gds-form-group__select-input--sm"
                options={slotImplementationTypes.map(type => ({
                    name: type.name,
                    value: type.id,
                }))}
                name="implementationTypeId"
                value={value}
                onChange={event => handleChange(event, row.id)}
            />
        </TableData>
    );

    const _deleteCellDecorator = (_, key, row) => (
        <TableData key={key}>
            <IconButton
                onClick={() => removeRow(row.id)}
                disabled={slotsList.length < 2}
                size="xs"
                icon="trash"
                name="remove-row"
                context="danger"
                className="-m-a-0"
            />
        </TableData>
    );

    /**
     * Table data and column config
     */
    const _getData = () =>
        slotsList.map((slot, key) => ({
            ...slot,
            id: key, // Allows to easily manipulate the data as Table returns a more complicated key 'row-X-key-X'
            delete: '',
        }));

    const _getColumns = () => [
        {
            children: 'Name',
            key: 'name',
            dataCellDecorator: _nameCellDecorator,
        },
        {
            children: 'Size',
            key: 'sizeId',
            dataCellDecorator: _sizeCellDecorator,
            disableSorting: true,
        },
        {
            children: 'Implementation Type',
            key: 'implementationTypeId',
            dataCellDecorator: _implementationTypeCellDecorator,
            disableSorting: true,
        },
        {
            children: ' ',
            key: 'delete',
            dataCellDecorator: _deleteCellDecorator,
            disableSorting: true,
            headingProps: {
                style: {
                    width: '50px',
                },
            },
        },
    ];

    const btnAddLabel =
        slotsList.length > 1
            ? t('zone.slots.addNumNew', {
                  count: slotsList.length,
              })
            : t('zone.slots.add1New');

    const hasMoreThanOneFlexSlot = slotsList.filter(x => x.sizeId === SLOT_FLEX_SIZE_ID).length > 1;

    return (
        <div className="-p-t-2 -p-h-3 -p-b-3 -m-b-3">
            <div className="gds-flex gds-flex--justify-between -m-b-2">
                <div className="gds-flex__item" style={{}}>
                    Use this form to request new slots for your zone. Slot requests will be approved
                    by GumGum.
                </div>
            </div>
            <Table
                className="gds-table gds-table--striped"
                size="sm"
                data={_getData()}
                columns={_getColumns()}
                isResponsive
            />
            <div className="-text-right -m-t-1 -m-r-3">
                <IconButton
                    icon="plus"
                    context="primary"
                    size="xs"
                    className="-m-a-0"
                    title="Add Another Slot"
                    onClick={addRow}
                />
            </div>
            <div className="-text-right">
                <Button
                    context="outline"
                    size="xs"
                    className="-m-t-2 -m-r-2"
                    onClick={toggleCreateForm}>
                    {t('common.cancel')}
                </Button>
                <Tooltip
                    position="bottom-left"
                    text={hasMoreThanOneFlexSlot ? 'Only 1 flex slot per zone is allowed.' : ''}>
                    <Button
                        context="primary"
                        size="xs"
                        className="-m-t-2 -m-r-3"
                        disabled={hasMoreThanOneFlexSlot}
                        onClick={handleCreate}>
                        {btnAddLabel}
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

SlotsCreateForm.defaultProps = {
    slotSizes: [],
    slotImplementationTypes: [],
};

SlotsCreateForm.propTypes = {
    createSlots: PropTypes.func.isRequired,
    toggleCreateForm: PropTypes.func.isRequired,
    slotSizes: PropTypes.array,
    slotImplementationTypes: PropTypes.array,
};

export default SlotsCreateForm;

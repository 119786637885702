import cx from 'classnames';
import React, { ChangeEvent, CSSProperties } from 'react';

import InputSpinner from '../InputSpinner';

const INPUT_SPINNER_STYLES = {
    md: { top: '.4rem' },
    sm: { top: '.5rem', right: '2.1rem' },
    xs: { top: '.4rem', right: '3.1rem' },
};

const SEARCH_ICON_STYLES = {
    md: { top: '.6rem', right: '.4rem' },
    sm: { top: '.6rem', right: '.4rem' },
    xs: { top: '.4rem', right: '.4rem' },
};

const CLOSE_ICON_STYLES = {
    md: { border: '0px', background: 'transparent', top: '.3rem' },
    sm: { border: '0px', background: 'transparent', top: '.3rem', right: '.3rem' },
    xs: { border: '0px', background: 'transparent', top: '.1rem' },
};

interface SearchInputProps {
    size: 'xs' | 'sm' | 'md';
    isLoading: boolean;
    showClose: boolean;
    placeholder: string;
    name: string;
    style: CSSProperties | undefined;
    onClick: () => void;
    value: string | undefined;
    onChange: (value: string) => void | undefined;
    isFocused: boolean;
}

const SearchInput = ({
    size = 'sm',
    isLoading = false,
    showClose = true,
    placeholder = 'Search...',
    name = 'search-input',
    style = {},
    onClick,
    value,
    onChange,
    isFocused = false,
}: SearchInputProps) => {
    return (
        <div className="gds-search-select__control" data-gds-search-select-control>
            <input
                type="text"
                placeholder={placeholder}
                className={`gds-search-select__input gds-search-select__input--${size}`}
                name={name}
                style={style}
                value={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChange(event.currentTarget.value)
                }
                onClick={onClick}
                data-gds-search-select-input
                autoComplete="off"
                autoFocus={isFocused}
            />
            <i
                className="btl bt-search search-icon -color-tx-lt-4"
                style={SEARCH_ICON_STYLES[size]}
            />
            {isLoading && (
                <InputSpinner
                    size={size}
                    style={{
                        ...INPUT_SPINNER_STYLES[size],
                    }}
                />
            )}
            <button
                className={cx('gds-search-select__clear', {
                    '-none': !(showClose && !!value),
                })}
                style={CLOSE_ICON_STYLES[size]}
                type="button"
                data-gds-search-select-toggle
                onClick={() => onChange('')}>
                <i className="fas fa-times-circle fa-2x" />
            </button>
        </div>
    );
};

export default SearchInput;

// @ts-nocheck
// @todo: Fix the type errors and refactor the code.

// External libraries
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Hooks
import { useAppSelector } from '../../store';
import { useGetZoneQuery } from '../zone/zoneApiSlice';
import { useDeleteRestrictionsMutation, useGetRestrictionsQuery } from './restrictionApiSlice';

// Components
// @ts-ignore
import { CardBlock } from 'gumdrops';
import BlockAdvertisersList from './BlockAdvertisersList';
import BlockAdvertisersModal from './BlockAdvertisersModal';
import Container from '../../components/common/Container';
import Alert from '../../components/common/Alert';
import IconButton from '../../components/common/IconButton';
import CardTab, { CardTabProps } from '../../components/common/CardTab';
import SearchSelectableListWrapper from '../../components/common/SearchSelectableListWrapper';

// Notifications
import { sendNotification } from '../../components/notifications/notificationsReducer';

// Helpers
import { isEmptyCustom } from '../../helpers/utils';
import { isInternalUser } from '../../helpers/authorization';
import { createLoadingSelector } from '../../helpers/createLoadingSelector';

import {
    GLOBAL_ADVERTISER_ID,
    PRODUCTION_HOSTNAME,
    PUBLISHERS,
    RESTRICTION_ID_KEY,
    PUBLISHER_MANAGER_PRODUCTION_HOSTNAME,
    PUBLISHER_MANAGER_STAGE_HOSTNAME,
    ZONES,
} from '../../constants/app';
import { EXCLUDE } from '../../constants/restrictions';

const restrictionType = GLOBAL_ADVERTISER_ID;

interface QueryResult {
    data: {
        restrictions: [];
        clusivity: string;
    };
    isLoading: boolean;
}

interface BlockAdvertisersProps {
    zoneId?: string;
    basePath: string;
}

const BlockAdvertisers = ({ zoneId, basePath }: BlockAdvertisersProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id: publisherId } = useAppSelector(state => state.publisher);
    const isLoadingBlockedAdvertisers = useAppSelector(({ loading }) =>
        createLoadingSelector(['GET_BLOCKED_ADVERTISERS'])(loading),
    );

    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);
    const [showAlert, setShowAlert] = useState(false);
    const [isPublisherBlockedAdvertisersFiltered, setIsPublisherBlockedAdvertisersFiltered] =
        useState(false);

    const isGlobalAdvertiserView = isEmptyCustom(zoneId);

    // Publishers (1)
    const {
        data: publisherBlockedAdvertisers = { restrictions: [], clusivity: '' },
        isLoading: isLoadingPublisherBlockedAdvertisers,
    } = useGetRestrictionsQuery<QueryResult>({
        entity: PUBLISHERS,
        entityId: publisherId,
        restrictionType,
    });

    const [deleteRestrictions, { isLoading: isDeleting }] = useDeleteRestrictionsMutation();

    // Publishers (1)
    let blockedRestrictions;
    let isAddAdvertiserAllowed;
    if (isGlobalAdvertiserView) {
        blockedRestrictions = publisherBlockedAdvertisers.restrictions;

        isAddAdvertiserAllowed =
            !publisherBlockedAdvertisers.clusivity ||
            publisherBlockedAdvertisers.clusivity === EXCLUDE;
    }
    // Publishers + Zones (1 + 2)
    else {
        const {
            data: zoneBlockedAdvertisers = {
                restrictions: [],
                clusivity: '',
            },
        } = useGetRestrictionsQuery<QueryResult>(
            {
                entity: ZONES,
                entityId: zoneId,
                restrictionType,
            },
            { skip: !zoneId },
        );

        isAddAdvertiserAllowed =
            !zoneBlockedAdvertisers.clusivity || zoneBlockedAdvertisers.clusivity === EXCLUDE;

        // Merge restrictions
        blockedRestrictions = isPublisherBlockedAdvertisersFiltered
            ? zoneBlockedAdvertisers.restrictions
            : [...publisherBlockedAdvertisers.restrictions, ...zoneBlockedAdvertisers.restrictions];
    }

    // Alert
    const toggleAlert = () => setShowAlert(!showAlert);
    const onAcceptAlert: ({
        selectedEntries,
        handleClearSelected,
    }: {
        selectedEntries: string[];
        handleClearSelected: () => void;
    }) => () => Promise<void> =
        ({ selectedEntries, handleClearSelected }) =>
        async () => {
            const payload = {
                entity: zoneId ? ZONES : PUBLISHERS,
                entityId: zoneId || publisherId,
                restrictionIds: selectedEntries,
            };

            await deleteRestrictions(payload).unwrap();
            toggleAlert();
            handleClearSelected();
        };

    // Global toggle
    const togglePublisherBlockedAdvertisers = () => {
        setIsPublisherBlockedAdvertisersFiltered(!isPublisherBlockedAdvertisersFiltered);
    };

    const globalToggle = (
        <div className="gds-form-group -m-l-3 gds-flex__item gds-flex__item--grow-0">
            <div className="gds-form-group__toggleswitch gds-form-group__toggleswitch--xs">
                <label className="gds-form-group__toggleswitch-label">
                    <input
                        type="checkbox"
                        className="gds-form-group__toggleswitch-input"
                        value={isPublisherBlockedAdvertisersFiltered.toString()}
                        onChange={togglePublisherBlockedAdvertisers}
                    />
                    <span className="gds-form-group__toggleswitch-indicator" />
                    Hide Global Blocks
                </label>
            </div>
        </div>
    );

    // Notification
    const sendNotificationParams = {
        text: t('blocking.advertisers.contactYourAccountManager'),
        context: 'danger',
        isInnerHtml: false,
    };
    if (isInternal) {
        const hostName =
            window.location.hostname === PRODUCTION_HOSTNAME
                ? PUBLISHER_MANAGER_PRODUCTION_HOSTNAME
                : PUBLISHER_MANAGER_STAGE_HOSTNAME;

        let publisherManagerLink = `${hostName}/publishers/${publisherId}/settings`;
        if (!isGlobalAdvertiserView) {
            const { data: zone } = useGetZoneQuery(zoneId);
            const trackingId = zone?.trackingId;
            publisherManagerLink = `${hostName}/publishers/${publisherId}/zones/details/${trackingId}/settings`;
        }

        sendNotificationParams.text = t('blocking.advertisers.contactYourAccountManagerInternal', {
            publisherManagerLink,
        });
        sendNotificationParams.isInnerHtml = true;
    }

    const contactManagerNotification = () => {
        dispatch(sendNotification(sendNotificationParams));
    };

    // Card tab
    const cardTabProps: CardTabProps = {
        title: t('blocking.advertisers.blocked'),
        buttonText: t('blocking.advertisers.add'),
        buttonPath: `${basePath}/block-advertisers/create`,
        isButtonDisabled: isLoadingPublisherBlockedAdvertisers,
        onClick: !isAddAdvertiserAllowed ? contactManagerNotification : undefined,
    };

    const handleClose = message => {
        typeof message === 'string' &&
            dispatch(sendNotification({ text: message, context: 'danger', msToClose: 3000 }));
        navigate(basePath);
    };

    return (
        <Container>
            <div data-cy="block-advertisers">
                <Routes>
                    <Route
                        path="block-advertisers/create"
                        element={
                            <BlockAdvertisersModal
                                zoneId={zoneId}
                                onClose={message => handleClose(message)}
                                blockedRestrictions={blockedRestrictions}
                            />
                        }
                    />
                </Routes>
                <CardTab {...cardTabProps} />
                <CardBlock>
                    <SearchSelectableListWrapper
                        inputPlaceholder={t('common.search')}
                        entries={blockedRestrictions}
                        customId={RESTRICTION_ID_KEY}
                        zoneId={zoneId}
                        renderCustomActions={({ selectedEntries }: { selectedEntries: string }) => {
                            const isDeleteButtonDisabled =
                                selectedEntries.length === 0 ||
                                isDeleting ||
                                isLoadingBlockedAdvertisers;
                            return (
                                <IconButton
                                    onClick={toggleAlert}
                                    size="xs"
                                    icon="trash-alt"
                                    context="danger"
                                    className={cx('-m-l-1', {
                                        '-disabled': isDeleteButtonDisabled,
                                    })}
                                    disabled={isDeleteButtonDisabled}
                                    style={{ height: 29 }}
                                    data-cy="block-advertisers-remove"
                                />
                            );
                        }}
                        isEntriesPaginated={false}
                        toggle={globalToggle}>
                        {({
                            entries: filteredEntries,
                            selectedEntries,
                            handleChangeEntry,
                            handleClearSelected,
                        }: {
                            entries: string[];
                            selectedEntries: string[];
                            handleChangeEntry: (entry: string) => void;
                            handleClearSelected: () => void;
                        }) => (
                            <>
                                <BlockAdvertisersList
                                    customKey={RESTRICTION_ID_KEY}
                                    advertiserRestrictions={filteredEntries}
                                    zoneId={zoneId}
                                    selectedEntriesIds={selectedEntries}
                                    handleSelectEntry={handleChangeEntry}
                                    isLoading={isLoadingPublisherBlockedAdvertisers}
                                />
                                {showAlert && (
                                    <Alert
                                        title={t('common.proceedWithCaution')}
                                        body={t('blocking.advertisers.areYouSureDeleteMessage')}
                                        onAccept={onAcceptAlert({
                                            selectedEntries,
                                            handleClearSelected,
                                        })}
                                        onReject={toggleAlert}
                                        acceptButtonText={t('common.accept')}
                                        rejectButtonText={t('common.cancel')}
                                        context="primary"
                                        isAcceptDisabled={isDeleting}
                                    />
                                )}
                            </>
                        )}
                    </SearchSelectableListWrapper>
                </CardBlock>
            </div>
        </Container>
    );
};

BlockAdvertisers.propTypes = {
    basePath: PropTypes.string.isRequired,
    zoneId: PropTypes.string,
};

export default BlockAdvertisers;

// External libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import Button from 'gumdrops/Button';
import Card from 'gumdrops/Card';
import Checkbox from 'gumdrops/Checkbox';
import Column from 'gumdrops/Column';
import FormGroup from 'gumdrops/FormGroup';
import FormGroupLabel from 'gumdrops/FormGroupLabel';
import LayoutContainer from 'gumdrops/LayoutContainer';
import RadioGroup from 'gumdrops/RadioGroup';
import TextArea from 'gumdrops/TextArea';

// Components
import ContentHeader from '../../components/common/ContentHeader';
import ValidInput from '../../components/inputs/ValidInput';

// Actions
import { sendNotification } from '../../components/notifications/notificationsReducer';

// Constants
import { ZONE_TYPES } from '../../constants/app';

// Helpers
import { getAvailableServices } from '../../helpers/getAvailableServices';
import { splitAndClean } from '../../helpers/utils';
import { isValidDomain, isValidUrl } from '../../helpers/validations';

// Hooks
import { useAddZoneMutation } from './zoneApiSlice';
import { useAppSelector } from '../../store';

const formInitialState = {
    name: {
        value: '',
        errors: [],
    },
    zoneType: {
        value: ZONE_TYPES[0].id,
    },
    domains: {
        value: '',
        errors: [],
    },
    services: {
        value: [],
    },
};

const ZoneNew = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useAppSelector(state => state.auth.id);
    const publisherId = useAppSelector(state => state.publisher.id);

    const [formState, setFormState] = useState(formInitialState);
    const { name, zoneType, domains, services } = formState;
    const appZoneTypeId = ZONE_TYPES.find(({ key }) => key === 'app').id;
    const isApp = parseInt(zoneType.value) === appZoneTypeId;

    const [addZone, { isLoading: isAddingZone }] = useAddZoneMutation();

    const handleChange = ({ target: { value, name } }) => {
        const isEmptyString = typeof value === 'string' && !value.trim().length;

        setFormState({
            ...formState,
            [name]: {
                value,
                ...(isEmptyString ? { errors: [t('errorMessages.required')] } : { errors: [] }),
            },
            // reset inputs if zone type is switched
            ...(name === 'zoneType'
                ? {
                      ...domains,
                      ...services,
                      domains: { value: '' },
                      services: { value: [] },
                  }
                : {}),
        });
    };

    const handleToggleService = (services, serviceId) => () =>
        handleChange({
            target: {
                name: 'services',
                value: services.includes(serviceId)
                    ? services.filter(item => item !== serviceId)
                    : [...services, serviceId],
            },
        });

    const hasInvalidDomains = () => {
        const domainsToProcess = splitAndClean(domains.value);
        return domainsToProcess.some(item => (isApp ? !isValidUrl(item) : !isValidDomain(item)));
    };

    const validateAndSave = () => {
        if (hasInvalidDomains()) {
            dispatch(
                sendNotification({
                    text: isApp ? t('errorMessages.url') : t('errorMessages.domain'),
                    neverFade: true,
                    context: 'danger',
                }),
            );
        } else {
            handleSave();
        }
    };

    const _formatPayload = () => {
        const domainsToProcess = splitAndClean(domains.value);
        return {
            userId: userId.toString(),
            publisherId: publisherId.toString(),
            name: name.value.trim(),
            domains: !isApp
                ? domainsToProcess.map(item =>
                      item.startsWith('*.') ? item : '*.' + item.replace(/^(www[0-9]?\.)/, ''),
                  )
                : domainsToProcess,
            typeId: zoneType.value,
            services: services.value,
        };
    };

    const handleSave = async () => {
        try {
            const { trackingId } = await addZone(_formatPayload()).unwrap();

            dispatch(sendNotification({ text: t('newZone.zoneCreated'), context: 'success' }));
            navigate(`/zones/edit/${trackingId}/overview`);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            dispatch(sendNotification({ text: t('newZone.zoneCreationError'), context: 'danger' }));
        }
    };

    const isSubmitEnabled =
        name.value.length &&
        !name.errors.length &&
        domains.value.length &&
        !domains.errors.length &&
        !isAddingZone;

    const zoneTypeOptions = ZONE_TYPES.map(({ id: value, key }) => ({
        label: t('newZone.' + key),
        value,
    }));

    return (
        <LayoutContainer>
            <ContentHeader />
            <Card className="-p-a-2 -p-b-3 -m-h-2" style={{ overflow: 'auto' }}>
                <Column className="-m-b-2-md" md="12">
                    <ValidInput
                        size="xs"
                        label={t('newZone.zoneName')}
                        name="name"
                        className="-m-b-2"
                        placeholder={t('newZone.zoneName')}
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={name.value}
                        errors={name.errors}
                    />
                    <FormGroupLabel text={t('newZone.zoneType')} />
                    <RadioGroup
                        size="xs"
                        name="zoneType"
                        onChange={handleChange}
                        options={zoneTypeOptions}
                        defaultValue={zoneTypeOptions[0].value}
                    />
                    <FormGroupLabel text={t(isApp ? 'newZone.appStoreUrl' : 'newZone.domain')} />
                    <TextArea
                        size="xs"
                        name="domains"
                        label={t(isApp ? 'newZone.appStoreUrl' : 'newZone.domain')}
                        resize="resize-v"
                        placeholder={t(
                            isApp ? 'newZone.appStoreUrlPlaceholder' : 'newZone.domainPlaceholder',
                        )}
                        style={{ height: '180px' }}
                        className="-m-b-2"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={domains.value}
                        errors={domains.errors}
                    />
                    <FormGroup>
                        <FormGroupLabel text={t('newZone.servicesEnabled')} />
                        <FormGroup>
                            {getAvailableServices({ isApp }).map(({ id, key }, index) => (
                                <Checkbox
                                    key={index}
                                    size="xs"
                                    placeholder=""
                                    name="services"
                                    label={t('unit.' + key)}
                                    checked={services.value.includes(id)}
                                    onChange={handleToggleService(services.value, id)}
                                />
                            ))}
                        </FormGroup>
                    </FormGroup>
                    <Button
                        className={cx('-m-v-3 -m-r-1', {
                            '-disabled': !isSubmitEnabled,
                        })}
                        size="xs"
                        context="primary"
                        disabled={!isSubmitEnabled}
                        onClick={validateAndSave}>
                        {t('newZone.addZone')}
                    </Button>
                    <Link to="/zones" className="-color-tx-white">
                        <Button className="-m-v-3" size="xs">
                            {t('common.cancel')}
                        </Button>
                    </Link>
                    <p>{t('newZone.note')}</p>
                </Column>
            </Card>
        </LayoutContainer>
    );
};

ZoneNew.displayName = 'ZoneNew';

export default ZoneNew;

// Components
import Card from 'gumdrops/Card';
import CardBlock from 'gumdrops/CardBlock';
import Column from 'gumdrops/Column';
import LayoutContainer from 'gumdrops/LayoutContainer';
import Row from 'gumdrops/Row';
import { useTranslation } from 'react-i18next';

import LoadingOverlay from '../../../components/common/LoadingOverlay';
// Common
import { adsTxtTransformer, getVideoAdsTxt } from './adsTxt.utils';
// Hooks
import { useGetAdsTxtQuery } from './adsTxtApiSlice';
import { useAppSelector } from '../../../store';

const adTextCodeStyle = {
    background: '#272822',
    color: '#f8f8f2',
    borderRadius: 2,
    fontFamily: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    fontSize: '0.8rem',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
};

const AdsTxt = () => {
    const { t } = useTranslation();
    const publisher = useAppSelector(state => state.publisher);
    const {
        data: adsTxts = [],
        error,
        isLoading,
        isError,
    } = useGetAdsTxtQuery({
        publisherId: publisher.id,
        businessUnitIds: publisher.businessUnitId,
    });

    // eslint-disable-next-line no-console
    if (isError) console.log(error);

    const adsTxtsDisplay = adsTxtTransformer(adsTxts);

    const videoAdsTxts = adsTxtTransformer(getVideoAdsTxt(adsTxts));
    const hasVideoAdsTxts = videoAdsTxts.length > 0;

    return (
        <LayoutContainer>
            <Row>
                <Column>
                    <h1 className="gds-text--header-lg -m-b-3" data-cy="ads-txt-title">
                        {t('accountSettings.adsTxt.title')}
                    </h1>
                </Column>
            </Row>
            {isLoading ? (
                <LoadingOverlay showOverlay showDots loadingDotsClass="-m-t-3" />
            ) : isError ? (
                <div className="gds-well gds-well--danger -m-b-2">
                    <p className="gds-well__text">{t('errorMessages.general')}</p>
                </div>
            ) : (
                <Card>
                    <CardBlock className="-p-a-3">
                        <p className="-m-b-2">{t('accountSettings.adsTxt.msg1')}</p>
                        <p>{t('accountSettings.adsTxt.msg2')}</p>

                        <div className="ads-txt-content">
                            <pre className="language-html -m-v-0" style={adTextCodeStyle}>
                                <code style={{ whiteSpace: 'pre-wrap' }}>{adsTxtsDisplay}</code>
                            </pre>
                        </div>

                        {hasVideoAdsTxts && (
                            <div className="video-ads-txt-content">
                                <hr className="-m-t-4 -m-b-3" />
                                <p>{t('accountSettings.adsTxt.videoLabel')}</p>
                                <pre className="language-html -m-v-0" style={adTextCodeStyle}>
                                    <code style={{ whiteSpace: 'pre-wrap' }}>{videoAdsTxts}</code>
                                </pre>
                            </div>
                        )}

                        <p
                            className="-m-t-3"
                            dangerouslySetInnerHTML={{
                                __html: t('accountSettings.adsTxt.msg3'),
                            }}
                        />
                    </CardBlock>
                </Card>
            )}
        </LayoutContainer>
    );
};

export default AdsTxt;

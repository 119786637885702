// @ts-nocheck
import { isFulfilled, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import notifications from '../../constants/notifications';
import i18n from '../../translations/i18n';
import { sendNotification } from './notificationsReducer';

const MS_TO_CLOSE = 5000; // ms

const notificationMiddleware: Middleware =
    ({ dispatch }: MiddlewareAPI) =>
    next =>
    action => {
        if (action.meta && action.meta.arg) {
            const {
                requestStatus = '',
                arg: { endpointName },
            } = action.meta;
            const context = {
                pending: 'warning',
                fulfilled: 'success',
                rejected: 'danger',
            };

            // @ts-ignore
            if (notifications[endpointName] || isRejectedWithValue(action)) {
                dispatch(
                    sendNotification({
                        text: isRejectedWithValue(action)
                            ? i18n.t('errorMessages.general')
                            : // @ts-ignore
                              `${notifications[endpointName].replace(
                                  '[tense]',
                                  isFulfilled(action) ? 'ed' : 'ing',
                              )}`,
                        // @ts-ignore
                        context: context[requestStatus],
                        ...(isRejectedWithValue(action)
                            ? {
                                  neverFade: true,
                              }
                            : {
                                  endpointName,
                                  requestStatus,
                              }),
                        ...(isFulfilled(action) ? { msToClose: MS_TO_CLOSE } : {}),
                    }),
                );
            }
        }

        return next(action);
    };

export default notificationMiddleware;

import cx from 'classnames';
// @ts-ignore
import Button from 'gumdrops/Button';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface BulkActionsProps {
    selectedEntriesCount: number;
    actionsList: [];
}

interface ActionItem {
    onClick?: () => void;
    path: string;
    title: string;
}

const BulkActions = ({ selectedEntriesCount = 0, actionsList }: BulkActionsProps) => {
    const [isListOpen, setIsListOpen] = useState(false);

    const hasActions = actionsList.length > 0;
    const isDisabled = !hasActions || !selectedEntriesCount;
    const isActive = hasActions && isListOpen && selectedEntriesCount > 0;

    // instead of getDerivedStateFromProps
    if (selectedEntriesCount === 0 && isListOpen) {
        setIsListOpen(false);
    }

    const toggleList = () => {
        setIsListOpen(!isListOpen);
    };

    const onItemClick = (item: ActionItem) => () => {
        item.onClick && item.onClick();
        toggleList();
    };

    return (
        <div
            className={cx('gds-button-dropdown gds-button-dropdown--in-group', {
                'gds-button-dropdown--active': isActive,
            })}>
            <Button
                name="actions-btn"
                className="gds-button-dropdown__button"
                context="secondary"
                style={{ width: '100px' }}
                disabled={isDisabled}
                onClick={toggleList}>
                Actions
            </Button>

            {isActive && (
                <ul
                    id="actions-list"
                    className="gds-button-dropdown__menu -color-bg-white"
                    style={{ width: '200px' }}>
                    {actionsList.map((item: ActionItem, index) => (
                        <li
                            key={index}
                            className="gds-button-dropdown__menu-item gds-text--body-xs">
                            <div
                                className="gds-button-dropdown__menu-link -color-tx-dk-5 -p-a-0 -m-l-2"
                                style={{ fontSize: '0.7rem' }}
                                onClick={onItemClick(item)}>
                                <Link className="link-table-cell" to={`${item.path}`}>
                                    {item.title}
                                </Link>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default BulkActions;

import React, { ReactNode } from 'react';

import styles from './BasicForm.module.scss';

interface BasicFormProps {
    title: string;
    subtitle: string | undefined;
    children: ReactNode;
    style?: React.CSSProperties;
}

const BasicForm = ({ title, subtitle, children, style }: BasicFormProps) => (
    <div className={styles.basicForm} style={style}>
        {title ||
            (subtitle && (
                <section className={styles.header}>
                    {title && <h1 className={styles.title}>{title}</h1>}
                    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                </section>
            ))}
        <section className={styles.content}>{children}</section>
    </div>
);

export default BasicForm;

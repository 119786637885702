import cx from 'classnames';
// Gumdrops
import Button from 'gumdrops/Button';
import CardBlock from 'gumdrops/CardBlock';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingOverlay from '../../components/common/LoadingOverlay';
// Components
import ModalWrapper from '../../components/common/ModalWrapper';
import SearchMultiSelectAutoComplete from '../../components/inputs/SearchMultiSelectAutoComplete';
// Constants
import { GLOBAL_ADVERTISER_ID, PUBLISHERS, ZONES } from '../../constants/app';
// Helpers
import { getSelectedValues, mapDataMultiSelect } from '../../helpers/mapDataMultiSelect';
// Hooks
import { useCreateRestrictionsMutation } from './restrictionApiSlice';
import { useSearchGlobalAdvertisersMutation } from './searchGlobalAdvertisersApiSlice';
import { useAppSelector } from '../../store';

const BlockAdvertisersModal = ({ onClose, isLoadingAdvertisers, zoneId, blockedRestrictions }) => {
    const { t } = useTranslation();
    const publisherId = useAppSelector(state => state.publisher.id);
    const [globalAdvertisers, setGlobalAdvertisers] = useState([]);

    const [selectedAdvertiserIds, setSelectedAdvertiserIds] = useState([]);

    const [createRestrictions, { isLoading: isCreatingRestrictions }] =
        useCreateRestrictionsMutation();
    const [searchGlobalAdvertisers, { isLoading: isSearchingGlobalAdvertisers }] =
        useSearchGlobalAdvertisersMutation();

    const handleClose = (message = '') => onClose(message);

    const handleMultiSelect = list =>
        setSelectedAdvertiserIds(getSelectedValues(list, 'isSelected'));

    const handleSearch = async searchTerm => {
        const globalAdvertisers = await searchGlobalAdvertisers({
            partialName: searchTerm,
        }).unwrap();

        setGlobalAdvertisers(globalAdvertisers);
    };

    const handleSubmit = async () => {
        const payload = {
            entity: zoneId ? ZONES : PUBLISHERS,
            entityId: zoneId || publisherId,
            restrictionIds: selectedAdvertiserIds,
            restrictionType: GLOBAL_ADVERTISER_ID,
        };

        const areRestrictionsAlreadyExisting = selectedAdvertiserIds.every(id =>
            blockedRestrictions.some(restriction => restriction.id === id),
        );

        if (!areRestrictionsAlreadyExisting) {
            await createRestrictions(payload).unwrap();
        }

        setSelectedAdvertiserIds([]);
        handleClose(areRestrictionsAlreadyExisting ? t('blocking.advertisers.alreadyBlocked') : '');
    };

    const isSubmitDisabled =
        isSearchingGlobalAdvertisers ||
        isCreatingRestrictions ||
        selectedAdvertiserIds.length === 0;
    const options = mapDataMultiSelect(globalAdvertisers, {
        valueVar: 'id',
        nameVar: 'name',
        selectedVar: 'isSelected',
        useValueAsKey: true,
    });

    return (
        <ModalWrapper
            bodyStyles={{ minHeight: 300 }}
            containerClassName="-overflow-visible --modal-override"
            bodyClassName=" -p-h-4 -p-t-4"
            title={t('blocking.advertisers.add')}
            isOpen
            onClose={handleClose}
            hideFooter>
            <CardBlock className="gds-flex gds-flex--justify-end">
                <div className="gds-flex__item">
                    <SearchMultiSelectAutoComplete
                        autoFocus
                        options={options}
                        onSearch={handleSearch}
                        placeholder={t('blocking.advertisers.search')}
                        size="sm"
                        name="blocked-advertisers"
                        context="primary"
                        handleChange={handleMultiSelect}
                        isLoading={isLoadingAdvertisers}
                    />
                </div>
                <Button
                    disabled={isSubmitDisabled}
                    context="primary"
                    className={cx('gds-flex__item gds-flex__item--grow-0', {
                        '-disabled': isSubmitDisabled,
                    })}
                    size="sm"
                    onClick={handleSubmit}>
                    {t('common.add')}
                </Button>
                {isCreatingRestrictions && <LoadingOverlay showOverlay />}
            </CardBlock>
        </ModalWrapper>
    );
};

BlockAdvertisersModal.propTypes = {
    zoneId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    // redux props
    isLoadingAdvertisers: PropTypes.bool,
};

export default BlockAdvertisersModal;

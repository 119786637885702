// Components
import FormGroup from 'gumdrops/FormGroup';
import FormGroupLabel from 'gumdrops/FormGroupLabel';
import FormGroupTextHelp from 'gumdrops/FormGroupTextHelp';
import TextArea from 'gumdrops/TextArea';
import PropTypes from 'prop-types';

const ValidTextArea = ({ label, className, isInvalid, errors, errorStyles, ...inputProps }) => (
    <FormGroup className={className} context={isInvalid ? 'danger' : 'default'}>
        {label && <FormGroupLabel text={label} />}
        <div style={{ position: 'relative' }}>
            <TextArea {...inputProps} />
            {isInvalid && (
                <FormGroupTextHelp
                    className="-ellipsis"
                    text={errors.join(', ')}
                    style={errorStyles}
                />
            )}
        </div>
    </FormGroup>
);

ValidTextArea.propTypes = {
    className: PropTypes.string,
    errors: PropTypes.array,
    errorStyles: PropTypes.object,
    isInvalid: PropTypes.bool,
    label: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.node.isRequired,
};

ValidTextArea.defaultProps = {
    className: null,
    errors: [],
    isInvalid: false,
    label: null,
    errorStyles: {
        position: 'absolute',
        marginTop: '-2px',
        top: '100%',
        width: '100%',
        padding: '0.01rem 0.25rem',
    },
};

export default ValidTextArea;

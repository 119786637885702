import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import SectionHeader from './SectionHeader';
// @ts-ignore
import { Button } from 'gumdrops';

export interface CardTabProps {
    title: string;
    buttonType?: 'primary';
    buttonSize?: 'xs';
    buttonText: string;
    buttonPath?: string;
    isButtonDisabled: boolean;
    onClick?: () => void;
}

const CardTab = ({
    title,
    buttonPath,
    buttonText,
    isButtonDisabled,
    buttonType = 'primary',
    buttonSize = 'xs',
    onClick,
}: CardTabProps) => {
    const location = useLocation();

    const buttonElement = (
        <Button
            size={buttonSize}
            context={buttonType}
            disabled={isButtonDisabled}
            onClick={onClick}>
            {buttonText}
        </Button>
    );

    const headerButton = onClick ? (
        buttonElement
    ) : (
        <Link
            className={cx({ '-pointer-events--none': isButtonDisabled })}
            to={buttonPath ? buttonPath : ''}
            state={{ previousLocation: location }}>
            {buttonElement}
        </Link>
    );

    return <SectionHeader title={title} headerButton={headerButton} />;
};
export default CardTab;

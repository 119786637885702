import { useLocation } from 'react-router-dom';

const paramsToObject = (entries: URLSearchParams) => {
    const result: Partial<Record<string, string>> = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};

type DefaultQuery = {
    [key: string]: string;
};

const useQuery = (defaultQuery?: DefaultQuery) => {
    const query = new URLSearchParams(useLocation().search);

    const queryParams = paramsToObject(query);

    return { ...defaultQuery, ...queryParams };
};

export default useQuery;

import React from 'react';

import { ValidatorResult } from '../../../validators/types';
import ErrorMessage from './ErrorMessage';
import styles from './InputErrorGroup.module.scss';

interface ErrorMessageGroupProps {
    errors: ValidatorResult[];
}

const ErrorMessageGroup = ({ errors }: ErrorMessageGroupProps) => {
    const hasMore = errors.length > 1;

    return (
        <div className={`${styles.inputErrorGroup} ${hasMore ? styles.inputErrorGroupBorder : ''}`}>
            {errors &&
                errors.map((error, index) => (
                    <ErrorMessage key={index} isValid={error.isValid} message={error.message} />
                ))}
        </div>
    );
};

export default ErrorMessageGroup;

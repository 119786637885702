import cx from 'classnames';
import React from 'react';

export interface SingleSelectOption {
    id: number;
    name: string;
    title?: string;
}

interface SingleSelectItemProps {
    option: SingleSelectOption;
    onSelect: (value: SingleSelectOption) => void;
    isActive: boolean;
}

const SingleSelectItem = ({ option, onSelect, isActive }: SingleSelectItemProps) => {
    const isDisabled = option.id === -1;

    const handleSelect = (option: SingleSelectOption) => {
        if (option.id === -1) return;
        onSelect(option);
    };
    return (
        <li
            className={cx('gds-search-select__menu-item', {
                'gds-search-select__menu-item--selected': isActive,
                listItemDisabled: isDisabled,
            })}
            onClick={() => handleSelect(option)}>
            {option?.title ? option.title : option.name}
        </li>
    );
};

export default SingleSelectItem;

import cx from 'classnames';
// @ts-ignore
import { Badge, Button, Column } from 'gumdrops';
// Components
import { Link, useLocation } from 'react-router-dom';

interface RestrictionButtonProps {
    text: string;
    restrictionCount?: number;
    modalPath: string;
    isDisabled?: boolean;
}

const RestrictionButton = ({
    text,
    restrictionCount,
    modalPath,
    isDisabled = false,
}: RestrictionButtonProps) => {
    const location = useLocation();

    return (
        <Column xs="12" md="6" lg="4" xl="3">
            <Link
                to={modalPath}
                className={cx({ '-pointer-events--none': isDisabled })}
                state={{ previousLocation: location }}>
                <Button
                    // className="-m-b-3 gds-text--body-sm gds-flex gds-flex--justify-center gds-flex--wrap-no"
                    size="sm"
                    style={{ width: '100%' }}>
                    {text}
                    <Badge style={{ fontSize: 10 }} text={restrictionCount} context="danger" />
                </Button>
            </Link>
        </Column>
    );
};

export default RestrictionButton;

import Button from 'gumdrops/Button';

const LookerNotesLink = () => (
    <a
        href="https://gumgumsupport.zendesk.com/hc/en-us/categories/360003767711-FAQs"
        rel="noreferrer"
        target="_blank"
        data-cy="looker-notes-link">
        <Button
            className="gds-circular-button gds-circular-button--default gds-circular-button--tooltip -pos-fix"
            style={{
                background: '#fff',
                zIndex: 10000,
                top: 'auto',
                left: 'auto',
                bottom: '1rem',
                right: '1rem',
            }}>
            <i className="fas fa-fw fa-info" />
        </Button>
    </a>
);

export default LookerNotesLink;

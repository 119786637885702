// Block URLS
const URL_TYPES = {
    PAGE: 'PAGE',
    IMAGE: 'IMAGE',
};

const EVAL_TYPES = {
    CONTAINS: 'CONTAINS',
    REGEX: 'REGEX',
};

export const ZONE_BLOCK_URL_OPTIONS = {
    IMAGE_URL: 'IMAGE_URL',
    PAGE_URL: 'PAGE_URL',
};

export const ZONE_BLOCK_URL_OPTIONS_MAP = {
    IMAGE_URL: {
        urlType: URL_TYPES.IMAGE,
        evalType: EVAL_TYPES.CONTAINS,
    },
    PAGE_URL: {
        urlType: URL_TYPES.PAGE,
        evalType: EVAL_TYPES.CONTAINS,
    },
};

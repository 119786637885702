export const STAGE_HOSTNAME = 'publisher-center.stage.web-engineering.k8s.ggops.com';
// @info: temporary hostname for testing
export const PRODUCTION_HOSTNAME = 'app.gumgum.com';

// Filters/Pagination
export const FILTER_DIRECTION_ASC = 'ASC';
export const FILTER_DIRECTION_DESC = 'DESC';

export const BUSINESS_UNIT = Object.freeze({
    US: 1,
    UK: 2,
    AUS: 3,
    EU: 4,
    JAP: 5,
    CAN: 6,
});

export const DATA_REQUEST_SIZE_OPTIONS = [
    {
        name: '10',
        value: '10',
    },
    {
        name: '20',
        value: '20',
    },
    {
        name: '50',
        value: '50',
    },
    {
        name: '100',
        value: '100',
    },
];

export const PUBLISHER_DIRECTNESS_DIRECT = 'direct';
export const PUBLISHER_DIRECTNESS_RESELLER = 'reseller';
export const PUBLISHER_DIRECTNESS_BOTH = 'both';
export const PUBLISHER_DIRECTNESS_OPTIONS = [
    { name: 'Publisher', value: PUBLISHER_DIRECTNESS_DIRECT },
    { name: 'Intermediary', value: PUBLISHER_DIRECTNESS_RESELLER },
    { name: 'Both', value: PUBLISHER_DIRECTNESS_BOTH },
];

export const PUBLISHER = 'publisher';
export const PUBLISHERS = 'publishers';
export const ZONE = 'zone';
export const ZONES = 'zones';
export const ENTITY_TITLES = {
    [PUBLISHER]: 'Publisher',
    [ZONE]: 'Zone',
};

export const ZONE_TYPES = [
    { id: 1, key: 'website', name: 'Website' },
    { id: 3, key: 'app', name: 'App' },
];

export const AVAILABLE_SERVICES = [
    { id: '5', key: 'inImage' },
    { id: '12', key: 'inScreen' },
    { id: '14', key: 'inContent' },
    { id: '13', key: 'inSlot' },
    { id: '15', key: 'inVideo' },
    { id: '16', key: 'video' },
];

export const APP_SERVICE_IDS = [13, 16];

// Restrictions
export const IAB_CATEGORY_ID_V1 = 'IAB_CATEGORY_ID_V1';
export const RESTRICTION_ID_KEY = 'advertisingRestrictionId';
export const ADVERTISER_DOMAIN = 'advertiser_domain';
export const GLOBAL_KEY = 'Global';
export const GLOBAL_ADVERTISER_ID = 'global_advertiser_id';
export const DEFAULT_IMPLEMENTATION_TYPE_ID = 1;

// 6 In-Video, 7	Video
export const VIDEO_PRODUCT_TYPE_IDS = Object.freeze([6, 7]);

export const JUST_PREMIUM_BUSINESS_UNITS = [7, 8];

// Zone Slots
export const SLOT_SIZE_300_250 = 4;
export const SLOT_FLEX_SIZE_ID = 12;
export const SLOT_FLEX_SIZE_NAME = 'Flex size';
export const IMPLEMENTATION_TYPE_HEADER_BIDDING = 2;
export const EMPTY_SLOT = {
    name: '',
    sizeId: SLOT_SIZE_300_250,
    implementationTypeId: IMPLEMENTATION_TYPE_HEADER_BIDDING,
    placementId: '',
};

// Publisher Manager
export const PUBLISHER_MANAGER_STAGE_HOSTNAME =
    'https://publisher-manager.stage.web-engineering.k8s.ggops.com';
export const PUBLISHER_MANAGER_PRODUCTION_HOSTNAME = 'https://publisher-manager.gumgum.com';

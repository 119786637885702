import React from 'react';

import AuthorizationLayout from '../../../components/layouts/Authorization/AuthorizationLayout';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => (
    <AuthorizationLayout>
        <ResetPasswordForm />
    </AuthorizationLayout>
);

export default ResetPasswordPage;

import PropTypes from 'prop-types';
import { ReactNode } from 'react';

interface SectionHeaderProps {
    title: string;
    headerButton?: ReactNode;
}
const SectionHeader = ({ title, headerButton }: SectionHeaderProps) => (
    <>
        <div className="gds-flex gds-flex--justify-between -p-h-2">
            <h3 className="gds-text--header-xs">{title}</h3>
            {headerButton}
        </div>
        <hr className="-color-bd-lt-2" />
    </>
);

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    headerButton: PropTypes.node,
};

SectionHeader.defaultProps = {
    headerButton: null,
};

export default SectionHeader;

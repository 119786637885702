import cx from 'classnames';
// Components
// @ts-ignore
import { Button, ModalBody, ModalFooter, ModalForm, ModalHeader } from 'gumdrops';

import LoadingOverlay from './LoadingOverlay';
import Modal from './Modal';
import React, { ReactNode } from 'react';

interface ModalWrapperProps {
    children: ReactNode;
    title: string;
    isOpen: boolean;
    shouldCloseOnOverlayClick?: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    submitIsDisabled?: boolean;
    submitButtonContext?: string;
    submitText?: string;
    size?: string;
    containerClassName: string;
    formClassName?: string;
    headerClassName?: string;
    bodyClassName: string;
    bodyStyles?: React.CSSProperties;
    hideFooter?: boolean;
    overlayFooter?: boolean;
    md?: string;
    footerChildren?: ReactNode;
}
const ModalWrapper = ({
    children,
    title,
    isOpen,
    shouldCloseOnOverlayClick = false, // by default click outside is disabled
    submitIsDisabled = false,
    submitButtonContext = 'primary',
    submitText = 'Submit',
    size = 'xs',
    containerClassName = '-overflow-visible --modal-override --modal-bulk-override',
    formClassName = '-overflow-visible',
    headerClassName = '-color-bg-pri-dk-4 -color-bd-sec -color-tx-white',
    bodyClassName = '-p-h-3-sm -p-h-6-md -p-h-6-lg -p-h-6-xl',
    bodyStyles = { minHeight: '100px', overflowY: 'auto' },
    hideFooter = false,
    onClose,
    onSubmit,
    overlayFooter = false,
    footerChildren = null,
    md = '12',
}: ModalWrapperProps) => (
    <Modal
        onClose={onClose}
        isOpen={isOpen}
        md={md}
        className={containerClassName}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
        <ModalForm className={formClassName}>
            <ModalHeader size="sm" title={title} onClose={onClose} className={headerClassName} />
            <ModalBody className={bodyClassName} style={bodyStyles}>
                {children}
            </ModalBody>
            {!hideFooter && (
                <ModalFooter className="gds-flex -p-v-2 -color-bg-white">
                    <LoadingOverlay showOverlay={overlayFooter} showDots={false} />
                    {!!footerChildren && footerChildren}
                    <div className="gds-flex__item gds-flex--justify-end">
                        <Button context="outline" size={size} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            name="submit"
                            context={submitButtonContext}
                            size={size}
                            className={cx('-m-l-2', {
                                '-disabled': submitIsDisabled,
                            })}
                            onClick={onSubmit}
                            disabled={submitIsDisabled}>
                            {submitText}
                        </Button>
                    </div>
                </ModalFooter>
            )}
        </ModalForm>
    </Modal>
);

export default ModalWrapper;

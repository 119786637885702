// @ts-ignore
import { Button, ButtonGroup, Tooltip } from 'gumdrops';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulkActions from './BulkActions';
import { useToggle } from 'usehooks-ts';

const spanStyles = { borderColor: '#0085a5' };

interface BulkSelectorProps {
    initialSelectedEntries: string[];
    children: any;
    getActionsList?: any;
    renderCustomActions?: any;
    renderHeaderChildren?: any;
    showSelectAll: boolean;
    zoneId?: string;
    toggle?: any;
    entries: any[];
    customId?: string;
    isEntriesPaginated: boolean;
    isEntriesDynamic?: boolean;
    handleAfterChange?: any;
}

const BulkSelector = ({
    children,
    getActionsList,
    renderCustomActions,
    renderHeaderChildren,
    zoneId,
    toggle,
    handleAfterChange,
    entries = [],
    initialSelectedEntries = [],
    customId = 'id',
    isEntriesPaginated = true,
    isEntriesDynamic = false,
    showSelectAll = true,
}: BulkSelectorProps) => {
    const { t } = useTranslation();
    const [selectedEntries, setSelectedEntries] = useState<string[]>(initialSelectedEntries);
    const [selectedFilteredEntries, setSelectedFilteredEntries] = useState<string[]>([]);
    const [isAllSelected, , setIsAllSelected] = useToggle(false);

    useEffect(() => {
        const allSelected =
            selectedFilteredEntries.length === selectedEntries.length &&
            (selectedFilteredEntries.length > 0 || selectedEntries.length > 0);
        setIsAllSelected(allSelected);
    }, [entries, selectedEntries]);

    // Sets deselect state when selects are made
    useEffect(() => {
        handleAfterChange && handleAfterChange({ entries, selectedEntries });
    }, [handleAfterChange, entries, selectedEntries]);

    const handleSelectAll = () => {
        const filteredEntries = zoneId
            ? // eslint-disable-next-line no-prototype-builtins
              entries.filter(entry => !entry.hasOwnProperty('tag'))
            : entries;
        const filteredEntriesIds = filteredEntries.map(item => item[customId].toString());
        let selectedEntriesCopy = selectedEntries.slice();

        setSelectedFilteredEntries(filteredEntries);

        if (isAllSelected) {
            // Remove all the current entries from the selected list
            if (isEntriesPaginated || isEntriesDynamic) {
                selectedEntriesCopy = selectedEntriesCopy.filter(
                    item => !filteredEntriesIds.includes(item),
                );
            } else {
                // If list of entries don't filter (change) and are not paginated, we can hard remove all the selected entries
                selectedEntriesCopy = [];
            }

            setSelectedEntries(selectedEntriesCopy);
        } else {
            // Add all the current entries to the selected list
            if (isEntriesPaginated || isEntriesDynamic) {
                filteredEntriesIds.forEach(item => {
                    if (!selectedEntries.includes(item)) {
                        selectedEntriesCopy.push(item);
                    }
                });
            } else {
                // If list of entries don't filter (change) and are not paginated, we can hard set all entries as selected
                selectedEntriesCopy = selectedEntriesCopy.concat(filteredEntriesIds);
            }

            setSelectedEntries(selectedEntriesCopy);
        }
    };

    const handleClearSelected = () => {
        setSelectedEntries([]);
        handleAfterChange && handleAfterChange({ entries, selectedEntries: [] });
    };

    const handleChangeEntry = ({ target: { value: id } }: { target: { value: string } }) => {
        let selectedEntriesCopy = selectedEntries.slice();

        if (!selectedEntries.includes(id)) {
            selectedEntriesCopy.push(id);
            entries.find(item => item[customId] == id);
        } else {
            selectedEntriesCopy = selectedEntries.filter(item => item !== id);
        }

        setSelectedEntries(selectedEntriesCopy);
    };

    const actionsList = getActionsList
        ? getActionsList({
              selectedEntries,
              handleClearSelected,
          })
        : [];

    const selectAllTooltipText = t(isAllSelected ? 'common.deselectAll' : 'common.selectAll');

    return (
        <>
            {showSelectAll && (
                <div className="gds-flex gds-flex--align-center -m-b-1">
                    <div className="gds-flex__item gds-flex__item--grow-0 gds-flex">
                        <ButtonGroup className="-m-r-3" size="xs">
                            <Button
                                className="-pointer-events--none"
                                context="outline"
                                group
                                style={{ width: '50px' }}>
                                <Tooltip text={selectAllTooltipText} position="top">
                                    <div className="gds-form-group -pointer-events--auto">
                                        <div className="gds-form-group__checkbox gds-form-group__checkbox--xs">
                                            <label className="gds-form-group__checkbox-label -m-l-2">
                                                <input
                                                    type="checkbox"
                                                    className="gds-form-group__checkbox-input"
                                                    checked={isAllSelected}
                                                    onChange={handleSelectAll}
                                                    data-cy="checkbox-select-all"
                                                />
                                                <span
                                                    className="gds-form-group__checkbox-indicator"
                                                    style={!isAllSelected ? spanStyles : {}}
                                                />
                                                {!isAllSelected && (
                                                    <i
                                                        className="fa fa-minus -abs-t-r"
                                                        style={{
                                                            right: '17px',
                                                            color: '#0085a5',
                                                            textShadow: 'none',
                                                        }}
                                                    />
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </Tooltip>
                            </Button>
                            {/*Selected text*/}
                            <Button
                                group
                                className="-color-bg-white -pointer-events--none"
                                style={{ minWidth: '110px' }}
                                context="default">
                                {selectedEntries.length} {t('common.selected')}
                            </Button>
                            {/*Action list*/}
                            {!!actionsList.length && (
                                <BulkActions
                                    selectedEntriesCount={selectedEntries.length}
                                    actionsList={actionsList}
                                />
                            )}
                            {/*Remove*/}
                            {renderCustomActions &&
                                renderCustomActions({
                                    selectedEntries,
                                    handleClearSelected,
                                })}
                        </ButtonGroup>
                    </div>
                    {/*Search*/}
                    <div className="gds-form-group gds-flex__item">
                        {!!renderHeaderChildren && renderHeaderChildren()}
                    </div>
                    {/*Hide Global Blocks*/}
                    {zoneId && toggle}
                </div>
            )}
            {/*List*/}
            {children({
                selectedEntries,
                handleChangeEntry,
                handleClearSelected,
                setSelectedEntries,
                handleSelectAll,
            })}
        </>
    );
};

export default BulkSelector;

import React from 'react';

import AuthorizationLayout from '../../../components/layouts/Authorization/AuthorizationLayout';
import LoginForm from './LoginForm';

const LoginPage = () => (
    <AuthorizationLayout>
        <LoginForm />
    </AuthorizationLayout>
);

export default LoginPage;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { FormGroup, FormGroupLabel, TextInput } from 'gumdrops';
import React, { ChangeEvent, HTMLAttributes } from 'react';

import { InputState } from '../../validators/useInputField';
import ErrorMessageGroup from '../common/Input/ErrorMessageGroup';
import styles from './FormGroupInput.module.scss';

interface FormGroupInputProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
    name: string;
    type: 'text' | 'password';
    value: string | null;
    inputState: InputState;
    onChange: (value: string) => void;
    label?: string;
    size?: string;
    defaultLabelStyles?: boolean;
    placeholder?: string;
}

const FormGroupInput = ({
    name,
    type = 'text',
    value,
    onChange,
    inputState,
    label,
    defaultLabelStyles,
    ...others
}: FormGroupInputProps) => (
    <FormGroup context={!inputState.touched || inputState.valid ? 'default' : 'danger'}>
        {label && (
            <FormGroupLabel text={label} className={defaultLabelStyles ? '' : styles.label} />
        )}
        <TextInput
            className={styles.textInput}
            name={name}
            type={type}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value)}
            value={value}
            {...others}
        />
        <ErrorMessageGroup errors={inputState.errors} />
    </FormGroup>
);

export default FormGroupInput;

import React from 'react';

import SingleSelectItem, { SingleSelectOption } from './SingleSelectItem';

export type Options = SingleSelectOption[];

interface SingleSelectListProps {
    options: Options;
    onSelect: (value: SingleSelectOption) => void;
    value: SingleSelectOption | null;
}

const emptyResult = { id: -1, name: 'No result found' } as SingleSelectOption;

const SingleSelectList = ({ options, onSelect, value }: SingleSelectListProps) => {
    const optionsList = options?.length ? options : [emptyResult];
    return (
        <div
            className="gds-search-select__menu"
            data-cy="browse-list"
            style={{
                overflowX: 'hidden',
            }}>
            <ul className="gds-search-select__menu-items">
                {optionsList.map(option => (
                    <SingleSelectItem
                        key={option.id}
                        option={option}
                        onSelect={onSelect}
                        isActive={value?.id === option.id}
                    />
                ))}
            </ul>
        </div>
    );
};

export default SingleSelectList;

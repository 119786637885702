import { isAfter, parseISO } from 'date-fns';

export const parseDate = date => (typeof date === 'string' ? parseISO(date) : date);

/**
 * Parses date values and returns date-fns isAfter method to compare
 * @param {string|date} value
 * @param {string|date} valueToCompare
 * @returns {function}
 */
export function isDateAfter(value, valueToCompare) {
    const date = parseDate(value);
    const dateToCompare = parseDate(valueToCompare);
    return isAfter(date, dateToCompare);
}

import SearchMultiSelect from 'gumdrops/SearchMultiSelect';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { getSelectedValues } from '../../helpers/mapDataMultiSelect';
import InputSpinner from '../common/InputSpinner';

class SearchMultiSelectAutoComplete extends Component {
    static propTypes = {
        className: PropTypes.string,
        size: PropTypes.oneOf(['xs', 'sm', 'md']),
        context: PropTypes.oneOf(['primary', 'secondary', 'danger']),
        placeholder: PropTypes.string,
        // NOTE: options need to have their 'key' to be set as something unique other than index
        // Otherwise it will cause a duplicate key warning when searching for new values
        options: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                value: PropTypes.node.isRequired,
                key: PropTypes.node.isRequired,
                selected: PropTypes.bool,
            }),
        ),
        onSearch: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: 'Search',
        options: [],
    };

    state = {
        // Keeps a copy of options from props for comparison
        _options: [],
        preparedOptions: [],
        selectedOptions: [],
    };
    _callDebouncedSearch = debounce(term => {
        this.props.onSearch(term.trim());
    }, 300);

    static getDerivedStateFromProps({ options: nextOptions }, { _options, selectedOptions }) {
        if (JSON.stringify(nextOptions) !== JSON.stringify(_options)) {
            // Get the previous selected items and append them to options
            const selectedOptionsValues = getSelectedValues(selectedOptions, 'isSelected', 'value');
            // Eliminate selected items from list to prevent having duplicates
            let preparedOptions = nextOptions.filter(
                item => !selectedOptionsValues.includes(item.value),
            );
            // Selected options will always show on top of list
            preparedOptions.unshift(...selectedOptions);

            return {
                _options: nextOptions,
                preparedOptions,
            };
        }
        return null;
    }

    _handleChange = options => {
        this.setState(
            { selectedOptions: options.filter(item => item.isSelected), preparedOptions: options },
            () => this.props.handleChange(options),
        );
    };

    _onSearchChange = searchTerm => {
        if (searchTerm.length < 2) {
            return false;
        }

        this._callDebouncedSearch(searchTerm);
    };

    render() {
        const { size, className, context, placeholder, isLoading } = this.props;
        const { preparedOptions } = this.state;

        return (
            <Fragment>
                <SearchMultiSelect
                    size={size}
                    context={context}
                    className={className}
                    placeholder={placeholder}
                    options={preparedOptions}
                    update={this._handleChange}
                    onChange={this._onSearchChange}
                />
                {isLoading && (
                    <InputSpinner
                        size="xs"
                        style={{
                            right: '40px',
                            top: '0.75rem',
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default SearchMultiSelectAutoComplete;

import React from 'react';

interface IconProps {
    icon: string;
    prefix?: 'fa' | 'fas' | 'far' | 'fal' | 'fab';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    color?:
        | 'white'
        | 'red'
        | 'blue'
        | 'green'
        | 'purple'
        | 'pri'
        | 'sec'
        | 'ter'
        | 'suc'
        | 'dan'
        | 'war'
        | 'inf';
}

const Icon = ({ icon = 'plus', prefix = 'fas', size = 'md', color = 'white' }: IconProps) => {
    const faSize = size ? `fa-${size}` : '';
    return <i className={`${prefix} fa-${icon} ${faSize} -color-tx-${color}`} />;
};

export default Icon;

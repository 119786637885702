import { baseApi } from '../../../api/api';

export const adsTxtApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAdsTxt: builder.query({
            query: ({ publisherId, businessUnitIds }) => ({
                url: '/external/ads-txt',
                params: {
                    publisherId,
                    businessUnitIds,
                },
            }),
        }),
    }),
});

export const { useGetAdsTxtQuery } = adsTxtApiSlice;

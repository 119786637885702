import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { useAppSelector } from '../../store';
import { useGetZoneQuery } from '../zone/zoneApiSlice';

import BlockedIABCategoriesList from './BlockedIABCategoriesList';
import CreateIABRestrictions from './CreateIABRestrictions';
import UpdateIABRestrictions from './UpdateIABRestrictions';
import useIABCategories from './useIABCategories';
import Container from '../../components/common/Container';
import CardTab, { CardTabProps } from '../../components/common/CardTab';
import { sendNotification } from '../../components/notifications/notificationsReducer';

import { isEmptyCustom } from '../../helpers/utils';
import { isInternalUser } from '../../helpers/authorization';

import {
    PRODUCTION_HOSTNAME,
    PUBLISHER_MANAGER_PRODUCTION_HOSTNAME,
    PUBLISHER_MANAGER_STAGE_HOSTNAME,
} from '../../constants/app';
import { EXCLUDE } from '../../constants/restrictions';

const BlockedIABCategories = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id: publisherId } = useAppSelector(state => state.publisher);
    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);

    const {
        iabCategories,
        blockedIABCategories,
        isLoading,
        blockedIABParentCategories,
        clusivity,
        zoneId,
    } = useIABCategories();

    const isGlobalAdvertiserView = isEmptyCustom(zoneId);

    const isDisabled =
        isLoading ||
        (!isEmptyCustom(blockedIABCategories) &&
            iabCategories.length == blockedIABParentCategories.length);

    // Notification
    const sendNotificationParams = {
        text: t('blocking.advertisers.contactYourAccountManager'),
        context: 'danger',
        isInnerHtml: false,
    };
    if (isInternal) {
        const hostName =
            window.location.hostname === PRODUCTION_HOSTNAME
                ? PUBLISHER_MANAGER_PRODUCTION_HOSTNAME
                : PUBLISHER_MANAGER_STAGE_HOSTNAME;

        let publisherManagerLink = `${hostName}/publishers/${publisherId}/settings`;
        if (!isGlobalAdvertiserView && zoneId) {
            const { data: zone } = useGetZoneQuery(zoneId);
            const trackingId = zone?.trackingId;
            publisherManagerLink = `${hostName}/publishers/${publisherId}/zones/details/${trackingId}/settings`;
        }

        sendNotificationParams.text = t('blocking.advertisers.contactYourAccountManagerInternal', {
            publisherManagerLink,
        });
        sendNotificationParams.isInnerHtml = true;
    }

    const contactManagerNotification = () => {
        dispatch(sendNotification(sendNotificationParams));
    };

    const isAddCategoriesAllowed = !clusivity || clusivity === EXCLUDE;

    const cardTabProps: CardTabProps = {
        title: t('blocking.iabCategories.blocked'),
        buttonText: t('blocking.iabCategories.add'),
        buttonPath: 'iab/create',
        isButtonDisabled: isDisabled,
        onClick: !isAddCategoriesAllowed ? contactManagerNotification : undefined,
    };

    return (
        <Container>
            {/* ROUTES */}
            {/* IAB restriction routes */}
            <Routes>
                <Route
                    path={'iab/create/*'}
                    element={
                        <CreateIABRestrictions
                            iabCategories={iabCategories.filter(
                                category => !blockedIABParentCategories.includes(category.id),
                            )}
                        />
                    }
                />
            </Routes>
            <Routes>
                <Route
                    path={'iab/:categoryId/edit/*'}
                    element={
                        <UpdateIABRestrictions
                            iabCategories={iabCategories}
                            blockedCategories={blockedIABCategories}
                        />
                    }
                />
            </Routes>
            {/* ADD Categories button & card tab */}
            <CardTab {...cardTabProps} />
            {/* List categories as list of buttons */}
            {/* Show only EXCLUDE restrictions or no restrictions */}
            {isAddCategoriesAllowed && (
                <BlockedIABCategoriesList
                    iabCategories={iabCategories}
                    isLoading={isLoading}
                    blockedIABParentCategories={blockedIABParentCategories}
                    blockedIABCategories={blockedIABCategories}
                />
            )}
        </Container>
    );
};

export default BlockedIABCategories;

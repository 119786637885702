import Button from 'gumdrops/Button';
// Components
import Card from 'gumdrops/Card';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../../components/common/SectionHeader';
import TablePaginationFooter from '../../components/common/TablePaginationFooter';
// Hooks
import { useGetZoneBlockedUrlsQuery } from './zoneApiSlice';
import ZoneBlockUrlModal from './ZoneBlockUrlModal';
import ZoneBlockUrlsList from './ZoneBlockUrlsList';

import { useDispatch } from 'react-redux';
import { sendNotification } from '../../components/notifications/notificationsReducer';

const ZoneBlockUrls = ({ zoneId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: blockedUrls = [], isLoading } = useGetZoneBlockedUrlsQuery(zoneId);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    const handleClose = message => {
        closeModal();
        typeof message === 'string' &&
            dispatch(sendNotification({ text: message, context: 'danger', msToClose: 3000 }));
    };

    return (
        <div className="-m-b-4" data-cy="zone-block-urls">
            <SectionHeader
                title={t('zone.overview.blockedUrls')}
                headerButton={
                    <Button size="xs" context="primary" onClick={openModal}>
                        {t('common.add')}
                    </Button>
                }
            />
            <div className="-p-a-2" data-cy="zone-block-urls-list">
                <Card className="-overflow-hidden">
                    <ZoneBlockUrlsList
                        zoneId={zoneId}
                        blockedUrls={blockedUrls}
                        isLoading={isLoading}
                    />
                    <TablePaginationFooter
                        totalElements={blockedUrls.length}
                        totalPages={blockedUrls.length && 1} // show footer
                    />
                </Card>
            </div>
            {isModalOpen && (
                <ZoneBlockUrlModal
                    zoneId={zoneId}
                    onClose={message => handleClose(message)}
                    blockedUrls={blockedUrls}
                />
            )}
        </div>
    );
};

ZoneBlockUrls.propTypes = {
    zoneId: PropTypes.string.isRequired,
};

ZoneBlockUrls.displayName = 'ZoneBlockUrls';

export default ZoneBlockUrls;

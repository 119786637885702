import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = ({ navData: { name, title, path, children, subPaths } }) => {
    const { pathname: currentPath } = useLocation();
    const crumb = children || subPaths;
    const breadcrumb = crumb?.find(({ path }) => currentPath.includes(path));

    return (
        <>
            <li className="gds-breadcrumb__item">
                <Link className="gds-breadcrumb__link" to={path + (children?.[0].path ?? '')}>
                    {name ?? title}
                </Link>
            </li>
            {breadcrumb && (
                <li className="gds-breadcrumb__item">
                    <div className="gds-breadcrumb__link" to={path + breadcrumb.path}>
                        {breadcrumb.title}
                    </div>
                </li>
            )}
        </>
    );
};

Breadcrumbs.propTypes = {
    navData: PropTypes.object.isRequired,
};

export default Breadcrumbs;

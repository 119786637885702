// @ts-ignore
import { AccordionItem, AccordionItemContent } from 'gumdrops';
import { IABParentCategory } from '../../../../types/IABCategories';
import { useEffect, useState } from 'react';
import LabelCheckBox from './LabelCheckBox';
import SubCategory from './SubCategory';

interface CategoryProps {
    selectedCategories: string[];
    iabCategory: IABParentCategory;
    onChange: (categoryId: string) => void;
}
const Category = ({ iabCategory, onChange, selectedCategories }: CategoryProps) => {
    const [checked, setChecked] = useState(selectedCategories.includes(iabCategory.id));

    useEffect(() => {
        setChecked(selectedCategories.includes(iabCategory.id));
    }, [selectedCategories]);

    const labelName = `${iabCategory.label} (${
        checked
            ? iabCategory.subCategories.length
            : selectedCategories.filter(item => item.includes(iabCategory.id + '-')).length
    })`;

    return (
        <AccordionItem
            size={'sm'}
            label={
                <LabelCheckBox
                    categoryId={iabCategory.id}
                    checked={checked}
                    onChange={() => onChange(iabCategory.id)}
                    label={labelName}
                    name={iabCategory.name}
                />
            }
            isOpen={false}
            context={'white'}>
            <AccordionItemContent context={'white'} style={{ background: 'white' }}>
                {iabCategory.subCategories.map((subCategory, index) => (
                    <SubCategory
                        selectedCategories={selectedCategories}
                        iabSubCategory={subCategory}
                        onChange={onChange}
                        key={index}
                    />
                ))}
            </AccordionItemContent>
        </AccordionItem>
    );
};

export default Category;

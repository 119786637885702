// @ts-nocheck
// @todo: Fix the type errors and refactor the code.

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import cx from 'classnames';

import { useAppSelector } from '../../store';
import { useGetZoneQuery } from '../zone/zoneApiSlice';
import { useDeleteRestrictionsMutation, useGetRestrictionsQuery } from './restrictionApiSlice';

// @ts-ignore
import { CardBlock } from 'gumdrops';

import BlockAdvertisersList from './BlockAdvertisersList';
import BlockAdvertiserDomainModal from './BlockAdvertiserDomainModal';
import Container from '../../components/common/Container';
import Alert from '../../components/common/Alert';
import IconButton from '../../components/common/IconButton';
import CardTab, { CardTabProps } from '../../components/common/CardTab';
import SearchSelectableListWrapper from '../../components/common/SearchSelectableListWrapper';
import { sendNotification } from '../../components/notifications/notificationsReducer';

import { isEmptyCustom } from '../../helpers/utils';
import { isInternalUser } from '../../helpers/authorization';
import { createLoadingSelector } from '../../helpers/createLoadingSelector';

import {
    ADVERTISER_DOMAIN,
    PRODUCTION_HOSTNAME,
    PUBLISHER_MANAGER_PRODUCTION_HOSTNAME,
    PUBLISHER_MANAGER_STAGE_HOSTNAME,
    PUBLISHERS,
    RESTRICTION_ID_KEY,
    ZONES,
} from '../../constants/app';
import { EXCLUDE } from '../../constants/restrictions';

const restrictionType = ADVERTISER_DOMAIN;

interface QueryResult {
    data: {
        restrictions: [];
        clusivity: string;
    };
    isLoading: boolean;
}

interface BlockAdvertiserDomainsProps {
    zoneId?: string;
    basePath: string;
}

const BlockAdvertiserDomains = ({ zoneId, basePath }: BlockAdvertiserDomainsProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id: publisherId } = useAppSelector(state => state.publisher);
    const isLoadingBlockedAdvertiserDomains = useAppSelector(({ loading }) =>
        createLoadingSelector([
            'GET_BLOCKED_ADVERTISER_DOMAINS',
            'GET_PUBLISHER_BLOCKED_ADVERTISER_DOMAINS',
        ])(loading),
    );

    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);
    const [showAlert, setShowAlert] = useState(false);
    const [
        isPublisherBlockedAdvertiserDomainsFiltered,
        setIsPublisherBlockedAdvertiserDomainsFiltered,
    ] = useState(false);

    const isGlobalAdvertiserView = isEmptyCustom(zoneId);
    let isAddAdvertiserAllowed;

    // Publishers (1)
    const {
        data: publisherBlockedDomains = { restrictions: [], clusivity: '' },
        isLoading: isLoadingPublisherBlockedAdvertisers,
    } = useGetRestrictionsQuery<QueryResult>({
        entity: PUBLISHERS,
        entityId: publisherId,
        restrictionType,
    });

    const [deleteRestrictions, { isLoading: isDeletingRestrictions }] =
        useDeleteRestrictionsMutation();

    // Publishers (1)
    let blockedRestrictions;
    if (isGlobalAdvertiserView) {
        blockedRestrictions = publisherBlockedDomains.restrictions;

        isAddAdvertiserAllowed =
            !publisherBlockedDomains.clusivity || publisherBlockedDomains.clusivity === EXCLUDE;
    }
    // Publishers + Zones (1 + 2)
    else {
        const { data: zoneBlockedDomains = { restrictions: [], clusivity: '' } } =
            useGetRestrictionsQuery<QueryResult>(
                {
                    entity: ZONES,
                    entityId: zoneId,
                    restrictionType,
                },
                { skip: !zoneId },
            );

        isAddAdvertiserAllowed =
            !zoneBlockedDomains.clusivity || zoneBlockedDomains.clusivity === EXCLUDE;

        blockedRestrictions = isPublisherBlockedAdvertiserDomainsFiltered
            ? zoneBlockedDomains.restrictions
            : [...publisherBlockedDomains.restrictions, ...zoneBlockedDomains.restrictions];
    }

    // Alert
    const toggleAlert = () => setShowAlert(!showAlert);
    const onAcceptAlert =
        ({
            selectedEntries,
            handleClearSelected,
        }: {
            selectedEntries: string;
            handleClearSelected: () => void;
        }) =>
        async () => {
            const payload = {
                entity: zoneId ? ZONES : PUBLISHERS,
                entityId: zoneId || publisherId,
                restrictionIds: selectedEntries,
                restrictionType,
            };

            await deleteRestrictions(payload);
            toggleAlert();
            handleClearSelected();
        };

    // Global toggle
    const togglePublisherBlockedAdvertiserDomains = () => {
        setIsPublisherBlockedAdvertiserDomainsFiltered(
            !isPublisherBlockedAdvertiserDomainsFiltered,
        );
    };

    const globalToggle = (
        <div className="gds-form-group -m-l-3 gds-flex__item gds-flex__item--grow-0">
            <div className="gds-form-group__toggleswitch gds-form-group__toggleswitch--xs">
                <label className="gds-form-group__toggleswitch-label">
                    <input
                        type="checkbox"
                        className="gds-form-group__toggleswitch-input"
                        value={isPublisherBlockedAdvertiserDomainsFiltered.toString()}
                        onChange={togglePublisherBlockedAdvertiserDomains}
                    />
                    <span className="gds-form-group__toggleswitch-indicator" />
                    Hide Global Blocks
                </label>
            </div>
        </div>
    );

    // Notification
    const sendNotificationParams = {
        text: t('blocking.advertisers.contactYourAccountManager'),
        context: 'danger',
        isInnerHtml: false,
    };
    if (isInternal) {
        const hostName =
            window.location.hostname === PRODUCTION_HOSTNAME
                ? PUBLISHER_MANAGER_PRODUCTION_HOSTNAME
                : PUBLISHER_MANAGER_STAGE_HOSTNAME;

        let publisherManagerLink = `${hostName}/publishers/${publisherId}/settings`;
        if (!isGlobalAdvertiserView) {
            const { data: zone } = useGetZoneQuery(zoneId);
            const trackingId = zone?.trackingId;
            publisherManagerLink = `${hostName}/publishers/${publisherId}/zones/details/${trackingId}/settings`;
        }

        sendNotificationParams.text = t('blocking.advertisers.contactYourAccountManagerInternal', {
            publisherManagerLink,
        });
        sendNotificationParams.isInnerHtml = true;
    }

    const contactManagerNotification = () => {
        dispatch(sendNotification(sendNotificationParams));
    };

    // Card tab
    const cardTabProps: CardTabProps = {
        title: t('blocking.domains.blocked'),
        buttonText: t('blocking.domains.add'),
        buttonPath: `${basePath}/block-advertiser-domains/create`,
        isButtonDisabled: isLoadingPublisherBlockedAdvertisers,
        onClick: !isAddAdvertiserAllowed ? contactManagerNotification : undefined,
    };

    const handleClose = message => {
        typeof message === 'string' &&
            dispatch(sendNotification({ text: message, context: 'danger', msToClose: 3000 }));
        navigate(basePath);
    };

    return (
        <Container>
            <div data-cy="block-advertiser-domains">
                <Routes>
                    <Route
                        path="block-advertiser-domains/create"
                        element={
                            <BlockAdvertiserDomainModal
                                zoneId={zoneId}
                                onClose={message => handleClose(message)}
                                blockedRestrictions={blockedRestrictions}
                            />
                        }
                    />
                </Routes>
                <CardTab {...cardTabProps} />
                <CardBlock>
                    <SearchSelectableListWrapper
                        inputPlaceholder={t('common.search')}
                        entries={blockedRestrictions}
                        customId={RESTRICTION_ID_KEY}
                        zoneId={zoneId}
                        renderCustomActions={({ selectedEntries }: { selectedEntries: string }) => {
                            const isDeleteButtonDisabled =
                                selectedEntries.length === 0 ||
                                isDeletingRestrictions ||
                                isLoadingBlockedAdvertiserDomains;
                            return (
                                <IconButton
                                    onClick={toggleAlert}
                                    size="xs"
                                    icon="trash-alt"
                                    context="danger"
                                    className={cx('-m-l-1', {
                                        '-disabled': isDeleteButtonDisabled,
                                    })}
                                    disabled={isDeleteButtonDisabled}
                                    style={{ height: 29 }}
                                />
                            );
                        }}
                        isEntriesPaginated={false}
                        toggle={globalToggle}>
                        {({
                            entries: filteredEntries,
                            selectedEntries,
                            handleChangeEntry,
                            handleClearSelected,
                        }: {
                            entries: string[];
                            selectedEntries: string;
                            handleChangeEntry: (entry: string) => void;
                            handleClearSelected: () => void;
                        }) => (
                            <>
                                <BlockAdvertisersList
                                    zoneId={zoneId}
                                    customKey={RESTRICTION_ID_KEY}
                                    advertiserRestrictions={filteredEntries}
                                    selectedEntriesIds={selectedEntries}
                                    handleSelectEntry={handleChangeEntry}
                                    isLoading={isLoadingPublisherBlockedAdvertisers}
                                />
                                {showAlert && (
                                    <Alert
                                        title={t('common.proceedWithCaution')}
                                        body={t('blocking.domains.areYouSureDeleteMessage')}
                                        onAccept={onAcceptAlert({
                                            selectedEntries,
                                            handleClearSelected,
                                        })}
                                        onReject={toggleAlert}
                                        acceptButtonText={t('common.accept')}
                                        rejectButtonText={t('common.cancel')}
                                        context="primary"
                                        isAcceptDisabled={isDeletingRestrictions}
                                    />
                                )}
                            </>
                        )}
                    </SearchSelectableListWrapper>
                </CardBlock>
            </div>
        </Container>
    );
};

BlockAdvertiserDomains.propTypes = {
    zoneId: PropTypes.string,
};

export default BlockAdvertiserDomains;

// Components
import TableData from 'gumdrops/TableData';
import Tag from 'gumdrops/Tag';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableWithLoading from '../../components/common/TableWithLoading';

// Helpers
import { getSortedColumnHeading } from '../../helpers/tableQueryUtils';

const ZonesList = ({ zones: { content }, direction, sortBy, handleUpdateSort, isLoading }) => {
    const { t } = useTranslation();

    const _getEntryLink = ({ trackingId }) => `/zones/edit/${trackingId}/overview`;

    const _getColumnWidth = key =>
        ({
            // selected: '30px',
            id: '80px',
            name: '290px',
        })[key];

    const _getSortedColumnHeading = (title, column) => {
        const { arrowIcon, hasIcon } = getSortedColumnHeading(column, sortBy, direction);
        return (
            <span>
                {title} {hasIcon && <i className={arrowIcon} />}
            </span>
        );
    };

    // const _selectedDecorator = (trackingId, key) => (
    //     <TableData key={key} className="-p-a-0">
    //         <Checkbox
    //             label=""
    //             size="xs"
    //             className="-m-l-3"
    //             // onChange={handleSelectZone}
    //             // checked={selectedZoneIds.includes(trackingId)}
    //             value={trackingId}
    //         />
    //     </TableData>
    // );

    const _idDecorator = (value, key, rowData) => (
        <TableData key={key}>
            <Link className="gds-text--link gds-table--link -block" to={_getEntryLink(rowData)}>
                {value}
            </Link>
        </TableData>
    );

    const _nameDecorator = (value, key, rowData) => (
        <TableData key={key}>
            <Link className="gds-text--link gds-table--link -block" to={_getEntryLink(rowData)}>
                {value}
            </Link>
        </TableData>
    );

    const _productsDecorator = (services, key) => (
        <TableData key={key}>
            {services.map(
                ({ service: { name, enabled } }, index) =>
                    enabled && (
                        <Tag
                            key={index}
                            size="xs"
                            context="primary"
                            text={name.replace(/ Ads$/, '').replace(/Native$/, 'In-Content')}
                            className="-pointer-events--none -m-b-0"
                        />
                    ),
            )}
        </TableData>
    );

    const _getTableColumns = () => [
        // @TODO enable when we want to offer bulk options
        // {
        //     children: ' ',
        //     headingProps: {
        //         style: {
        //             width: _getColumnWidth('selected'),
        //         },
        //     },
        //     key: 'trackingId',
        //     disableSorting: true,
        //     dataCellDecorator: _selectedDecorator,
        // },
        {
            children: _getSortedColumnHeading(t('zones.zoneId'), 'trackingId'),
            headingProps: {
                onClick: () => handleUpdateSort('trackingId'),
                style: { width: _getColumnWidth('id') },
            },
            key: 'trackingId',
            disableSorting: true,
            dataCellDecorator: _idDecorator,
        },
        {
            children: _getSortedColumnHeading(t('zones.zoneName'), 'name'),
            headingProps: {
                onClick: () => handleUpdateSort('name'),
                style: {
                    width: _getColumnWidth('name'),
                    minWidth: '180px',
                },
            },
            key: 'name',
            disableSorting: true,
            dataCellDecorator: _nameDecorator,
        },
        {
            children: t('zones.products'),
            key: 'services',
            disableSorting: true,
            dataCellDecorator: _productsDecorator,
        },
    ];

    return (
        <TableWithLoading
            data={content}
            columns={_getTableColumns()}
            isStriped
            size="sm"
            isLoading={isLoading}
        />
    );
};

ZonesList.propTypes = {
    zones: PropTypes.object.isRequired,
    direction: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    handleUpdateSort: PropTypes.func.isRequired,
};

ZonesList.displayName = 'ZonesList';

export default ZonesList;

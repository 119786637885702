import Button from 'gumdrops/Button';
import PropTypes from 'prop-types';

const Alert = ({
    context,
    title,
    body,
    acceptButtonText,
    rejectButtonText,
    onAccept,
    onReject,
    isAcceptDisabled,
    isInnerHtml,
    icon,
}) => {
    return (
        <div className="gds-alert__overlay gds-alert--shown">
            <div className="gds-alert">
                <div className={`gds-alert__header gds-alert__header--${context} -color-bd-pri`}>
                    {icon ? (
                        icon
                    ) : (
                        <i className="gds-alert__icon fa fa-times-circle" aria-hidden="true" />
                    )}
                    {title && <h2 className="gds-alert__title">{title}</h2>}
                </div>
                <div className="gds-alert__body">
                    {isInnerHtml ? (
                        <p dangerouslySetInnerHTML={{ __html: body }}></p>
                    ) : (
                        <p>{body}</p>
                    )}
                </div>
                <div className="gds-alert__footer">
                    <div className="gds-flex gds-flex--justify-center">
                        <Button
                            onClick={onAccept}
                            name="accept"
                            size="sm"
                            context={context}
                            type="submit"
                            disabled={isAcceptDisabled}>
                            {acceptButtonText}
                        </Button>
                        {context !== 'danger' && (
                            <Button
                                onClick={onReject}
                                name="reject"
                                className="-m-l-2"
                                size="sm"
                                context="outline">
                                {rejectButtonText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

function propOnRejectTest(props, propName, componentName) {
    if (props.context !== 'danger' && typeof props[propName] !== 'function') {
        return new Error(
            `Invalid prop ${propName} supplied to ${componentName}. Validation failed.`,
        );
    }
}

Alert.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onReject: propOnRejectTest,
    context: PropTypes.oneOf(['primary', 'secondary', 'danger']),
    title: PropTypes.string,
    body: PropTypes.string,
    acceptButtonText: PropTypes.string,
    rejectButtonText: PropTypes.string,
    isInnerHtml: PropTypes.bool,
    icon: PropTypes.element,
};

Alert.defaultProps = {
    context: 'primary',
    title: null,
    body: 'Are you sure?',
    acceptButtonText: 'Ok',
    rejectButtonText: 'Cancel',
    onReject: null,
    isInnerHtml: false,
    icon: null,
};

export default Alert;

import cx from 'classnames';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Button from 'gumdrops/Button';
import React, { HTMLAttributes } from 'react';

import styles from './SubmitButton.module.scss';
import basicStyles from '../../features/auth/BasicForm/BasicForm.module.scss';

interface SubmitButtonProps extends HTMLAttributes<HTMLButtonElement> {
    disabled: boolean;
    className?: string;
}

const SubmitButton = ({
    disabled,
    children,
    className = cx('gds-button--block -m-t-6', styles.primaryButton, basicStyles.button),
    ...others
}: SubmitButtonProps) => {
    return (
        <Button
            type="submit"
            className={className}
            context="primary"
            disabled={disabled}
            {...others}>
            {children}
        </Button>
    );
};

export default SubmitButton;

import { isEmpty } from 'ramda';

import { baseApi } from '../../api/api';
import {
    ADVERTISER_DOMAIN,
    GLOBAL_ADVERTISER_ID,
    GLOBAL_KEY,
    IAB_CATEGORY_ID_V1,
    RESTRICTION_ID_KEY,
    ZONES,
} from '../../constants/app';
import { EXCLUDE } from '../../constants/restrictions';
import { isEmptyCustom } from '../../helpers/utils';
import { IABCategoryResponse } from '../../types/IABCategories';

const transformRestrictions = (
    response: unknown,
    // @ts-ignore
    meta,
    // @ts-ignore
    { entity, restrictionType, language, entityId },
) => {
    const isZoneEntity = entity === ZONES;

    switch (restrictionType) {
        case IAB_CATEGORY_ID_V1: {
            if (isEmpty(response)) {
                return {
                    restrictions: [],
                    clusivity: null,
                };
            }

            // @ts-ignore
            const { restrictions, clusivity } = response[0];

            return {
                restrictions: restrictions.map(
                    (record: {
                        advertisingRestrictionId: number;
                        restriction: IABCategoryResponse;
                    }) => {
                        const { restriction, advertisingRestrictionId } = record;
                        const { parentId } = restriction;

                        return {
                            ...restriction,
                            advertisingRestrictionId,
                            parentId: `IAB${parentId}`,
                        };
                    },
                ),
                clusivity,
            };
        }

        case GLOBAL_ADVERTISER_ID: {
            if (isEmpty(response)) {
                return {
                    restrictions: [],
                    clusivity: null,
                };
            }

            // @ts-ignore
            const { restrictions, clusivity } = response[0];

            return !isEmptyCustom(response)
                ? {
                      restrictions: restrictions.map(
                          // @ts-ignore
                          r => ({
                              ...r.restriction,
                              [RESTRICTION_ID_KEY]: r[RESTRICTION_ID_KEY],
                              ...(isZoneEntity ? {} : { tag: GLOBAL_KEY }),
                          }),
                      ),
                      clusivity,
                  }
                : [];
        }

        case ADVERTISER_DOMAIN: {
            if (isEmpty(response)) {
                return {
                    restrictions: [],
                    clusivity: null,
                };
            }

            // @ts-ignore
            const { restrictions, clusivity } = response[0];

            return !isEmptyCustom(response)
                ? {
                      restrictions: restrictions.map(
                          // @ts-ignore
                          r => ({
                              [RESTRICTION_ID_KEY]: r[RESTRICTION_ID_KEY],
                              name: r.restriction,
                              ...(isZoneEntity ? {} : { tag: GLOBAL_KEY }),
                          }),
                      ),
                      clusivity,
                  }
                : [];
        }
    }
};

export const restrictionsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getRestrictions: builder.query({
            query: ({ entity, restrictionType, entityId, language = 'en' }) => ({
                url: `/external/${entity}/${entityId}/restrictions`,
                params: { restrictionType, groupByType: 'true', language },
            }),
            providesTags: ['Restriction'],
            transformResponse: transformRestrictions,
        }),
        createRestrictions: builder.mutation({
            query: ({ entity, entityId, restrictionIds, restrictionType }) => ({
                url: `/external/${entity}/${entityId}/restrictions/bulk`,
                method: 'POST',
                body: [
                    {
                        clusivity: EXCLUDE,
                        restrictions: restrictionIds,
                        type: restrictionType.toLowerCase(),
                    },
                ],
            }),
            invalidatesTags: ['Restriction'],
        }),
        updateRestrictions: builder.mutation({
            query: ({ entity, entityId, restrictionIds, restrictionType }) => ({
                url: `/external/${entity}/${entityId}/restrictions/bulk`,
                method: 'PUT',
                body: [
                    {
                        clusivity: EXCLUDE,
                        restrictions: restrictionIds,
                        type: restrictionType.toLowerCase(),
                    },
                ],
            }),
            invalidatesTags: ['Restriction'],
        }),
        deleteRestrictions: builder.mutation({
            query: ({ entity, entityId, restrictionIds }) => ({
                url: `/external/${entity}/${entityId}/restrictions/bulk`,
                method: 'DELETE',
                body: restrictionIds,
            }),
            invalidatesTags: ['Restriction'],
        }),
    }),
});

export const {
    useGetRestrictionsQuery,
    useCreateRestrictionsMutation,
    useUpdateRestrictionsMutation,
    useDeleteRestrictionsMutation,
} = restrictionsApiSlice;

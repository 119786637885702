import { createSlice } from '@reduxjs/toolkit';
import { Publisher } from '../../types/Publisher';

const initialState: Publisher[] | Array<Publisher> = [];

export const publishersSlice = createSlice({
    name: 'publishers',
    initialState,
    reducers: {
        setPublishers: (state, action) => action.payload,
        publishersSearchClear: () => initialState,
        clearPublishers: () => initialState,
    },
});

export const { setPublishers, publishersSearchClear, clearPublishers } = publishersSlice.actions;

export default publishersSlice.reducer;

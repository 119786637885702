import { VIDEO_PRODUCT_TYPE_IDS } from '../../../constants/app';
import { isEmptyCustom } from '../../../helpers/utils';

/**
 * Filters ads.txt data to get only video products
 * @param {any[]} adsTxt
 * @returns any[]
 */
export const getVideoAdsTxt = adsTxt =>
    adsTxt.filter(
        item =>
            item.products &&
            item.products
                .map(el => el.id)
                .some(element => VIDEO_PRODUCT_TYPE_IDS.includes(element)),
    );

/**
 * Transforms the ads.txt data into an IAB compliant format for publisher consumption.
 * Current format: `adExchange`, `accountId`, `relationshipType`, `certificationTag`
 *
 * @param {any[]} adsTxt
 * @returns string
 */
export const adsTxtTransformer = adsTxt =>
    adsTxt
        .map(item => {
            const tagId = !isEmptyCustom(item.tagId) ? `,${item.tagId}` : '';
            return `${item.exchangeDomain},${item.sellerAccountId},${item.accountType}${tagId}`;
        })
        .join('\n');

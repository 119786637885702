import cx from 'classnames';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tooltip from 'gumdrops/Tooltip';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { NavOption } from '../../constants/navOptions';
import { logout } from '../../features/auth/authReducer';
// Reducers
import { clearPublisher } from '../../features/Browse/publisherReducer';
import { clearPublishers } from '../../features/Browse/publishersReducer';
import { clearNotifications } from '../notifications/notificationsReducer';
// Gumdrops
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Icon from '../common/Icon';
// Components
import NavItem from './NavItem';
import styles from './NavMenu.module.scss';
import PublisherChangeButton from './PublisherChangeButton';

interface NavMenuProps {
    navOptions: NavOption[];
    navOpen: boolean;
    toggleNavOpen: () => void;
}

const NavMenu = ({ navOptions, navOpen, toggleNavOpen }: NavMenuProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [navExpanded, setNavExpanded] = useState(true);

    const toggleNavExpanded = () => setNavExpanded(!navExpanded);

    const goToLogout = () => {
        dispatch(clearPublisher());
        dispatch(clearPublishers());
        dispatch(clearNotifications());
        dispatch(logout());
        navigate('/login');
    };

    return (
        <aside
            data-cy="nav-menu"
            className={cx(styles.navContainer, 'gds-app-layout__left gds-nav__side', {
                'gds-nav__side--collapsed': !navExpanded,
                'gds-app-layout__left--open': navOpen,
            })}>
            <div className="gds-nav__side--collapse-wrapper">
                <button className="gds-nav__side--collapse-button" onClick={toggleNavExpanded} />
            </div>
            <div className="gds-nav__close-wrapper">
                <button className="gds-text-button" onClick={toggleNavOpen}>
                    <Icon icon="times" />
                </button>
            </div>
            <Link
                className={`gds-nav__logo ${styles.logoContainer}`}
                to="/home/reporting"
                data-cy="logo"
            />
            <Link
                to="/home/reporting"
                className="gds-nav__title gds-nav__item--grow-0"
                data-cy="aside-title">
                Publisher Center
            </Link>
            <ul className="gds-nav__links" data-cy="nav-links">
                {navOptions.map(item => (
                    <NavItem key={item.title} item={item} />
                ))}
            </ul>
            <div className={styles.bottomContainer}>
                <PublisherChangeButton isOpen={navExpanded} />

                {/* {navExpanded && <LanguageSelector />} */}
                <ul className="gds-nav__icons gds-nav__item--grow-0">
                    <li>
                        <Tooltip text="Logout" position="top">
                            <button
                                className="gds-nav__icon"
                                onClick={goToLogout}
                                data-cy="logout-button">
                                <i className="fas fa-lg fa-sign-out-alt" />
                            </button>
                        </Tooltip>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default NavMenu;

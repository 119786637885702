// Components
import Button from 'gumdrops/Button';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

// Helpers
import { tryParseJson } from '../../helpers/objectUtils';
import Portal from '../common/Portal';
import ExportGenerating from './ExportGenerating';

class ExportFile extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        fileExt: PropTypes.string.isRequired,
        exportData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        handleInvalidExportData: PropTypes.func,
        endpoint: PropTypes.string,
        className: PropTypes.string.isRequired,
        context: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        style: PropTypes.object,
        size: PropTypes.oneOf(['xs', 'sm', 'md']),
        renderCustomButton: PropTypes.func,
    };

    static defaultProps = {
        title: 'Export File',
        fileName: '',
        fileExt: 'csv',
        className: '-m-r-2',
        context: 'primary',
        disabled: false,
        size: 'sm',
    };

    state = {
        openPortal: false,
        generating: false,
        exportBlob: null,
        isLoadingExportData: false,
    };

    _makeRequest() {
        const { exportData, jwt } = this.props;
        const options = {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'CUSTOM-HEADER-APP': 'PC',
            },
        };

        if (exportData) {
            this._updateBlobWithExportData(exportData);
        } else {
            this.setState({ isLoadingExportData: true });
            fetch(this.props.endpoint, options)
                .then(response => response.blob())
                .then(blob => {
                    const objectURL = URL.createObjectURL(blob);
                    this._updateBlobURL(objectURL);
                })
                .then(() => {
                    this.setState({ isLoadingExportData: false });
                });
        }
    }

    _updateBlobWithExportData = exportData => {
        const { handleInvalidExportData, fileExt } = this.props;
        let preparedData = exportData;
        let contextType = 'text/plain;charset=utf-8';
        if (fileExt === 'json') {
            const stringifiedData =
                typeof exportData === 'string' ? exportData : JSON.stringify(exportData);
            const parsedJson = tryParseJson(stringifiedData);
            if (!parsedJson) {
                handleInvalidExportData && handleInvalidExportData('Invalid Json data provided.');
                throw new Error('Invalid Json data provided.');
            }

            preparedData = JSON.stringify(parsedJson, 0, 4);
            contextType = 'text/json;charset=utf-8';
        }
        const objectURL = URL.createObjectURL(new Blob([preparedData], { type: contextType }));
        this._updateBlobURL(objectURL);
    };

    _createPortal() {
        if (this.windowPortal) {
            this.windowPortal.close();
        }
        this.windowPortal = window.open('about:blank');

        // add a root element to render into
        this.portalEl = this.windowPortal.document.createElement('div');
        this.portalEl.id = 'root';
        this.windowPortal.document.body.appendChild(this.portalEl);
        const link = this.windowPortal.document.createElement('link');
        this.windowPortal.document.head.appendChild(link);
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = 'https://ds.gumgum.com/stable/theme-blue.css';

        this.windowPortal.document.title = `${this.props.title}`;
        this.windowPortal.document.head.appendChild(link);

        this.setState({ openPortal: true });
    }

    _updateBlobURL = url => {
        this.setState({ generating: false, exportBlob: url });
        this.downloadFile(url);
    };

    _handleClose = () => {
        this.windowPortal.close();
    };

    downloadFile = url => {
        const { fileExt, fileName } = this.props;
        const doc = this.windowPortal.document;
        const element = doc.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `${fileName} - ${Date.now()}.${fileExt}`);
        element.style.display = 'none';
        doc.body.appendChild(element);
        element.click();
        doc.body.removeChild(element);
    };

    startExport = () => {
        this._createPortal();
        this._makeRequest();
    };

    render() {
        const { generating, openPortal, exportBlob, isLoadingExportData } = this.state;
        const { title, className, style, context, size, disabled, renderCustomButton } = this.props;

        return (
            <Fragment>
                {!renderCustomButton && (
                    <Button
                        context={context}
                        size={size}
                        className={className}
                        disabled={disabled}
                        style={style}
                        onClick={this.startExport}>
                        <i className="fa fa-file-excel -m-r-2" />
                        {title}
                    </Button>
                )}
                {renderCustomButton &&
                    renderCustomButton({ onClick: this.startExport, isLoadingExportData })}
                {openPortal && (
                    <Portal domNode={this.portalEl}>
                        <ExportGenerating
                            title={title}
                            generating={generating}
                            handleDownload={() => this.downloadFile(exportBlob)}
                            handleClose={this._handleClose}
                        />
                    </Portal>
                )}
            </Fragment>
        );
    }
}

export default ExportFile;

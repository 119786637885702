import React, { FormEvent, useState } from 'react';

// Components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LayoutContainer, Column, Row } from 'gumdrops';

import BasicForm from '../auth/BasicForm/BasicForm';
import { isInternalUser } from '../../helpers/authorization';

// Helpers
import { useResetPasswordMutation } from '../auth/authApiSlice';
import { useAppSelector } from '../../store';
import FormGroupInput from '../../components/layouts/FormGroupInput';
import SubmitButton from '../../components/common/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useInputField from '../../validators/useInputField';
import useConfirmPasswordValidator from '../../validators/useConfirmPasswordValidator';
import usePasswordValidator from '../../validators/usePasswordValidator';
import Loader from '../../components/common/Loader';
import { sendNotification } from '../../components/notifications/notificationsReducer';
import cx from 'classnames';

const PasswordUpdate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);

    const [isLoading, setIsLoading] = useState(false);
    const [resetPassword] = useResetPasswordMutation();

    const {
        onChange: onCurrentPasswordChange,
        inputState: currentPasswordState,
        clearValidation: clearCurrentPasswordValidator,
    } = useInputField({
        initialValue: '',
        validators: [],
    });
    const passwordValidator = usePasswordValidator();
    const {
        onChange: onPasswordChange,
        inputState: passwordState,
        clearValidation: clearPasswordValidator,
    } = useInputField({
        initialValue: '',
        validators: passwordValidator,
    });

    const confirmPasswordValidator = useConfirmPasswordValidator(passwordState.value);
    const {
        onChange: onConfirmPasswordChange,
        inputState: confirmPasswordState,
        clearValidation: clearConfirmPasswordValidator,
    } = useInputField({
        initialValue: '',
        validators: confirmPasswordValidator,
        listeners: [passwordState.value],
    });

    if (isInternal) {
        return (
            <div>
                As administrator, you are not allowed to reset a client&apos;s password. <br /> If
                this was requested by the client, please guide them towards the forgot password
                page.
            </div>
        );
    }

    const isFormValid = passwordState.valid && confirmPasswordState.valid;
    const isCurrentPasswordNotEmpty =
        currentPasswordState.value && currentPasswordState.value.length >= 1;

    const handlePasswordUpdate = async (e: FormEvent) => {
        e.preventDefault();

        if (isFormValid) {
            setIsLoading(true);

            const payload = {
                email: user.email,
                password: currentPasswordState.value,
                newPassword: passwordState.value,
            };

            resetPassword(payload)
                .then(res => {
                    if ('error' in res && 'status' in res.error && res.error.status !== 200) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        throw Error(res.error);
                    }

                    dispatch(
                        sendNotification({
                            text: t('accountSettings.changePassword.updatePasswordSuccessfully'),
                            neverFade: true,
                            context: 'success',
                        }),
                    );

                    clearCurrentPasswordValidator();
                    clearPasswordValidator();
                    clearConfirmPasswordValidator();

                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    dispatch(
                        sendNotification({
                            text: t('errorMessages.password'),
                            neverFade: true,
                            context: 'danger',
                        }),
                    );
                });
        }
    };

    return (
        <LayoutContainer>
            <Row>
                <Column md={12}>
                    <h1 className="gds-text--header-lg -m-b-4 -m-b-3-sm -float-left -float-none--sm">
                        {t('accountSettings.changePassword.changeTitle')}
                    </h1>
                </Column>
            </Row>
            <Row>
                <div className="gds-flex gds-flex--justify-end">
                    <BasicForm
                        title=""
                        subtitle=""
                        style={{ maxWidth: 'none', width: '100%', padding: 0 }}>
                        <form onSubmit={handlePasswordUpdate}>
                            {/* CURRENT PASSWORD */}
                            <FormGroupInput
                                name="currentPassword"
                                type="password"
                                placeholder="*******"
                                value={currentPasswordState.value}
                                inputState={currentPasswordState}
                                label={t('auth.forgotOrResetPassword.form.currentPass') as string}
                                defaultLabelStyles={true}
                                onChange={onCurrentPasswordChange}
                                size="sm"
                                className="-m-b-2"
                                data-cy="current-password-input"
                            />
                            {/* NEW PASSWORD */}
                            <FormGroupInput
                                name="newPassword"
                                type="password"
                                placeholder="*******"
                                value={passwordState.value}
                                inputState={passwordState}
                                label={t('auth.forgotOrResetPassword.form.newPass') as string}
                                defaultLabelStyles={true}
                                onChange={onPasswordChange}
                                size="sm"
                                className="-m-b-2"
                                data-cy="new-password-input"
                            />
                            {/* CONFIRM NEW PASSWORD */}
                            <FormGroupInput
                                name="confirmNewPassword"
                                type="password"
                                placeholder="*******"
                                value={confirmPasswordState.value}
                                inputState={confirmPasswordState}
                                label={
                                    t('auth.forgotOrResetPassword.form.confirmNewPass') as string
                                }
                                defaultLabelStyles={true}
                                size="sm"
                                onChange={onConfirmPasswordChange}
                                className="-m-b-2"
                                data-cy="confirm-new-password-input"
                            />
                            {/* SUBMIT */}
                            <SubmitButton
                                disabled={!isFormValid || !isCurrentPasswordNotEmpty || isLoading}
                                className={cx('gds-button--block -m-t-3')}
                                data-cy="password-update-submit-button">
                                {isLoading
                                    ? (t('common.loading') as string)
                                    : (t(
                                          'auth.forgotOrResetPassword.form.resetSubmitButton',
                                      ) as string)}
                            </SubmitButton>
                        </form>
                        <Loader show={isLoading} />
                    </BasicForm>
                </div>
            </Row>
        </LayoutContainer>
    );
};

export default PasswordUpdate;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ModalWrapper from '../../components/common/ModalWrapper';
// Components
import SlotsCreateForm from './SlotsCreateForm';

const SlotsCreateModal = ({ onCloseModal, ...SlotsCreateFormProps }) => {
    const { t } = useTranslation();
    return (
        <ModalWrapper
            containerClassName="-overflow-visible --modal-override --modal-bulk-override"
            bodyClassName=""
            md="12"
            title={t('zone.slots.request')}
            isOpen
            onClose={onCloseModal}
            hideFooter>
            <SlotsCreateForm toggleCreateForm={onCloseModal} {...SlotsCreateFormProps} />
        </ModalWrapper>
    );
};

SlotsCreateModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
};

export default SlotsCreateModal;

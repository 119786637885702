import { clone, isEmpty, isNil } from 'ramda';

export const isEmptyCustom = (value: string | null | unknown): boolean =>
    isNil(value) || isEmpty(value) || (typeof value === 'string' && isEmpty(value.trim()));

export const removeEmptyProperties = (obj: any) => {
    const clonedObject = clone(obj);
    Object.keys(clonedObject).forEach(
        key => isEmptyCustom(clonedObject[key]) && delete clonedObject[key],
    );
    return clonedObject;
};

/**
 * Looks for a name, id, or specified property on the entity object
 * @param  {Object} entity The object you want to find the title of
 * @param  {Object} config Specify the property name(s) you want to use for id or name
 */
export const getEntityTitle = (entity: any = {}, config: any = {}) => {
    const { idVar = 'id', nameVar = 'name' } = config;
    const preparedId = !isEmptyCustom(entity[idVar]) ? ` (${entity[idVar]})` : '';
    return !isEmptyCustom(entity[nameVar]) ? `${entity[nameVar]}${preparedId}` : preparedId;
};

export const removeStarFromBegining = (value: string) => {
    if (!isEmptyCustom(value) && value.length > 2 && value.startsWith('*.')) {
        return value.substring(2, value.length).trim();
    }
    return value;
};

export const splitAndClean = (str: string, delimiter = '\n') =>
    str
        .split(delimiter)
        .map(val => val.trim())
        .filter(val => val);

export const tryParseJson = (jsonString: string) => {
    try {
        const parsedJson = JSON.parse(jsonString);

        if (parsedJson && typeof parsedJson == 'object') {
            return parsedJson;
        }
    } catch (e) {
        /* empty */
        console.log(e);
    }

    return false;
};

export const objectsMatch = (a: object, b: object) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
        return false;
    }
    const aKeys = Object.keys(a);
    const aLength = aKeys.length;
    const bLength = Object.keys(b).length;

    if (aLength !== bLength) return false;

    const matches = aKeys.map(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return a[key] === b[key];
    });
    return !matches.includes(false);
};

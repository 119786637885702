import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import Layout from '../../components/layouts/Basic/Layout';
import Header from '../../components/layouts/Header/Header';
// Helpers
import { isInternalUser } from '../../helpers/authorization';
import { useAppSelector } from '../../store';
// Hooks
import { useGetPublisherByIdMutation } from './publisherApiSlice';
// Reducers
import { setPublisher } from './publisherReducer';
import { useSearchPublishersMutation } from './publishersApiSlice';
import styles from './Browse.module.scss';
import { publishersSearchClear, setPublishers } from './publishersReducer';
import Loader from '../../components/common/Loader';
import PublisherSelection from './PublisherSelection';
import { useToggle } from 'usehooks-ts';

const Browse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFirstLoad, , setIsFirstLoad] = useToggle(true);

    const [getPublisherById] = useGetPublisherByIdMutation();
    const [searchPublishers, { isLoading: isSearchingPublishers }] = useSearchPublishersMutation();

    const publishers = useAppSelector(state => state.publishers);
    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);

    useEffect(() => {
        if (isInternal) {
            dispatch(publishersSearchClear());
        }

        // redirect to publisher select or reporting
        // depending on the number of publishers the user is linked to
        searchPublishers({})
            .unwrap()
            .then(publishers => {
                dispatch(setPublishers(publishers));
                setIsFirstLoad(false);
                if (publishers.length === 1) {
                    getPublisherById(publishers[0].id)
                        .unwrap()
                        .then(publisher => {
                            dispatch(setPublisher(publisher));
                        });

                    return navigate('/home/reporting');
                }
            });
    }, []);

    const handleSearch = async (searchTerm: string) => {
        const publishers = await searchPublishers({ searchTerm }).unwrap();
        dispatch(setPublishers(publishers));
    };

    const handleSelect = async (value: number) => {
        const publisher = await getPublisherById(value).unwrap();
        dispatch(setPublisher(publisher));

        const publishers = await searchPublishers({}).unwrap();
        dispatch(setPublishers(publishers));

        return navigate('/home/reporting');
    };

    return (
        <Layout>
            <Header />
            <div className={styles.content} data-cy="browse">
                {isFirstLoad ? (
                    <Loader show={isSearchingPublishers} />
                ) : (
                    <PublisherSelection
                        publishers={publishers}
                        isLoading={isSearchingPublishers}
                        handleSearch={handleSearch}
                        handleSelect={handleSelect}
                    />
                )}
            </div>
        </Layout>
    );
};

export default Browse;

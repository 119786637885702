import { IABBlockedCategory, IABParentCategory } from '../../types/IABCategories';
import { useEffect, useState } from 'react';
import { useGetIabCategoriesQuery } from '../blocks/iabCategoriesApiSlice';
import { useAppSelector } from '../../store';
import { useGetRestrictionsQuery } from '../blocks/restrictionApiSlice';
import { useParams } from 'react-router-dom';
import { IAB_CATEGORY_ID_V1, PUBLISHERS, ZONES } from '../../constants/app';

type QueryResult = {
    data: {
        restrictions: IABBlockedCategory[];
        clusivity: string;
    };
    isLoading: boolean;
};

export interface QueryOptions {
    entity: string;
    entityId: string | number | undefined;
    restrictionType: string;
    language: string;
}

const useIABCategories = (): {
    iabCategories: IABParentCategory[];
    isLoading: boolean;
    blockedIABCategories: IABBlockedCategory[];
    blockedIABParentCategories: string[];
    clusivity?: string;
    zoneId?: string;
} => {
    const [blockedIABParentCategories, setBlockedIABParentCategories] = useState<string[]>([]);
    const { zoneId } = useParams();
    const entity = zoneId ? ZONES : PUBLISHERS;
    const { locale } = useAppSelector(state => state.auth);
    const { id: publisherId } = useAppSelector(state => state.publisher);

    const { data: iabCategories = [], isLoading: isLoadingIabCategories } =
        useGetIabCategoriesQuery({ language: locale });

    const queryOptions: QueryOptions = {
        entity,
        entityId: zoneId || publisherId,
        restrictionType: IAB_CATEGORY_ID_V1,
        language: 'en',
    };

    // get PUBLISHER restrictions
    const {
        data: blockedIABCategories = { restrictions: [], clusivity: '' },
        isLoading: isLoadingBlockedCategories,
    } = useGetRestrictionsQuery<QueryResult>(queryOptions);

    const getIds = () => {
        // get unique parentId values to display blocked parent categories
        const parentIds = new Set<string>([]);

        blockedIABCategories.restrictions.forEach((blockedCategory: IABBlockedCategory) => {
            parentIds.add(blockedCategory.parentId);
        });

        return Array.from(parentIds).sort((a, b) =>
            // @ts-ignore
            iabCategories
                .find(category => category.id === a)
                .name.localeCompare(
                    // @ts-ignore
                    iabCategories.find(category => category.id === b).name,
                ),
        );
    };

    useEffect(() => {
        if (!iabCategories.length || !blockedIABCategories.restrictions.length) return;
        setBlockedIABParentCategories(getIds());
    }, [iabCategories, blockedIABCategories]);

    return {
        iabCategories,
        isLoading: isLoadingIabCategories || isLoadingBlockedCategories,
        blockedIABCategories: blockedIABCategories.restrictions,
        blockedIABParentCategories: blockedIABParentCategories,
        clusivity: blockedIABCategories.clusivity,
        zoneId: zoneId,
    };
};

export default useIABCategories;

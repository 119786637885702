import {
    useCreateRestrictionsMutation,
    useDeleteRestrictionsMutation,
    useUpdateRestrictionsMutation,
} from '../blocks/restrictionApiSlice';
import { IAB_CATEGORY_ID_V1, PUBLISHERS, ZONES } from '../../constants/app';
import { useAppSelector } from '../../store';
import { IABBlockedCategory } from '../../types/IABCategories';
import { useParams } from 'react-router-dom';

const useUpdateIABRestriction = (): {
    updateRestrictions: (
        deletedCategories: IABBlockedCategory[],
        categoryIds: string[],
    ) => Promise<void>;
    isLoading: boolean;
} => {
    const { zoneId } = useParams();
    const [createRestrictions, { isLoading }] = useCreateRestrictionsMutation();
    const [updateRestrictions, { isLoading: isUpdating }] = useUpdateRestrictionsMutation();
    const [deleteRestrictions, { isLoading: isDeleting }] = useDeleteRestrictionsMutation();
    const publisherId = useAppSelector(state => state.publisher.id);
    const locale = useAppSelector(state => state.auth.locale);

    const deleteIABRestriction = async (deletedCategories: IABBlockedCategory[]) => {
        await deleteRestrictions({
            entity: PUBLISHERS,
            entityId: publisherId,
            restrictionIds: deletedCategories.map(category => category.advertisingRestrictionId),
        });
    };

    const updateIABRestriction = async (
        deletedCategories: IABBlockedCategory[],
        categoryIds: string[],
    ) => {
        const isZone = Boolean(zoneId);

        if (!isZone && deletedCategories.length > 0) {
            await deleteIABRestriction(deletedCategories);
        }

        if (categoryIds.length === 0) {
            return;
        }

        await (isZone ? updateRestrictions : createRestrictions)({
            entity: isZone ? ZONES : PUBLISHERS,
            entityId: zoneId || publisherId,
            restrictionType: IAB_CATEGORY_ID_V1,
            language: locale,
            restrictionIds: categoryIds,
        });
    };

    return {
        updateRestrictions: updateIABRestriction,
        isLoading: isLoading || isUpdating || isDeleting,
    };
};

export default useUpdateIABRestriction;

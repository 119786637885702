import React from 'react';
// @ts-ignore
import LoadingDots from 'gumdrops/LoadingDots';

interface LoadingOverlayProps {
    showOverlay?: boolean;
    showDots?: boolean;
    children?: React.ReactNode;
    loadingDotsClass?: string;
}
const LoadingOverlay = ({
    showOverlay = true,
    showDots = true,
    children = null,
    loadingDotsClass = '',
}: LoadingOverlayProps) =>
    showOverlay ? (
        <div className="loading-overlay">
            {children}
            {showDots && <LoadingDots className={loadingDotsClass} />}
        </div>
    ) : null;

export default LoadingOverlay;

import { Button, LoadingDots } from 'gumdrops';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import ContentHeader from '../../components/common/ContentHeader';
import IconButton from '../../components/common/IconButton';
import ValidInput from '../../components/inputs/ValidInput';
// Hooks
import { useGetZoneQuery, useUpdateZoneMutation } from './zoneApiSlice';

const ZoneName = ({ zoneId }) => {
    const { t } = useTranslation();
    const [isEditingName, setIsEditingName] = useState(false);
    const [zoneName, setZoneName] = useState('');
    const [inputErrors, setInputErrors] = useState([]);
    const { data: zone, isLoading } = useGetZoneQuery(zoneId);
    const [updateZone] = useUpdateZoneMutation();

    useEffect(() => {
        if (!isLoading) {
            setZoneName(zone.name);
        }
    }, [isLoading]);

    const handleChange = ({ target: { value } }) => {
        const isEmptyString = typeof value === 'string' && !value.trim().length;
        setInputErrors(isEmptyString ? [t('errorMessages.required')] : []);
        setZoneName(value);
    };

    const handleSubmit = async () => {
        if (!zoneName) {
            setInputErrors([t('errorMessages.required')]);
            return;
        }

        if (zoneName === zone.name) {
            setIsEditingName(false);
            return;
        }

        const payload = {
            enabled: zone.enabled,
            name: zoneName,
            verticalId: zone.vertical ? zone.vertical.id : null,
            typeId: zone.zoneType ? zone.zoneType.id : null,
            zoneGradeId: zone.zoneGrade ? zone.zoneGrade.id : null,
            msaStartDate: zone.msaStartDate,
        };

        try {
            await updateZone({ zoneId: zone.trackingId, payload });
            setIsEditingName(false);
        } catch (e) {
            console.error('Error updating zone:', e);
        }
    };

    const handleAbortEdit = () => {
        setInputErrors([]);
        setZoneName(zone.name);
        setIsEditingName(false);
    };

    const editZoneNameButton = (
        <div className="gds-tooltip gds-tooltip--right" data-tooltip="Edit Title">
            <Button
                context="outline"
                onClick={() => setIsEditingName(true)}
                size="xs"
                className="-m-h-2">
                <i className="fa fa-fw fa-edit" />
            </Button>
        </div>
    );

    return isEditingName ? (
        <div className="gds-flex">
            <ValidInput
                size="xs"
                name="name"
                className="-m-b-2"
                placeholder={t('newZone.zoneName')}
                onChange={handleChange}
                value={zoneName}
                errors={inputErrors}
            />
            <IconButton
                size="xs"
                icon="check"
                className="-m-h-1"
                context="success"
                onClick={handleSubmit}
            />
            <IconButton
                size="xs"
                icon="ban"
                className="-m-h-1"
                context="danger"
                onClick={handleAbortEdit}
            />
        </div>
    ) : isLoading ? (
        <LoadingDots style={{ height: '90px' }} />
    ) : (
        <ContentHeader editButton={editZoneNameButton} customTitle={zoneName} />
    );
};

export default ZoneName;

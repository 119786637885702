import { baseApi } from '../../api/api';

const globalAdvertisersApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        searchGlobalAdvertisers: builder.mutation({
            query: ({ partialName, maxResults = 50, hasDomain = true }) => ({
                url: '/external/advertisers-global/search',
                params: {
                    partialName,
                    size: maxResults,
                    page: 0,
                    orderBy: 'name',
                    hasDomain,
                },
            }),
        }),
    }),
});

export const { useSearchGlobalAdvertisersMutation } = globalAdvertisersApiSlice;

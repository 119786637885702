// External libraries
import PropTypes from 'prop-types';

// Components
import Column from 'gumdrops/Column';
import Row from 'gumdrops/Row';
import IconButton from '../../components/common/IconButton';
import ExportFile from '../../components/export/ExportFile';

// Hooks
import { useAppSelector } from '../../store';

const SlotsHeader = ({ slotsCount, toggleCreateForm, exportEndpoint }) => {
    const jwt = useAppSelector(state => state.auth.jwt);
    return (
        <Row
            className="gds-flex gds-flex--align-center gds-flex--justify-between -m-b-2"
            data-cy="slots-header">
            <Column className="gds-flex__item" md="6">
                <h3 className="gds-text--header-sm -inline-block -m-b-2 -m-r-2">
                    All Slots ({slotsCount})
                </h3>
                <IconButton
                    onClick={toggleCreateForm}
                    size="xs"
                    icon="plus"
                    context="primary"
                    data-cy="add-slot-button"
                />
            </Column>
            <Column className="gds-flex__item -text-right" md="6">
                <ExportFile
                    context="outline"
                    title="Export Slot Codes"
                    fileName="Slot Embed Codes"
                    fileExt="csv"
                    disabled={!slotsCount}
                    endpoint={exportEndpoint}
                    jwt={jwt}
                />
            </Column>
        </Row>
    );
};

SlotsHeader.propTypes = {
    exportEndpoint: PropTypes.string.isRequired,
    slotsCount: PropTypes.number.isRequired,
    toggleCreateForm: PropTypes.func.isRequired,
};

export default SlotsHeader;

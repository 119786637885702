// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LoadingDots } from 'gumdrops';
import React from 'react';

interface LoaderProps {
    show: boolean;
}

const Loader = ({ show = false }: LoaderProps) => {
    if (!show) {
        return <></>;
    }

    return (
        <div className="loading-overlay">
            <LoadingDots />
        </div>
    );
};

export default Loader;

import { IABSubCategory } from '../../../../types/IABCategories';
import LabelCheckBox from './LabelCheckBox';
import { useEffect, useState } from 'react';

interface SubCategoryProps {
    selectedCategories: string[];
    iabSubCategory: IABSubCategory;
    onChange: (categoryId: string) => void;
}
const SubCategory = ({ iabSubCategory, selectedCategories, onChange }: SubCategoryProps) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(
            selectedCategories.filter(item => item === iabSubCategory.id).length > 0 ||
                selectedCategories.includes(iabSubCategory.parentId),
        );
    }, [selectedCategories]);

    return (
        <LabelCheckBox
            categoryId={iabSubCategory.id}
            name={iabSubCategory.name}
            checked={checked}
            label={iabSubCategory.label}
            onChange={onChange}
        />
    );
};
export default SubCategory;

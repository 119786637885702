import { isEmptyCustom } from './utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isInternalUser = user =>
    user && user.isInternal && !isEmptyCustom(user.roles) && /@gumgum\.com$/.test(user.email);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isExternalUser = user => !isInternalUser(user);

export const validateLinkTs = (time: string | undefined): boolean => {
    if (typeof time === 'undefined') {
        return false;
    }

    const msBetweenDates = Math.abs(parseInt(time) - new Date().getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    const timeLimitInHours = 24;

    return hoursBetweenDates <= timeLimitInHours;
};

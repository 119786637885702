// Components
import FormGroup from 'gumdrops/FormGroup';
import FormGroupLabel from 'gumdrops/FormGroupLabel';
import FormGroupTextHelp from 'gumdrops/FormGroupTextHelp';
import TextInput from 'gumdrops/TextInput';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const ValidInput = ({ label, className, preAddon, errors, errorStyles, ...inputProps }) => {
    const hasErrors = !!errors.length;

    const _renderInput = () => (
        <Fragment>
            <TextInput {...inputProps} />
            {hasErrors && (
                <FormGroupTextHelp
                    className="-ellipsis"
                    text={errors.join(', ')}
                    style={errorStyles}
                />
            )}
        </Fragment>
    );

    return (
        <FormGroup className={className} context={hasErrors ? 'danger' : 'default'}>
            {label && <FormGroupLabel text={label} />}
            <div style={{ position: 'relative' }}>
                {preAddon ? (
                    <div className="gds-form-group__input-group">
                        <div className="gds-form-group__input-group-addon">
                            <div className="gds-form-group__input-group-cap">{preAddon}</div>
                        </div>
                        {_renderInput()}
                    </div>
                ) : (
                    _renderInput()
                )}
            </div>
        </FormGroup>
    );
};

ValidInput.propTypes = {
    className: PropTypes.string,
    errors: PropTypes.array,
    errorStyles: PropTypes.object,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.node.isRequired,
    preAddon: PropTypes.string,
};

ValidInput.defaultProps = {
    errorStyles: {
        position: 'absolute',
        marginTop: '-2px',
        top: '100%',
        width: '100%',
        padding: '0.01rem 0.25rem',
        zIndex: '201',
    },
    label: null,
    onBlur: null,
    className: null,
    errors: [],
    preAddon: null,
};

export default ValidInput;

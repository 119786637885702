import { useLocation } from 'react-router-dom';

// Constants
import { PRODUCTION_HOSTNAME, STAGE_HOSTNAME } from '../../constants/app';

const HeaderManager = ({ toggleNavOpen }) => {
    const { pathname } = useLocation();

    const _getEnv = () => {
        switch (window.location.hostname) {
            case PRODUCTION_HOSTNAME:
                return null;
            case STAGE_HOSTNAME:
                return (
                    <div className="gds-text--body-sm gds-text--bold -text-center -color-bg-war-lt-1">
                        Staging
                    </div>
                );
            default:
                return (
                    <div className="gds-text--body-sm gds-text--bold -text-center -color-bg-dan-dk-1 -color-tx-white">
                        Development
                    </div>
                );
        }
    };

    const hideHeader = ['/external-login', '/reset-password', '/forgot-password'].includes(
        pathname,
    );
    return !hideHeader ? (
        <header className="gds-app-layout__top" data-cy="header">
            {_getEnv()}
            <div className="gds-nav__top gds-nav__auto-hide">
                <button className="gds-nav__menu gds-nav__auto-hide" onClick={toggleNavOpen} />
                <a className="gds-nav__logo" href="/" />
                <a
                    className="gds-nav__title gds-nav__item--grow-0 -color-tx-white"
                    href="/"
                    style={{ maxWidth: 'none' }}>
                    Publisher Center
                </a>
                <div className="gds-nav__item">{/* this is to fill in space */}</div>
            </div>
        </header>
    ) : (
        ''
    );
};

export default HeaderManager;
export { HeaderManager };

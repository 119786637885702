const location = window.location.href;
const devSites = ['ggops.com', 'localhost', '0.0.0.0'];

export const isDev =
    devSites.some(site => location.includes(site)) && !location.includes('app.gumgum.com');

// Advertising Api V2

// Stage
const API_URL_ADV_STAGE_V2 = 'https://advertising-api-v2.stage.web-engineering.k8s.ggops.com';

// Prod
const API_URL_ADV_PROD_V2 = 'https://advertising-api-v2.prod.va.ggops.com';

// Dev
// const API_URL_ADV_STAGE_V2 = 'https://advertising-api-v2-gha-pc-external-endpoints.dev.web-engineering.k8s.ggops.com';

export const API_URL_ADV_V2 = isDev ? API_URL_ADV_STAGE_V2 : API_URL_ADV_PROD_V2;

// Advertising Auth Application

const ADVERTISING_AUTH_APP_PROD = 'https://advertising-apps-auth.gumgum.com';
export const ADVERTISING_AUTH_APP_STAGE =
    'https://advertising-apps-auth.stage.web-engineering.k8s.ggops.com';
export const ADVERTISING_AUTH_APP = isDev ? ADVERTISING_AUTH_APP_STAGE : ADVERTISING_AUTH_APP_PROD;

// local testing
// const ADVERTISING_AUTH_APP_STAGE = 'http://localhost:3001';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Helpers
import { pushFilters, updateSort } from '../../helpers/tableQueryUtils';

const QueryableTableWrapper = ({ queryParams, children }) => {
    const navigate = useNavigate();

    const handlePageChange = page => pushFilters({ ...queryParams, page }, navigate);

    const handleSizeChange = ({ target: { value: size } }) =>
        pushFilters({ ...queryParams, size, page: 1 }, navigate);

    const handleSearchChange = debounce(
        term =>
            pushFilters(
                {
                    ...queryParams,
                    page: 1,
                    searchTerm: term.trim(),
                },
                navigate,
            ),
        300,
    );

    const handleUpdateSort = newSortBy =>
        updateSort(
            newSortBy,
            {
                ...queryParams,
            },
            navigate,
        );

    return children({
        handlePageChange,
        handleSizeChange,
        handleSearchChange,
        handleUpdateSort,
    });
};

QueryableTableWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    queryParams: PropTypes.object,
};

export default QueryableTableWrapper;

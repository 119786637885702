import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    id?: string | number;
    email?: string;
    name?: string;
    isInternal?: boolean;
    jwt?: string;
    locale?: string;
    showSlotReport?: boolean;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserData: (state, action) => action.payload,
        logout: () => initialState,
    },
});

export const { setUserData, logout } = authSlice.actions;

export default authSlice.reducer;

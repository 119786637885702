// Dependencies
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import MultiSelect from 'gumdrops/MultiSelect';
import TableData from 'gumdrops/TableData';
import TableWithLoading from '../../components/common/TableWithLoading';

// Helpers
import { mapDataMultiSelect, setPreSelected } from '../../helpers/mapDataMultiSelect';
import { isEmptyCustom } from '../../helpers/utils';

const ZoneDomainIdentifierList = props => {
    const { t } = useTranslation();
    const {
        zoneDomainIdentifiers,
        isEditMode,
        selfIdentifiersList,
        selectedZoneSelfIdentifiers,
        handleSelfIdentifiersChange,
        isLoading,
    } = props;
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (!selectedZoneSelfIdentifiers.length) setOptions({});
    }, [selectedZoneSelfIdentifiers]);

    const _getColumnWidth = key =>
        ({
            zoneDomainId: '140px',
            zoneDomain: '200px',
            zoneDomainSelfIdentifiers: '540px',
        })[key];

    const _zoneDomainDecorator = (value, key) => (
        <TableData key={key}>
            <div className="long-string-wrapper">
                {!isEmptyCustom(value?.domain) ? value.domain : !isEmptyCustom(value) ? value : ''}
            </div>
        </TableData>
    );

    const handleMultiSelectChange = (name, list) =>
        setOptions({ ...options, ...{ [`${name}`]: list } });

    const _identifiersDecorator = (value, key, rowData) => {
        const zoneDomainId = rowData.zoneDomainId;
        let selectedValues = rowData.selfIdentifiers
            ? rowData.selfIdentifiers.map(item => item.id)
            : [];
        const selectOptions = options[`selfIdentifiers${key}`]
            ? options[`selfIdentifiers${key}`]
            : setPreSelected(mapDataMultiSelect(selfIdentifiersList), selectedValues);
        return isEditMode ? (
            <TableData key={key}>
                <MultiSelect
                    size="xs"
                    style={{
                        maxWidth: _getColumnWidth('zoneDomainSelfIdentifiers'),
                    }}
                    placeholder={t('accountSettings.zone.identifiers')}
                    name={`selfIdentifiers${key}`}
                    options={selectOptions}
                    onChange={event => {
                        handleMultiSelectChange(`selfIdentifiers${key}`, event);
                        handleSelfIdentifiersChange(zoneDomainId, event);
                    }}
                    data-cy="zone-domain-identifiers-multi-select"
                />
            </TableData>
        ) : (
            <TableData key={key}>
                <div
                    style={{
                        maxWidth: _getColumnWidth('zoneDomainSelfIdentifiers'),
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                    }}>
                    {!isEmptyCustom(value) ? value : ''}
                </div>
            </TableData>
        );
    };

    const _getTableColumns = () => [
        {
            children: t('accountSettings.zone.domain'),
            headingProps: {
                style: { width: _getColumnWidth('zoneDomain') },
            },
            key: 'zoneDomainName',
            disableSorting: true,
            dataCellDecorator: _zoneDomainDecorator,
        },
        {
            children: t('accountSettings.zone.domainId'),
            headingProps: {
                style: { width: _getColumnWidth('zoneDomainId') },
            },
            key: 'zoneDomainId',
            disableSorting: true,
            dataCellDecorator: _zoneDomainDecorator,
        },
        {
            children: t('accountSettings.zone.identifiers'),
            headingProps: {
                style: { width: _getColumnWidth('zoneIdentifiers') },
            },
            key: 'zoneDomainSelfIdentifiers',
            disableSorting: true,
            dataCellDecorator: _identifiersDecorator,
        },
    ];

    return (
        <TableWithLoading
            data={zoneDomainIdentifiers.content}
            columns={_getTableColumns()}
            isStriped
            size="md"
            className="ds-table--responsive-self-identifiers"
            isLoading={isLoading}
        />
    );
};

ZoneDomainIdentifierList.propTypes = {
    zoneDomainIdentifiers: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    selfIdentifiersList: PropTypes.array.isRequired,
    handleSelfIdentifiersChange: PropTypes.func.isRequired,
};

export default ZoneDomainIdentifierList;

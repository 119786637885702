import { baseApi } from '../../api/api';

export const gdprApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        updateGDPRDate: builder.mutation({
            query: ({ publisherId }) => ({
                url: `/external/publishers/${publisherId}/accept-gdpr`,
                method: 'PATCH',
            }),
        }),
    }),
});

export const { useUpdateGDPRDateMutation } = gdprApi;

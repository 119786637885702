import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';
import { API_URL_ADV_V2 } from './constants';

export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL_ADV_V2,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const jwt = state.auth.jwt;
            headers.set('authorization', `Bearer ${jwt}`);
            headers.set('CUSTOM-HEADER-APP', 'PC');
            return headers;
        },
    }),
    tagTypes: [
        'Restriction',
        'Self Identifier',
        'Zone',
        'Zone Services',
        'Zone Blocked URLs',
        'Zone Slots',
    ],
    endpoints: () => ({}),
});

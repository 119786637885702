import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Tabs = ({ options, topPath, location, entityLevel, onClick }) => {
    const currentLoc = location.split('/');
    const isActive = `/${currentLoc[entityLevel ? entityLevel : currentLoc.length - 1]}`;

    const activeTabClasses = 'gds-nav-tabs__list-item gds-nav-tabs__list-item--active';
    const tabClasses = 'gds-nav-tabs__list-item';

    return (
        <nav data-cy="nav-tabs">
            <ul className="gds-nav-tabs gds-nav-tabs--sm gds-nav-tabs--justified">
                {options.map(({ title, path }) => (
                    <li
                        key={path}
                        className={path == isActive ? activeTabClasses : tabClasses}
                        onClick={onClick}>
                        <Link to={topPath + path} className="gds-nav-tabs__link">
                            {title}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

Tabs.defaultProps = {
    entityLevel: null,
    onClick: null,
};

Tabs.propTypes = {
    options: PropTypes.array.isRequired,
    topPath: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    entityLevel: PropTypes.number,
};

export default Tabs;

export const COMMON_BLOCKED_CATEGORIES = [
    'IAB7',
    'IAB14',
    'IAB23',
    'IAB25',
    'IAB26',
    'IAB30',
    // Temporary commented, remove later
    // 'IAB7-1',
    // 'IAB7-2',
    // 'IAB7-3',
    // 'IAB7-4',
    // 'IAB7-5',
    // 'IAB7-6',
    // 'IAB7-7',
    // 'IAB7-8',
    // 'IAB7-9',
    // 'IAB7-10',
    // 'IAB7-11',
    // 'IAB7-12',
    // 'IAB7-13',
    // 'IAB7-14',
    // 'IAB7-15',
    // 'IAB7-16',
    // 'IAB7-17',
    // 'IAB7-18',
    // 'IAB7-19',
    // 'IAB7-20',
    // 'IAB7-21',
    // 'IAB7-22',
    // 'IAB7-23',
    // 'IAB7-24',
    // 'IAB7-25',
    // 'IAB7-26',
    // 'IAB7-27',
    // 'IAB7-28',
    // 'IAB7-29',
    // 'IAB7-30',
    // 'IAB7-31',
    // 'IAB7-32',
    // 'IAB7-33',
    // 'IAB7-34',
    // 'IAB7-35',
    // 'IAB7-36',
    // 'IAB7-37',
    // 'IAB7-38',
    // 'IAB7-39',
    // 'IAB7-40',
    // 'IAB7-41',
    // 'IAB7-42',
    // 'IAB7-43',
    // 'IAB7-44',
    // 'IAB7-45',
    // 'IAB14-1',
    // 'IAB14-2',
    // 'IAB14-3',
    // 'IAB14-4',
    // 'IAB14-5',
    // 'IAB14-6',
    // 'IAB14-7',
    // 'IAB14-8',
    // 'IAB23-1',
    // 'IAB23-2',
    // 'IAB23-3',
    // 'IAB23-4',
    // 'IAB23-5',
    // 'IAB23-6',
    // 'IAB23-7',
    // 'IAB23-8',
    // 'IAB23-9',
    // 'IAB23-10',
    // 'IAB25-3',
    // 'IAB25-4',
    // 'IAB25-5',
    // 'IAB26-3',
    // 'IAB30-1',
    // 'IAB30-2',
    // 'IAB30-3',
    // 'IAB30-4',
    // 'IAB30-5',
    // 'IAB30-6',
    // 'IAB30-7',
    // 'IAB30-8',
    // 'IAB30-9',
];

import { createSlice } from '@reduxjs/toolkit';
import { path } from 'ramda';
import { Publisher } from '../../types/Publisher';

const initialState: Partial<Publisher> = {};

export const publisherSlice = createSlice({
    name: 'publisher',
    initialState,
    reducers: {
        setPublisher: (state, action) => ({
            ...action.payload,
            businessUnitId: path(['businessUnit', 'id'], action.payload),
        }),
        updatePublisher: (state, action) => {
            Object.assign(state, action.payload);
        },
        clearPublisher: () => initialState,
    },
});

export const { setPublisher, updatePublisher, clearPublisher } = publisherSlice.actions;

export default publisherSlice.reducer;

import React from 'react';

import { ValidatorResult } from '../../../validators/types';
import Icon from '../Icon';
import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ isValid, message }: ValidatorResult) =>
    message ? (
        <div
            className={`${styles.inputError} ${
                isValid ? styles.inputErrorValid : styles.inputErrorInvalid
            }`}>
            <Icon
                icon={isValid ? 'check' : 'times'}
                prefix="fa"
                size="md"
                color={isValid ? 'green' : 'red'}
            />{' '}
            {message}
        </div>
    ) : (
        <></>
    );
export default ErrorMessage;

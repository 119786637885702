import PropTypes from 'prop-types';

import { Table, LoadingDots } from 'gumdrops';

// Helpers
import { isEmptyCustom } from '../../helpers/utils';
import NoData from './NoData';
import { useEffect, useState } from 'react';

const TableWithLoading = props => {
    const {
        data,
        columns,
        className,
        size,
        tableName,
        tableStyle,
        containerStyle,
        noDataWrapperStyle,
        isLoading,
        ...other
    } = props;

    const [showNoData, setShowNoData] = useState(false);

    const isEmpty = isEmptyCustom(data);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowNoData(true);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [isLoading]);

    return (
        <div className={className} style={!isEmpty ? containerStyle : noDataWrapperStyle}>
            <Table
                data={data}
                columns={columns}
                size={size}
                {...other}
                style={isEmpty ? tableStyle : ''}
            />
            {isEmpty && (
                <div className="loading-overlay">
                    {isLoading ? (
                        <LoadingDots />
                    ) : showNoData ? (
                        <NoData
                            message={isLoading ? 'Loading...' : `No ${tableName} found`}
                            size="lg"
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
};

TableWithLoading.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
    tableName: PropTypes.string,
    tableStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    noDataWrapperStyle: PropTypes.object,
    isLoading: PropTypes.bool,
};

TableWithLoading.defaultProps = {
    data: [],
    size: 'sm',
    className: 'gds-table--responsive',
    tableName: 'results',
    tableStyle: { tableLayout: 'fixed' },
    containerStyle: {},
    noDataWrapperStyle: { height: 200 },
};

export default TableWithLoading;

import SearchSingleSelectV2 from '../../components/common/Select/SearchSingleSelect';
import React from 'react';
import { Publisher } from '../../types/Publisher';
import { useTranslation } from 'react-i18next';

interface PublisherSelectionProps {
    publishers: Publisher[];
    isLoading: boolean;
    handleSearch: (searchTerm: string) => void;
    handleSelect: (id: number) => void;
}
const PublisherSelection = ({
    publishers,
    isLoading,
    handleSearch,
    handleSelect,
}: PublisherSelectionProps) => {
    const { t } = useTranslation();

    return (
        <>
            <span className="gds-text--header-sm -color-tx-white -m-b-2">
                {t('publisher.select') as string}
            </span>
            <SearchSingleSelectV2
                options={publishers}
                isLoading={isLoading}
                name="publisher-search"
                onChange={handleSearch}
                onSelect={handleSelect}
                placeholder="Search..."
                showClose
                size="sm"
                style={{ width: '50vw' }}
            />
        </>
    );
};

export default PublisherSelection;

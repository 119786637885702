import i18n from '../translations/i18n';

export const passbackOption = {
    title: i18n.t('reporting.publisherCampaigns'),
    path: '/publisher-campaigns',
};
export const slotRevenueOption = {
    title: i18n.t('reporting.slotRevenue'),
    path: '/slot-revenue',
};
export const historicalJPReportOption = {
    title: 'Historical JustPremium Reporting',
    path: '/historical-jp-reporting',
};

export const reportingTabOptions = [
    { title: i18n.t('reporting.title'), path: '/reporting' },
    { title: i18n.t('reporting.advertiserSpend'), path: '/advertiser-spend' },
    { title: i18n.t('reporting.customReports'), path: '/custom' },
];

export const accountSettingsTabOptions = [
    {
        title: i18n.t('accountSettings.inclusiveInventory.title'),
        path: '/inclusive-inventory-program',
    },
    { title: i18n.t('accountSettings.adsTxt.title'), path: '/authorized-digital-sellers' },
    { title: i18n.t('accountSettings.billing'), path: '/billing' },
    { title: i18n.t('accountSettings.changePassword.changeTitle'), path: '/password-update' },
];

export const zoneTabOptions = [
    { title: 'Overview', path: '/overview' },
    { title: 'Slots', path: '/slots' },
    { title: 'Blocking', path: '/blocking' },
];

export const inVideoReportOptions = [
    { title: i18n.t('reporting.inVideoDashboard'), path: '/in-video-dashboard' },
    { title: i18n.t('reporting.inVideoCustomReporting'), path: '/in-video-custom' },
];

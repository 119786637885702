import { baseApi } from '../../api/api';
import { PublisherSearchResult } from '../../types/Publisher';

type PublisherSearchQuery = Partial<{
    searchTerm: string;
    maxResults: number;
}>;

export const publishersApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        searchPublishers: builder.mutation<PublisherSearchResult[], PublisherSearchQuery | void>({
            query: (query = {}) => {
                const { searchTerm = '', maxResults = 50 } = query as PublisherSearchQuery;
                return {
                    url: '/external/publishers/simple-search',
                    params: {
                        searchTerm: searchTerm,
                        maxResults: maxResults,
                    },
                };
            },
        }),
    }),
});

export const { useSearchPublishersMutation } = publishersApiSlice;

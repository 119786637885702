import { baseApi } from '../../api/api';
import { SLOT_FLEX_SIZE_ID, SLOT_FLEX_SIZE_NAME } from '../../constants/app';
import { Slot, SlotSize } from '../../types/Slots';
import { ImplementationType } from '../../types/Zone';

export const slotsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getSlots: builder.query<Slot[], string>({
            query: zoneId => ({ url: `/external/zones/${zoneId}/slots` }),
            transformResponse: (response: Slot[]) =>
                response.map(({ slotSize, ...slot }) => ({
                    ...slot,
                    slotSize: {
                        ...slotSize,
                        name:
                            slotSize.id == SLOT_FLEX_SIZE_ID
                                ? SLOT_FLEX_SIZE_NAME
                                : `${slotSize.width}*${slotSize.height}`,
                    },
                })) as Slot[],
            providesTags: ['Zone Slots'],
        }),
        getSlotSizes: builder.query<SlotSize[], null>({
            query: () => ({ url: '/external/slot-sizes' }),
            transformResponse: (response: SlotSize[]) =>
                response.map(slotSize => ({
                    ...slotSize,
                    value: slotSize.id,
                    name:
                        slotSize.id == SLOT_FLEX_SIZE_ID
                            ? SLOT_FLEX_SIZE_NAME
                            : `${slotSize.width} x ${slotSize.height}`,
                })),
        }),
        getSlotImplementationTypes: builder.query<ImplementationType[], null>({
            query: () => ({
                url: '/external/implementation-types',
                params: { type: 'slot' },
            }),
        }),
        createSlotsBulk: builder.mutation({
            query: ({ zoneId, slots }) => ({
                url: `/external/zones/${zoneId}/slots/bulk`,
                body: { slots, trackingId: zoneId },
                method: 'PUT',
            }),
            invalidatesTags: ['Zone Slots'],
        }),
    }),
});

export const {
    useGetSlotsQuery,
    useGetSlotSizesQuery,
    useGetSlotImplementationTypesQuery,
    useCreateSlotsBulkMutation,
} = slotsApiSlice;

import { baseApi } from '../../api/api';
import { PaymentLedger } from '../../types/PaymentLedger';
import { PublisherFilters } from '../../types/Publisher';

export const paymentsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getPublisherPaymentLedger: builder.query<PaymentLedger[], PublisherFilters>({
            query: ({ publisherId, filters }) => ({
                url: `/external/publishers/${publisherId}/payment-ledger`,
                params: filters,
            }),
        }),
    }),
});

export const { useGetPublisherPaymentLedgerQuery } = paymentsApiSlice;

import { baseApi } from '../../api/api';
import { sendNotification } from '../../components/notifications/notificationsReducer';
import i18n from '../../translations/i18n';
import { AssetUrlFilterPattern, EmbedCode, Service, Zone } from '../../types/Zone';

export const zoneApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        /*@todo discuss what to do with this endpoint*/
        addZone: builder.mutation({
            query: zone => ({
                url: '/external/request',
                body: zone,
                method: 'POST',
            }),
        }),
        getZone: builder.query<Zone, string>({
            query: zoneId => ({ url: `/external/zones/${zoneId}` }),
            providesTags: ['Zone'],
        }),
        updateZone: builder.mutation({
            query: ({ zoneId, payload }) => ({
                url: `/external/zones/${zoneId}`,
                body: payload,
                method: 'PUT',
            }),
            async onQueryStarted({ zoneId, payload }, { dispatch, queryFulfilled }) {
                const optimisticUpdate = dispatch(
                    // @ts-ignore
                    baseApi.util.updateQueryData('getZone', zoneId, zone =>
                        Object.assign(zone, payload),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    console.log({ e });
                    dispatch(
                        sendNotification({
                            text: i18n.t('errorMessages.general'),
                            neverFade: true,
                            context: 'danger',
                        }),
                    );
                    optimisticUpdate.undo();
                }
            },
        }),
        getZoneServices: builder.query<Service[], string>({
            query: zoneId => ({ url: `/external/zones/${zoneId}/services` }),
            providesTags: ['Zone Services'],
        }),
        addZoneBulkServices: builder.mutation({
            query: payload => ({
                url: '/external/zones/bulk/services',
                body: payload,
                method: 'PUT',
            }),
            invalidatesTags: ['Zone Services'],
        }),
        deleteZoneService: builder.mutation({
            query: ({ serviceId, zoneId }) => ({
                url: `/external/zones/${zoneId}/services/${serviceId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ zoneId, servicesToDelete }, { dispatch, queryFulfilled }) {
                const optimisticDelete = dispatch(
                    // @ts-ignore
                    baseApi.util.updateQueryData('getZoneServices', zoneId, zoneServices =>
                        // @ts-ignore
                        zoneServices.filter(
                            // @ts-ignore
                            ({ service: { id } }) => !servicesToDelete.includes(id),
                        ),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    console.log({ e });
                    dispatch(
                        sendNotification({
                            text: i18n.t('errorMessages.general'),
                            neverFade: true,
                            context: 'danger',
                        }),
                    );
                    optimisticDelete.undo();
                }
            },
            invalidatesTags: ['Zone Services'],
        }),
        getZoneBlockedUrls: builder.query<AssetUrlFilterPattern, string>({
            query: zoneId => ({ url: `/external/zones/${zoneId}/asset-url-filter-patterns` }),
            providesTags: ['Zone Blocked URLs'],
        }),
        createZoneAssetUrlFilterPatterns: builder.mutation<
            AssetUrlFilterPattern[],
            { zoneId: string; payload: any }
        >({
            query: ({ zoneId, payload }) => {
                return {
                    url: `/external/zones/${zoneId}/asset-url-filter-patterns/bulk`,
                    body: payload,
                    method: 'POST',
                };
            },
            invalidatesTags: ['Zone Blocked URLs'],
        }),
        deleteZoneBlockedUrl: builder.mutation({
            query: ({ zoneId, urlId }) => ({
                url: `/external/zones/${zoneId}/asset-url-filter-patterns/${urlId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ zoneId, urlId }, { dispatch, queryFulfilled }) {
                const optimisticDelete = dispatch(
                    //@ts-ignore
                    baseApi.util.updateQueryData('getZoneBlockedUrls', zoneId, zoneBlockUrls =>
                        // @ts-ignore
                        zoneBlockUrls.filter(({ id }) => id != urlId),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    console.log({ e });
                    dispatch(
                        sendNotification({
                            text: i18n.t('errorMessages.general'),
                            neverFade: true,
                            context: 'danger',
                        }),
                    );
                    optimisticDelete.undo();
                }
            },
        }),
        getZoneEmbedCodes: builder.query<EmbedCode, string>({
            query: zoneId => ({
                url: '/external/zones/embed-codes',
                params: { trackingIds: zoneId },
            }),
            transformResponse: (response: EmbedCode[]) => response[0],
        }),
    }),
});

export const {
    useAddZoneMutation,
    useGetZoneQuery,
    useUpdateZoneMutation,
    useGetZoneServicesQuery,
    useAddZoneBulkServicesMutation,
    useDeleteZoneServiceMutation,
    useGetZoneBlockedUrlsQuery,
    useCreateZoneAssetUrlFilterPatternsMutation,
    useDeleteZoneBlockedUrlMutation,
    useGetZoneEmbedCodesQuery,
} = zoneApiSlice;

import { split, uniq } from 'ramda';

export const separateStringByRegex = (data, splitTerm = new RegExp(/[\n,]/)) => {
    const separatedValues = split(splitTerm, data);
    // Remove empty rows and duplicate
    return uniq(separatedValues.filter(item => item));
};

/**
 * Breaks and trim words from a textarea into an array.
 * Removes duplicates
 *
 * @returns Array of objects : ['string1', 'string2']
 */
export const getFormattedTextAreaData = (
    textAreaContent,
    formatItem,
    splitTerm = new RegExp(/[\n,]/),
) => {
    // Get values from textArea
    const values = split(splitTerm, textAreaContent);
    // Trim and format value each row
    const formattedValues = values.map(item =>
        formatItem ? formatItem(item.trim()) : item.trim(),
    );

    // Remove empty rows and duplicate
    return uniq(formattedValues.filter(item => item));
};

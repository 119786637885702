import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
    isAuthenticated: boolean;
    redirectPath: string;
    element: ReactElement;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    isAuthenticated,
    redirectPath,
    element,
}): ReactElement => {
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} />;
    }

    return element;
};

export default ProtectedRoute;

import { useTranslation } from 'react-i18next';

import { Validator } from './types';

const useConfirmPasswordValidator = (password: string | null) => {
    const { t } = useTranslation();

    const validateEquality = (value: string | null) => !!value && !!password && value === password;

    return [
        {
            validate: validateEquality,
            onValid: null,
            onError: t('auth.forgotOrResetPassword.validations.noMatch'),
        },
    ] as Validator[];
};

export default useConfirmPasswordValidator;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ADVERTISING_AUTH_APP } from '../../api/constants';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: ADVERTISING_AUTH_APP }),
    endpoints: builder => ({
        login: builder.mutation({
            query: ({ email, password }) => ({
                url: '/auth/login',
                method: 'POST',
                body: { email, password },
                headers: {
                    'CUSTOM-HEADER-APP': 'PC',
                },
            }),
        }),
        forgotPassword: builder.mutation({
            query: email => ({
                url: '/auth/forget-pwd',
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: builder.mutation({
            query: payload => ({
                url: '/auth/reset-pwd',
                method: 'POST',
                body: payload,
            }),
        }),
        validateToken: builder.mutation({
            query: payload => ({
                url: '/auth/validate',
                method: 'POST',
                body: payload,
            }),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transformResponse: response => response.response || {},
        }),
    }),
});

export const {
    useLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useValidateTokenMutation,
} = authApi;

export const LOOKER_REPORTS = {
    ['reporting']: 5,
    ['advertiser-spend']: 4,
    ['custom']: 9,
    ['historical-jp-reporting']: 10,
    ['slot-revenue']: 8,
    ['publisher-campaigns']: 7,
    ['in-video-dashboard']: 14,
    ['in-video-custom']: 13,
};

// Zendesk release notes IDs
export const ZENDESK_ID = '4403033708429';
export const JUSTPREMIUM_ZENDESK_ID = '10141527576717';

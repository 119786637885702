// External libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Column from 'gumdrops/Column';
import Row from 'gumdrops/Row';
import SectionHeader from '../../components/common/SectionHeader';

// Hooks
import { useGetZoneEmbedCodesQuery } from './zoneApiSlice';

// Components (continued)
import ZoneEmbedCodesTag from './ZoneEmbedCodesTag';

const ZoneEmbedCodes = ({ zoneId, isApp }) => {
    const { t } = useTranslation();
    const { data: embedCodes = [] } = useGetZoneEmbedCodesQuery(zoneId);

    return (
        <div className="-m-b-4" data-cy="zone-embed-codes">
            <SectionHeader title={t('zone.overview.embedCodes')} />
            <Row className="-p-a-2">
                <Column md={12}>
                    {!isApp && embedCodes.standardCode && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.standardCode')}
                            tag={embedCodes.standardCode}
                        />
                    )}
                    {!isApp && embedCodes.adServerReadyCode && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.asyncCode')}
                            tag={embedCodes.adServerReadyCode}
                        />
                    )}
                    {embedCodes.inVastTag && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.vastTagIn')}
                            tag={embedCodes.inVastTag}
                        />
                    )}
                    {embedCodes.outVastTag && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.vastTagOut')}
                            tag={embedCodes.outVastTag}
                        />
                    )}
                    {embedCodes.inVideoVastTag && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.inVideoVastTag')}
                            tag={embedCodes.inVideoVastTag}
                        />
                    )}
                    {embedCodes.inVideoVMapTag && (
                        <ZoneEmbedCodesTag
                            title={t('zone.overview.inVideoVMapTag')}
                            tag={embedCodes.inVideoVMapTag}
                        />
                    )}
                </Column>
            </Row>
        </div>
    );
};

ZoneEmbedCodes.propTypes = {
    zoneId: PropTypes.string.isRequired,
    isApp: PropTypes.bool.isRequired,
};

ZoneEmbedCodes.displayName = 'ZoneEmbedCodes';

export default ZoneEmbedCodes;

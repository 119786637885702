import { useTranslation } from 'react-i18next';

import { isEmptyCustom } from '../helpers/utils';
import { Validator } from './types';

const useSimplePasswordValidator = (): Validator[] => {
    const { t } = useTranslation();
    const message = t('auth.login.validations.invalidPassword');

    const validate = (value: string | null) => !isEmptyCustom(value);

    return [
        {
            validate: validate,
            onValid: null,
            onError: message,
        },
    ] as Validator[];
};

export default useSimplePasswordValidator;

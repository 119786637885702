import { baseApi } from '../../api/api';
import { SearchZoneResult, Service, Zone } from '../../types/Zone';
import { Paginator } from '../../types/Paginator';
import { PublisherFilters } from '../../types/Publisher';

interface ContentData {
    zone: Zone;
    services: Service[];
}

export const zonesApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        searchZones: builder.mutation<SearchZoneResult, PublisherFilters>({
            query: ({ publisherId, filters }) => ({
                url: `/external/publishers/${publisherId}/zones/search`,
                params: filters,
            }),
            transformResponse: (response: Paginator & { content: ContentData[] }) => ({
                ...response,
                content: response.content.map(({ zone, services }) => ({
                    ...zone,
                    services,
                })),
            }),
        }),
    }),
});

export const { useSearchZonesMutation } = zonesApiSlice;

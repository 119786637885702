export const parseJWT = (token: string | undefined) => {
    try {
        if (!token) {
            return null;
        }
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export default parseJWT;

import { createSlice } from '@reduxjs/toolkit';

import idGenerator from '../../helpers/idGenerator';

type NotificationType = {
    endpointName: string;
    requestStatus: string;
    text: string;
    neverFade: boolean;
    msToClose: number;
    context: 'danger' | 'success' | 'warning';
    id: string | number;
};

const initialState: [] | NotificationType[] = [];
const idGen = idGenerator();

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        sendNotification: (state, action) => {
            // filter out PENDING notification associated with FULFILLED notification to dismiss it automatically
            const dismissedRequestNotifications = state.filter(
                ({ endpointName, requestStatus }) =>
                    endpointName !== action.payload.endpointName && requestStatus !== 'pending',
            );
            const notification = {
                ...action.payload,
                id: idGen.next().value,
            };

            return [...dismissedRequestNotifications, notification];
        },
        dismissNotification: (state, action) => state.filter(n => n.id !== action.payload),
        clearNotifications: () => initialState,
    },
});

export const { sendNotification, dismissNotification, clearNotifications } =
    notificationsSlice.actions;

export default notificationsSlice.reducer;

import { IABBlockedCategory, IABParentCategory } from '../../types/IABCategories';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import CategoriesList from './CategoriesList';
// @ts-ignore
import { Button, Modal, ModalBody, ModalFooter, ModalForm, ModalHeader } from 'gumdrops';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useUpdateIABRestriction from './useUpdateIABRestriction';
import useDeleteIABRestriction from './useDeleteIABRestriction';

interface EditIABRestrictionsProps {
    iabCategories: IABParentCategory[];
    blockedCategories: IABBlockedCategory[];
}

const UpdateIABRestrictions = ({ iabCategories, blockedCategories }: EditIABRestrictionsProps) => {
    const { t } = useTranslation();
    const { categoryId } = useParams();
    const { updateRestrictions, isLoading } = useUpdateIABRestriction();
    const { deleteRestrictions, isLoading: isDeleting } = useDeleteIABRestriction();
    const { state } = useLocation();
    const navigate = useNavigate();
    const previousLocation = state?.previousLocation || '/global-advertiser-blocks';
    const initialState =
        blockedCategories
            .filter(category => category.parentId === categoryId)
            .map(category => category.id) || [];
    const [userSelection, setUserSelection] = useState<string[]>(initialState);

    const onCloseHandler = () => {
        navigate(previousLocation);
    };

    const onSaveHandler = async (e: Event) => {
        e.preventDefault();
        // compare userSelection with initially blocked categories
        const deletedCategoriesIds = blockedCategories
            .filter(category => category.parentId === categoryId)
            .filter(category => userSelection.indexOf(category.id) === -1);
        await updateRestrictions(deletedCategoriesIds, userSelection);
        navigate(previousLocation);
    };

    const onRemoveAllHandler = async (e: Event) => {
        e.preventDefault();
        setUserSelection([]);
        // compare userSelection with initially blocked categories
        const deletedCategoriesIds = blockedCategories.filter(
            category => category.parentId === categoryId,
        );
        await deleteRestrictions(deletedCategoriesIds, []);
        navigate(previousLocation);
    };

    const filteredCategories = iabCategories.filter(category => category.id == categoryId);

    const isInitialState =
        JSON.stringify(userSelection.sort()) === JSON.stringify(initialState.sort());

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <Modal
                        onClose={onCloseHandler}
                        isOpen={true}
                        md={8}
                        shouldCloseOnOverlayClick={false}>
                        <ModalHeader
                            title={t('blocking.iabCategories.select')}
                            onClose={onCloseHandler}
                        />
                        <ModalForm style={{ maxHeight: '80vh' }}>
                            <ModalBody>
                                <p className={'-m-b-3'}>{t('blocking.iabCategories.selectHint')}</p>
                                <CategoriesList
                                    iabCategories={filteredCategories}
                                    onChange={setUserSelection}
                                    selectedCategories={userSelection}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    className={'-m-r-1'}
                                    disabled={isLoading || isDeleting}
                                    size="sm"
                                    context={'danger'}
                                    onClick={onRemoveAllHandler}>
                                    Remove All
                                </Button>
                                <Button
                                    className={'-m-r-1'}
                                    disabled={isLoading || isDeleting}
                                    size="sm"
                                    onClick={onCloseHandler}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isLoading || isDeleting || isInitialState}
                                    context={'primary'}
                                    size="sm"
                                    onClick={onSaveHandler}>
                                    Save Changes
                                </Button>
                            </ModalFooter>
                        </ModalForm>
                    </Modal>
                }></Route>
        </Routes>
    );
};

export default UpdateIABRestrictions;

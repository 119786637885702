import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Styles
import './PublisherChangeButton.scss';
import cx from 'classnames';
// Gumdrops
// @ts-ignore
import Tooltip from 'gumdrops/Tooltip';

// Helpers
import { isInternalUser } from '../../helpers/authorization';
import { isEmptyCustom } from '../../helpers/utils';
import { useAppSelector } from '../../store';
import { setPublishers } from '../../features/Browse/publishersReducer';
import { useSearchPublishersMutation } from '../../features/Browse/publishersApiSlice';

interface PublisherChangeButtonProps {
    isOpen: boolean;
}

export const PublisherChangeButton = ({ isOpen = true }: PublisherChangeButtonProps) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [searchPublishers] = useSearchPublishersMutation();

    const user = useAppSelector(state => state.auth);
    const isInternal = isInternalUser(user);
    const publishers = useAppSelector(state => state.publishers);
    const publisher = useAppSelector(state => state.publisher);

    const openPublisherSelection = () => {
        navigate('/browse');
    };

    const selectPublishers = async () => {
        const publishers = await searchPublishers().unwrap();
        dispatch(setPublishers(publishers));
    };

    useEffect(() => {
        if (isEmptyCustom(publisher)) {
            openPublisherSelection();
        } else if (!isInternal || publishers.length === 0) {
            selectPublishers();
        }
    }, []);

    return isInternal || publishers.length > 1 ? (
        <div className="publisher-change-button" data-cy="publisher-change-button">
            <Tooltip text="Change publisher" position="top-right" className="tooltip">
                <button
                    type="button"
                    className={cx('gds-button--link button', {
                        hasText: isOpen,
                    })}
                    onClick={openPublisherSelection}>
                    <i className="fas fa-lg fa-newspaper icon" />{' '}
                    {isOpen ? <span>{publisher?.name}</span> : null}
                </button>
            </Tooltip>
        </div>
    ) : null;
};

export default PublisherChangeButton;

import cx from 'classnames';
// Components
import Button from 'gumdrops/Button';
import FormGroup from 'gumdrops/FormGroup';
import Select from 'gumdrops/Select';
import PropTypes from 'prop-types';
import { useState } from 'react';

import ModalWrapper from '../../components/common/ModalWrapper';
import ValidTextArea from '../../components/common/ValidTextArea';
// Constants
import { ZONE_BLOCK_URL_OPTIONS, ZONE_BLOCK_URL_OPTIONS_MAP } from '../../constants/zoneSettings';
import { separateStringByRegex } from '../../helpers/textarea';
import { isEmptyCustom } from '../../helpers/utils';
// Helpers
import { isValidUrl } from '../../helpers/validations';
// Hooks
import { useCreateZoneAssetUrlFilterPatternsMutation } from './zoneApiSlice';
import { useTranslation } from 'react-i18next';

const initialState = {
    urlsList: '',
    selectType: ZONE_BLOCK_URL_OPTIONS.PAGE_URL,
    isInvalid: false,
};

const ZoneBlockURLModal = ({ zoneId, onClose, blockedUrls }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(initialState);
    const { urlsList, selectType, isInvalid } = formData;

    const [createZoneAssetUrlFilterPatterns] = useCreateZoneAssetUrlFilterPatternsMutation();

    const handleChange = ({ target: { name, value } }) => {
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmitUrls = async urls => {
        const isUrlBlocked = (urls, blockedUrls) =>
            urls.some(newUrl => blockedUrls.some(blockedUrl => blockedUrl.pattern === newUrl));

        if (isUrlBlocked(urls, blockedUrls)) {
            onClose(t('zone.overview.blockedUrlsExists'));
            return;
        }

        const params = ZONE_BLOCK_URL_OPTIONS_MAP[selectType];
        const payload = {
            zoneAssetUrlFilterPatternParamList: urls.map(url => ({
                pattern: url,
                ...params,
            })),
        };
        await createZoneAssetUrlFilterPatterns({ zoneId, payload });
        onClose();
    };

    const handleSubmit = () => {
        const [isInvalid, urls] = validateForm();
        if (isInvalid) {
            return;
        }

        handleSubmitUrls(urls);
    };

    const validateForm = () => {
        const urls = separateStringByRegex(urlsList).map(url => url.trim());
        const validations = urls.map(v => isValidUrl(v));

        const isInvalid = validations.includes(false);
        setFormData({ ...formData, isInvalid });

        return [isInvalid, urls];
    };

    const isSubmitDisabled = isEmptyCustom(urlsList);
    const placeholder = 'Enter new line or comma separated URLs in the format https://domain.com';
    return (
        <ModalWrapper
            title="Create New Block Urls"
            bodyClassName="-p-a-3"
            md="6"
            isOpen
            onClose={onClose}
            hideFooter>
            <FormGroup>
                <div className="gds-form-group__input-group">
                    <Select
                        name="selectType"
                        value={selectType}
                        onChange={handleChange}
                        size="sm"
                        options={[
                            {
                                name: 'Image URL',
                                value: ZONE_BLOCK_URL_OPTIONS.IMAGE_URL,
                            },
                            {
                                name: 'Page URL',
                                value: ZONE_BLOCK_URL_OPTIONS.PAGE_URL,
                            },
                        ]}
                    />
                </div>
                <div>
                    <ValidTextArea
                        size="sm"
                        name="urlsList"
                        className="-m-t-2"
                        resize="resize-v"
                        placeholder={placeholder}
                        style={{ minHeight: 100 }}
                        onBlur={validateForm}
                        onChange={handleChange}
                        value={urlsList}
                        isInvalid={isInvalid}
                        errors={[]}
                    />
                </div>
                <div className="-text-right -m-t-2">
                    <Button
                        size="sm"
                        context="success"
                        disabled={isSubmitDisabled}
                        className={cx({ '-disabled': isSubmitDisabled })}
                        onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </FormGroup>
        </ModalWrapper>
    );
};

ZoneBlockURLModal.propTypes = {
    zoneId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

ZoneBlockURLModal.displayName = 'ZoneBlockURLModal';

export default ZoneBlockURLModal;

// Components
import TableData from 'gumdrops/TableData';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableWithLoading from '../../components/common/TableWithLoading';
// Helpers
import { isEmptyCustom } from '../../helpers/utils';

const PreviousPaymentsList = ({ publisherPaymentList, isLoading }) => {
    const { t } = useTranslation();

    const _getColumnWidth = key =>
        ({
            date: '200px',
            description: '300px',
            amount: '250px',
        })[key];

    const _cellDecorator = (value, key) => (
        <TableData key={key}>
            <div className="long-string-wrapper">{!isEmptyCustom(value) ? value : ''}</div>
        </TableData>
    );

    const _dateCellDecorator = (value, key) => (
        <TableData key={key}>
            <div className="long-string-wrapper">{!isEmptyCustom(value) ? value : ''}</div>
        </TableData>
    );

    const _getTableColumns = () => [
        {
            children: t('table.date'),
            headingProps: {
                style: { width: _getColumnWidth('date') },
            },
            key: 'date',
            disableSorting: true,
            dataCellDecorator: _dateCellDecorator,
        },
        {
            children: t('table.description'),
            headingProps: {
                style: { width: _getColumnWidth('description') },
            },
            key: 'description',
            disableSorting: true,
            dataCellDecorator: _cellDecorator,
        },
        {
            children: t('table.amount'),
            headingProps: {
                style: { width: _getColumnWidth('amount') },
            },
            key: 'amount',
            disableSorting: true,
            dataCellDecorator: _cellDecorator,
        },
    ];

    return (
        <TableWithLoading
            data={publisherPaymentList}
            columns={_getTableColumns()}
            isStriped
            size="md"
            className="ds-table--responsive"
            isLoading={isLoading}
        />
    );
};

PreviousPaymentsList.propTypes = {
    publisherPaymentList: PropTypes.array.isRequired,
};

PreviousPaymentsList.defaultProps = {
    publisherPaymentList: [],
};

export { PreviousPaymentsList };

export default PreviousPaymentsList;

// External Libraries
import { useTranslation } from 'react-i18next';

// Gumdrops Components
import { Card, Icon } from 'gumdrops';
import LayoutContainer from 'gumdrops/LayoutContainer';

// Helpers
import { getEnv } from '../../helpers/getEnv';

// Hooks
import { useGetTipaltiUrlQuery } from '../payments/tipaltiApiSlice';

// Local Components
import LoadingOverlay from '../../components/common/LoadingOverlay';

// Store
import { useAppSelector } from '../../store';

const isProduction = getEnv() === 'production';

const PublisherBilling = () => {
    const { t } = useTranslation();
    const { businessUnitId, id: publisherId } = useAppSelector(state => state.publisher);

    const { data: tipaltiUrl } = useGetTipaltiUrlQuery({
        publisherId,
        businessUnitId,
        reportType: 'billing',
    });

    return (
        <LayoutContainer>
            {(isProduction && (
                <div style={{ height: '1200px' }}>
                    {tipaltiUrl ? (
                        <iframe
                            style={{ height: '100%', width: '100%', border: 'none' }}
                            src={tipaltiUrl}
                        />
                    ) : (
                        <LoadingOverlay showOverlay showDots loadingDotsClass="-m-t-3">
                            <h1
                                className="loading-overlay gds-text--header-lg gds-text--bold -p-b-4"
                                style={{ flexFlow: 'unset' }}>
                                Loading reporting
                            </h1>
                        </LoadingOverlay>
                    )}
                </div>
            )) || (
                <Card className="-p-a-3">
                    <Icon fontSize={30} icon="info-circle" className="-va-sub -color-tx-pri-lt-1" />
                    <span style={{}} className="-m-l-2">
                        {t('payments.paymentsNotAvailable')}
                    </span>
                </Card>
            )}
        </LayoutContainer>
    );
};

export default PublisherBilling;

// External libraries
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Constants
import { SLOT_FLEX_SIZE_ID } from '../../constants/app';

// Helpers
import { isEmptyCustom } from '../../helpers/objectUtils';
import { removeEmptyProperties } from '../../helpers/formatFields';

// Hooks
import { useGetSlotImplementationTypesQuery, useGetSlotSizesQuery } from './slotsApiSlice';

// Components
import SlotsCreateModal from './SlotsCreateModal';
import SlotsHeader from './SlotsHeader';
import SlotsList from './SlotsList';

const hasFlexSlot = slots =>
    slots.some(slot => pathOr(null, ['slotSize', 'id'], slot) == SLOT_FLEX_SIZE_ID);

/*
    This component and children are adapted for zone slots only (current implementation in WebApp)
    If publisher slots need to be implemented,
    see publisher manager implementation of this component.
*/
const SlotsManager = ({
    entityId,
    entityType,
    entityName,
    entityLabel,
    exportEndpoint,
    slots,
    isLoading,
    saveSlots,
}) => {
    const navigate = useNavigate();
    const { data: slotSizes = [] } = useGetSlotSizesQuery();
    const { data: slotImplementationTypes = [] } = useGetSlotImplementationTypesQuery();

    const openCreateForm = () => navigate('create');

    const closeCreateForm = () => navigate('slots');

    const handleCreateSlots = slotsList => {
        const slots = slotsList.map(
            ({ name, sizeId: slotSizeId, implementationTypeId, placementId }) =>
                removeEmptyProperties({
                    [entityType]: entityId,
                    slotSizeId,
                    name,
                    implementationTypeId,
                    placementId,
                    enabled: true,
                }),
        );

        saveSlots(entityId, slots, closeCreateForm);
    };

    const getSlotCodeConfig = slotData => {
        const {
            id,
            name,
            implementationType: { id: implementationId },
            slotSize: { width, height },
        } = slotData;
        let message = 'Copy slot code to clipboard';
        let code = '';

        switch (parseInt(implementationId)) {
            case 1: //Javascript
                code = `<!-- ${entityLabel} - Javascript - ${name}- ${width}x${height} -->
                <script src='https://js.gumgum.com/slot.js'></script>
                <div style='width:${width}px;height:${height}px' data-gg-slot='${id}'></div>`;
                break;
            case 2: //Header Bidding
                code = id;
                message = 'Copy slot ID to clipboard';
                break;
            case 3: //Server to Server
                code = `https://g2.gumgum.com/${entityName}/${entityId}/bid`;
                message = 'Copy slot bid URL to clipboard';
                break;
            case 4: //AMP
                code = `<!-- ${entityLabel} - AMP - ${name}- ${width}x${height} -->
                <script src='https://js.gumgum.com/slot.js'></script>
                <div style='width:${width}px;height:${height}px' data-gg-slot='${id}' pl='2'></div>`;
                break;
        }
        return { message, code };
    };

    const getFilteredSizes = () =>
        hasFlexSlot(slots) ? slotSizes.filter(x => x.id != SLOT_FLEX_SIZE_ID) : slotSizes;

    const filteredSlotSizes = getFilteredSizes();

    return (
        <>
            <SlotsHeader
                exportEndpoint={exportEndpoint}
                slotsCount={slots.length}
                toggleCreateForm={openCreateForm}
            />
            <SlotsList
                entityId={entityId}
                slots={slots}
                getSlotCodeConfig={getSlotCodeConfig}
                updateSlots={saveSlots}
                slotImplementationTypes={slotImplementationTypes}
                slotSizes={slotSizes}
                isLoading={isLoading}
            />
            <Routes>
                <Route
                    path="create"
                    element={
                        !isEmptyCustom(filteredSlotSizes) &&
                        !isEmptyCustom(slotImplementationTypes) && (
                            <SlotsCreateModal
                                onCloseModal={closeCreateForm}
                                createSlots={handleCreateSlots}
                                slotImplementationTypes={slotImplementationTypes}
                                slotSizes={filteredSlotSizes}
                                slots={slots}
                            />
                        )
                    }
                />
            </Routes>
        </>
    );
};

SlotsManager.propTypes = {
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    entityType: PropTypes.oneOf(['trackingId', 'publisherId']).isRequired,
    entityName: PropTypes.string.isRequired,
    entityLabel: PropTypes.string.isRequired,
    exportEndpoint: PropTypes.string.isRequired,
    slots: PropTypes.array.isRequired,
    saveSlots: PropTypes.func.isRequired,
};

export { SlotsManager };

export default SlotsManager;

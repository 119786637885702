import { useCallback, useEffect, useState } from 'react';

import { Validator, ValidatorResult } from './types';

interface InputFieldProps {
    initialValue: string | null;
    validators: Validator[];
    listeners?: Array<string | null>;
}

export interface InputState {
    touched: boolean;
    value: string | null;
    valid: boolean;
    errors: ValidatorResult[];
}

const initialInputState = {
    touched: false,
    value: '',
    valid: false,
    errors: [] as ValidatorResult[],
} as InputState;

const useInputField = ({
    initialValue,
    validators,
    listeners = [],
}: InputFieldProps): {
    onChange: (value: string) => void;
    inputState: InputState;
    clearValidation: () => void;
} => {
    const [input, setInput] = useState({ ...initialInputState, value: initialValue });
    const onChange = (value: string) => {
        setInput({
            ...initialInputState,
            value: value,
            touched: true,
        });
    };

    const validate = useCallback(() => {
        const results = [] as ValidatorResult[];

        validators.forEach(validator => {
            const isValid = validator.validate(input.value);
            const message = !isValid ? validator.onError : validator.onValid;

            results.push({
                isValid: isValid,
                message: message,
            });
        });

        setInput({
            ...input,
            valid: !results.some(result => !result.isValid),
            errors: results,
        } as InputState);
    }, [input.value, ...listeners]);

    useEffect(() => {
        if (!input.touched) return;

        validate();
    }, [input.value, ...listeners]);

    const clearValidation = () => {
        setInput({ ...initialInputState, value: initialValue });
    };

    return { onChange, inputState: input, clearValidation };
};

export default useInputField;

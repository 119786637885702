import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';

const Header = () => (
    <header className={styles.header}>
        <Link to="/">
            <img className={styles.logo} src="/assets/logo.svg" />
        </Link>
    </header>
);

export default Header;

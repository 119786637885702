import Card from 'gumdrops/Card';
// Components
import Column from 'gumdrops/Column';
import LayoutContainer from 'gumdrops/LayoutContainer';
import Row from 'gumdrops/Row';
import { Route, Routes, useLocation } from 'react-router-dom';

import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import ContentHeader from '../../components/common/ContentHeader';
import Tabs from '../../components/common/Tabs';
import AdsTxt from './adsTxt/AdsTxt';
import Billing from './Billing';
import InclusiveInventoryProgram from './inclusiveInventory/InclusiveInventoryProgram';
import PasswordUpdate from './PasswordUpdate';

const AccountSettings = ({ tabOptions, isLegacyJustPremium }) => {
    const { pathname } = useLocation();
    const baseUrl = '/account-settings';

    return (
        <LayoutContainer>
            <ContentHeader />
            <Column md={12} className="-m-b-3">
                <Tabs topPath={baseUrl} location={pathname} options={tabOptions} />
                <Row className="-m-b-3">
                    <Column md="12">
                        <Card className="-p-a-3 -m-b-3">
                            <Routes>
                                <Route
                                    path="inclusive-inventory-program"
                                    element={
                                        <AuthorizedRoute
                                            isAuthorized={!isLegacyJustPremium}
                                            element={<InclusiveInventoryProgram />}
                                        />
                                    }
                                />
                                <Route path="authorized-digital-sellers" element={<AdsTxt />} />
                                <Route path="billing" element={<Billing />} />
                                <Route path="password-update" element={<PasswordUpdate />} />
                            </Routes>
                        </Card>
                    </Column>
                </Row>
            </Column>
        </LayoutContainer>
    );
};

export default AccountSettings;

import { baseApi } from '../../api/api';
import { Publisher } from '../../types/Publisher';

export const publisherApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getPublisherById: builder.mutation<Publisher, number>({
            query: id => ({
                url: `/external/publishers/${id}`,
            }),
        }),
    }),
});

export const { useGetPublisherByIdMutation } = publisherApiSlice;

import { useTranslation } from 'react-i18next';

import { isEmptyCustom } from '../helpers/utils';
import { isValidEmail } from '../helpers/validations';
import { Validator } from './types';

const useEmailValidator = (): Validator[] => {
    const { t } = useTranslation();
    const message = t('auth.login.validations.invalidEmail');

    const validate = (value: string | null) =>
        !isEmptyCustom(value) && value && isValidEmail(value) && value.length > 0;

    return [
        {
            onValid: null,
            validate: validate,
            onError: message,
        },
    ] as Validator[];
};

export default useEmailValidator;

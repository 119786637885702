import { useTranslation } from 'react-i18next';

// Helpers
import { isDateAfter } from '../../helpers/date';
import { useAppSelector } from '../../store';

const Footer = ({ isLegacyJustPremium }) => {
    const { t } = useTranslation();
    const publisherCreatedDate = useAppSelector(state => state.publisher.createdAt);
    // GC-1751 requirement
    const isPostNov25Publisher = isDateAfter(publisherCreatedDate, '2022-11-25');

    return (
        <footer className="-m-v-3 -text-center -p-b-6">
            <a
                className="-p-a-2"
                href="http://www.linkedin.com/company/167379"
                target="_blank"
                rel="noreferrer">
                <i className="fa fa-linkedin" />
            </a>
            <a
                className="-p-a-2"
                href="https://twitter.com/gumgum"
                target="_blank"
                rel="noreferrer">
                <i className="fa-brands fa-x-twitter" />
            </a>
            <hr className="-m-v-2 -color-bd-lt-2" />
            <p>
                {t('footer.copyright', {
                    year: new Date().getFullYear(),
                })}
            </p>
            <p>
                <a
                    className="gds-text--link -m-r-2"
                    href={t('footer.faqsUrl')}
                    target="_blank"
                    rel="noreferrer">
                    {t('footer.faqs')}
                </a>
                &nbsp;
                <a
                    className="gds-text--link -m-r-2"
                    href={t('footer.privacyUrl')}
                    target="_blank"
                    rel="noreferrer">
                    {t('footer.privacy')}
                </a>
                &nbsp;
                <a
                    className="gds-text--link"
                    href="https://gumgum.com/terms-and-policies/opt-out"
                    target="_blank"
                    rel="noreferrer">
                    {t('footer.optOut')}
                </a>
            </p>
            <p
                dangerouslySetInnerHTML={{
                    __html: t('footer.termsAndConditions', {
                        termsAndConditionsLink: t(
                            isLegacyJustPremium || isPostNov25Publisher
                                ? 'footer.termsAndConditionsUrlJustPremium'
                                : 'footer.termsAndConditionsUrl',
                        ),
                    }),
                }}
            />
        </footer>
    );
};

export default Footer;

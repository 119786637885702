import React from 'react';

import AuthorizationLayout from '../../../components/layouts/Authorization/AuthorizationLayout';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => (
    <AuthorizationLayout>
        <ForgotPasswordForm />
    </AuthorizationLayout>
);

export default ForgotPasswordPage;

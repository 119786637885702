// External libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'gumdrops/Button';
import Card from 'gumdrops/Card';

// Components
import LayoutContainer from 'gumdrops/LayoutContainer';
import ContentHeader from '../../components/common/ContentHeader';
import QueryableTableWrapper from '../../components/common/QueryableTableWrapper';
import SearchInput from '../../components/common/SearchInput';
import TablePaginationFooter from '../../components/common/TablePaginationFooter';
import ZonesList from './ZonesList';

// Actions
import { sendNotification } from '../../components/notifications/notificationsReducer';
import { updateSort } from '../../helpers/tableQueryUtils';

// Helpers
import { removeStarFromBegining } from '../../helpers/utils';

// Hooks
import useQuery from '../../hooks/useQuery';
import { useSearchZonesMutation } from './zonesApiSlice';

// Store
import { useAppSelector } from '../../store';

const DEFAULT_QUERY_PARAMS = {
    page: '1',
    size: '20',
    direction: 'ASC',
    sortBy: 'name',
};

const Zones = () => {
    const { t } = useTranslation();
    const queryParams = useQuery(DEFAULT_QUERY_PARAMS);
    const navigate = useNavigate();
    const publisherId = useAppSelector(state => state.publisher.id);
    const [zones, setZones] = useState({});
    const { page, searchTerm, size, sortBy, direction } = queryParams;
    const dispatch = useDispatch();
    const [searchZones, { isLoading: isSearchingZones }] = useSearchZonesMutation();

    useEffect(() => {
        _fetchZones();
    }, [page, searchTerm, size, direction, sortBy, publisherId]);

    const _fetchZones = async () => {
        try {
            const zones = await searchZones({
                publisherId,
                filters: {
                    ...DEFAULT_QUERY_PARAMS,
                    ...queryParams,
                    direction,
                    page: page ? parseInt(page) - 1 : 0,
                    ...(searchTerm
                        ? {
                              names: removeStarFromBegining(searchTerm),
                              trackingIds: removeStarFromBegining(searchTerm),
                          }
                        : {}),
                },
            }).unwrap();

            setZones(zones);
        } catch (e) {
            dispatch(
                sendNotification(t('errorMessages.general'), {
                    neverFade: true,
                    context: 'danger',
                }),
            );
        }
    };

    const handleUpdateSort = newSortBy => {
        updateSort(newSortBy, queryParams, navigate);
    };

    const addZoneButton = (
        <div className="-float-right -float-none--sm">
            <Link to="/zones/new" className="-m-b-4-sm -color-tx-white" data-cy="add-zone-button">
                <Button size="xs" context="primary">
                    {t('zones.addZone')}
                </Button>
            </Link>
        </div>
    );

    return (
        <QueryableTableWrapper queryParams={queryParams}>
            {({ handlePageChange, handleSizeChange, handleSearchChange }) => (
                <LayoutContainer>
                    <ContentHeader headerButton={addZoneButton} />
                    <div className="-overflow-hidden -m-b-3">
                        <SearchInput
                            style={{ width: '100%' }}
                            className="-m-b-2"
                            size="xs"
                            name="zones-list-search-input"
                            placeholder={t('zones.searchZonesPlaceholder')}
                            onChange={handleSearchChange}
                            initialValue={searchTerm}
                            isFocused
                            loading={isSearchingZones}
                            hasCloseOption
                            onCloseOptionClick={() => handleSearchChange('')}
                        />
                        <Card>
                            <ZonesList
                                zones={zones}
                                sortBy={sortBy}
                                direction={direction}
                                handleUpdateSort={handleUpdateSort}
                                isLoading={isSearchingZones}
                            />
                            <TablePaginationFooter
                                totalPages={zones.totalPages}
                                totalElements={zones.totalElements}
                                handlePageChange={handlePageChange}
                                handleSizeChange={handleSizeChange}
                                activePage={parseInt(page)}
                                size={parseInt(size)}
                            />
                        </Card>
                    </div>
                </LayoutContainer>
            )}
        </QueryableTableWrapper>
    );
};

export default Zones;

import { useEffect, useState } from 'react';
// @ts-ignore
import { Button, Modal, ModalBody, ModalFooter, ModalForm, ModalHeader } from 'gumdrops';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CategoriesList from './CategoriesList';
import { IABParentCategory } from '../../types/IABCategories';
import useCreateIABRestriction from './useCreateIABRestriction';
import { COMMON_BLOCKED_CATEGORIES } from '../../constants/blockedIabRestrictions';

interface CreateIABRestrictionsProps {
    iabCategories: IABParentCategory[];
}

const CreateIABRestrictions = ({ iabCategories }: CreateIABRestrictionsProps) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [userSelection, setUserSelection] = useState<string[]>([]);
    const [isCommonSelected, setIsCommonSelected] = useState<boolean>(false);
    const [isCommonAvailable, setCommonAvailable] = useState(false);
    const { createRestrictions, isLoading } = useCreateIABRestriction();

    const previousLocation = state?.previousLocation || '/global-advertiser-blocks';

    useEffect(() => {
        const isCommonAvailable = !iabCategories.some(i =>
            COMMON_BLOCKED_CATEGORIES.includes(i.id),
        );
        setCommonAvailable(isCommonAvailable);
    }, []);

    const onCloseHandler = () => {
        navigate(previousLocation);
    };

    const onSaveHandler = async (e: Event) => {
        e.preventDefault();
        await createRestrictions(userSelection);
        navigate(previousLocation);
    };

    /**
     * This function handles the selection and deselection of common blocked categories.
     *
     * When invoked, it checks if all common blocked categories are already selected.
     * If they are, it deselects them by removing them from the user's current selection.
     * If they are not, it selects them by adding them to the user's current selection.
     *
     * It also updates the `isCommonSelected` state to reflect whether the common blocked categories are selected.
     */
    const handleCommonSelection = () => {
        setUserSelection(prevSelection => {
            const select = !COMMON_BLOCKED_CATEGORIES.every(v => prevSelection.includes(v));
            setIsCommonSelected(select);
            return select
                ? [
                      ...new Set([
                          ...removeCommonSubcategories(prevSelection),
                          ...COMMON_BLOCKED_CATEGORIES,
                      ]),
                  ]
                : prevSelection.filter(category => !COMMON_BLOCKED_CATEGORIES.includes(category));
        });
    };

    /**
     * This function handles changes in category selection.
     *
     * @param {string[]} categories - The selected categories.
     * It updates the state of `isCommonSelected` by checking if all common blocked categories are selected.
     * It also updates the `userSelection` state with the new selection of categories.
     */
    const handleCategoryChange = (categories: string[]) => {
        setIsCommonSelected(checkIfEveryCommonSelected(categories));
        setUserSelection(categories);
    };

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <Modal
                        onClose={onCloseHandler}
                        isOpen={true}
                        md={8}
                        shouldCloseOnOverlayClick={false}>
                        <ModalHeader
                            title={t('blocking.iabCategories.select')}
                            onClose={onCloseHandler}
                        />
                        <ModalForm style={{ maxHeight: '80vh' }}>
                            <ModalBody>
                                <div className={'gds-flex gds-flex--justify-between -p-h-2'}>
                                    <p className={'-m-b-3'}>
                                        {t('blocking.iabCategories.selectHint')}
                                    </p>
                                    <div className="gds-flex gds-flex--justify-end -m-b-2">
                                        <Button
                                            size="xs"
                                            style={{ width: 185 }}
                                            context={isCommonSelected ? 'danger' : 'default'}
                                            onClick={handleCommonSelection}
                                            disabled={isCommonAvailable}>
                                            {t(
                                                isCommonSelected
                                                    ? 'blocking.iabCategories.deselectCommon'
                                                    : 'blocking.iabCategories.selectCommon',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                                <CategoriesList
                                    iabCategories={iabCategories}
                                    onChange={categories => handleCategoryChange(categories)}
                                    selectedCategories={userSelection}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    className={'-m-r-1'}
                                    disabled={isLoading}
                                    size="sm"
                                    onClick={onCloseHandler}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isLoading || userSelection.length === 0}
                                    context="primary"
                                    size="sm"
                                    onClick={onSaveHandler}>
                                    Save Changes
                                </Button>
                            </ModalFooter>
                        </ModalForm>
                    </Modal>
                }></Route>
        </Routes>
    );
};

/**
 * This function removes subcategories of common blocked categories from the provided selection.
 *
 * @param {string[]} prevSelection - The previous selection of categories.
 * @returns {string[]} The updated selection of categories with subcategories of common blocked categories removed.
 */
const removeCommonSubcategories = (prevSelection: string[]): string[] => {
    return prevSelection.filter(
        category =>
            !COMMON_BLOCKED_CATEGORIES.some(blockedCategory =>
                category.startsWith(`${blockedCategory}-`),
            ),
    );
};

/**
 * This function checks if all common blocked categories are selected.
 *
 * @param {string[]} categories - The selected categories.
 * @returns {boolean} True if all common blocked categories are selected, false otherwise.
 */
const checkIfEveryCommonSelected = (categories: string[]): boolean => {
    const mainCategories = categories.filter(category => !category.includes('-'));
    return COMMON_BLOCKED_CATEGORIES.every(category => mainCategories.includes(category));
};

export default CreateIABRestrictions;

import { ReactNode } from 'react';
// @ts-ignore
import { Card, Column, Row } from 'gumdrops';

interface ContainerProps {
    children: ReactNode;
}

const Container = ({ children }: ContainerProps) => {
    return (
        <Row className={'-m-b-3'}>
            <Column md={12}>
                <Card className="gds-container -color-bd-lt-2 -p-a-3 -m-b-4">{children}</Card>
            </Column>
        </Row>
    );
};

export default Container;

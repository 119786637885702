import cx from 'classnames';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useDebounceValue, useOnClickOutside } from 'usehooks-ts';

import SearchInput from './SearchInput';
import { SingleSelectOption } from './SingleSelectItem';
import SingleSelectList, { Options } from './SingleSelectList';

interface SearchSingleSelectProps {
    options: Options;
    name: string;
    size: 'xs' | 'sm' | 'md';
    isLoading: boolean;
    placeholder: string;
    style: CSSProperties | undefined;
    onChange: (value: string) => void;
    onSelect: (value: number) => void;
    showClose?: boolean;
    isFocused?: boolean;
    defaultValue?: string;
    debouncedDelay?: number;
}

const SearchSingleSelectV2 = ({
    options,
    name,
    size = 'sm',
    isLoading = false,
    placeholder = 'Search ...',
    style = {},
    onChange,
    onSelect,
    showClose = true,
    isFocused = true,
    debouncedDelay = 300,
    defaultValue = '',
}: SearchSingleSelectProps) => {
    const [selectedValue, setSelectedValue] = useState<SingleSelectOption | null>(null);
    const [searchValue, setSearchValue] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const debouncedValue = useDebounceValue(searchValue, debouncedDelay);

    // handle click outside
    const handleClickOutside = () => {
        setIsOpen(false);
    };

    // handle input onClick event
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    // handle search input change event
    const handleChange = (value: string) => {
        setIsOpen(true);
        setSearchValue(value);
    };

    const handleSelect = (value: SingleSelectOption) => {
        setSearchValue(typeof value.title === 'string' ? value.title : value.name);
        setSelectedValue(value);
        onSelect(value.id);
        setIsOpen(false);
    };

    // handle on click outside
    useOnClickOutside(container, handleClickOutside);

    // call onChange on debouncedValue change
    useEffect(() => {
        const trimmedValue = debouncedValue ? debouncedValue[0].trim() : '';

        if (trimmedValue.length === 1) {
            return;
        }

        // call onChange callback after declared delay
        onChange(trimmedValue);
    }, [debouncedValue[0]]);

    return (
        <div
            className={cx('gds-search-select', {
                'gds-search-select--open': isOpen,
            })}
            ref={container}>
            <SearchInput
                size={size}
                isLoading={isLoading}
                showClose={showClose}
                placeholder={placeholder}
                name={name}
                style={style}
                onClick={handleClick}
                value={searchValue}
                onChange={handleChange}
                isFocused={isFocused}
            />
            <SingleSelectList options={options} onSelect={handleSelect} value={selectedValue} />
        </div>
    );
};

export default SearchSingleSelectV2;

import { baseApi } from '../../api/api';

export const tipaltiApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getTipaltiUrl: builder.query({
            query: ({ publisherId, businessUnitId, reportType }) => ({
                url: `/external/tipalti-url/${reportType}`,
                method: 'POST',
                body: {
                    publisherId: publisherId.toString(),
                    businessUnitId: businessUnitId.toString(),
                },
            }),
            transformResponse: ({ url }) => url,
        }),
    }),
});

export const { useGetTipaltiUrlQuery } = tipaltiApiSlice;

import queryString from 'query-string';

// Constants
import { FILTER_DIRECTION_ASC, FILTER_DIRECTION_DESC } from '../constants/app';
// Helpers
import { removeEmptyProperties } from './utils';

export const pushFilters = (filters, navigate) => {
    navigate({ search: queryString.stringify(removeEmptyProperties(filters)) });
};

export const updateSort = (newSortBy, filters, navigate) => {
    const { sortBy, direction } = filters;
    if (sortBy === newSortBy) {
        const newSortDir =
            direction === FILTER_DIRECTION_DESC ? FILTER_DIRECTION_ASC : FILTER_DIRECTION_DESC;
        return navigate({
            search: queryString.stringify({
                ...removeEmptyProperties(filters),
                sortBy: newSortBy,
                direction: newSortDir,
            }),
        });
    } else {
        return navigate({
            search: queryString.stringify({
                ...removeEmptyProperties(filters),
                sortBy: newSortBy,
                direction: FILTER_DIRECTION_ASC,
            }),
        });
    }
};

export const getSortedColumnHeading = (column, sortBy, direction) => {
    const arrowIcon = direction === FILTER_DIRECTION_ASC ? 'fa fa-caret-down' : 'fa fa-caret-up';
    const hasIcon = column === sortBy;
    return { arrowIcon, hasIcon };
};

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Constants
import navOptions from '../../constants/navOptions';
import { isEmptyCustom } from '../../helpers/utils';
import BreadcrumbWrapper from '../nav/BreadcrumbWrapper';

const ContentHeader = ({ headerButton, editButton, customTitle }) => {
    const { pathname: currentPath } = useLocation();
    const navData = navOptions.find(({ path }) => currentPath.includes(path));
    const { title, children, subPaths } = navData;

    const header = (
        <div className="gds-flex gds-flex--align-end -m-b-3">
            <h2
                className="gds-text--header-md"
                style={{ height: '39.59px' }}
                data-cy="header-title">
                {isEmptyCustom(customTitle) ? title : customTitle}
                {editButton}
            </h2>
        </div>
    );

    const wrapHeader = () => (
        <div className="gds-flex gds-flex--justify-between">
            {header}
            {headerButton}
        </div>
    );

    return (
        <>
            {children || subPaths ? (
                <BreadcrumbWrapper navData={navData} />
            ) : (
                <div style={{ height: '35px' }} />
            )}
            {headerButton ? wrapHeader() : header}
        </>
    );
};

ContentHeader.defaultProps = {
    headerButton: null,
    editButton: null,
    customTitle: '',
};

ContentHeader.propTypes = {
    headerButton: PropTypes.object,
    editButton: PropTypes.object,
    customTitle: PropTypes.string,
};

export default ContentHeader;

import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import SubmitButton from '../../../components/common/SubmitButton';
import FormGroupInput from '../../../components/layouts/FormGroupInput';
import useEmailValidator from '../../../validators/useEmailValidator';
import useInputField from '../../../validators/useInputField';
import { useForgotPasswordMutation } from '../authApiSlice';
import BasicForm from '../BasicForm/BasicForm';
import {
    clearNotifications,
    sendNotification,
} from '../../../components/notifications/notificationsReducer';

const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const emailValidators = useEmailValidator();

    const { onChange: onEmailChange, inputState: emailState } = useInputField({
        initialValue: '',
        validators: emailValidators,
    });

    useEffect(() => {
        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    const [forgotPassword] = useForgotPasswordMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await forgotPassword(emailState.value);
            dispatch(
                sendNotification({
                    text: t('auth.forgotOrResetPassword.validations.tempPassSent'),
                    context: 'success',
                    msToClose: 0,
                }),
            );

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            dispatch(
                sendNotification({
                    text: t('auth.forgotOrResetPassword.validations.somethingWentWrong'),
                    context: 'danger',
                    msToClose: 0,
                }),
            );
        }
    };

    const goToLoginPage = () => {
        navigate('/login');
    };

    const subtitle = `${t('auth.forgotOrResetPassword.forgotSubTitle1')}
        ${t('auth.forgotOrResetPassword.forgotSubTitle2')}`;

    return (
        <BasicForm
            title={t('auth.forgotOrResetPassword.forgotTitle') as string}
            subtitle={subtitle}>
            <form onSubmit={handleSubmit}>
                {/* EMAIL */}
                <FormGroupInput
                    name="email"
                    type="text"
                    value={emailState.value}
                    inputState={emailState}
                    label={t('auth.forgotOrResetPassword.form.email') as string}
                    onChange={onEmailChange}
                />
                {/* SUBMIT */}
                <SubmitButton disabled={!emailState.valid || isLoading}>
                    {isLoading
                        ? (t('common.loading') as string)
                        : (t('auth.forgotOrResetPassword.form.forgotSubmitButton') as string)}
                </SubmitButton>
            </form>
            {/* CANCEL */}
            <div className="-m-t-3 -text-center">
                <button className="gds-text-button" onClick={goToLoginPage}>
                    {t('common.cancel') as string}
                </button>
            </div>

            <Loader show={isLoading} />
        </BasicForm>
    );
};

export default ForgotPasswordForm;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TableData from 'gumdrops/TableData';
import Tooltip from 'gumdrops/Tooltip';

import Alert from '../../components/common/Alert';
import IconButton from '../../components/common/IconButton';
import TableWithLoading from '../../components/common/TableWithLoading';

import { useDeleteZoneBlockedUrlMutation } from './zoneApiSlice';

const ZoneBlockUrlsList = ({ zoneId, blockedUrls, isLoading }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlIdToDelete, setUrlIdToDelete] = useState(null);
    const [deleteZoneBlockedUrl] = useDeleteZoneBlockedUrlMutation();

    const toggleCloneZoneModal = urlId => () => {
        setUrlIdToDelete(urlId);
        setIsModalOpen(true);
    };

    const handleUrlDelete = () => {
        deleteZoneBlockedUrl({ zoneId, urlId: urlIdToDelete });
        setIsModalOpen(false);
    };

    const closeModal = () => setIsModalOpen(false);

    const _actionsDecorator = (urlId, key) => (
        <TableData key={key}>
            <Tooltip position="left" text="Delete Blocked URL">
                <IconButton
                    size="xs"
                    className="-m-a-0 -m-r-2"
                    context="danger"
                    icon="trash-alt"
                    onClick={toggleCloneZoneModal(urlId)}
                />
            </Tooltip>
        </TableData>
    );

    const _getTableColumns = () => [
        {
            key: 'urlType',
            children: t('zone.overview.urlType'),
            headingProps: {
                style: {
                    width: '100px',
                },
            },
        },
        {
            key: 'pattern',
            children: t('zone.overview.urlPattern'),
        },
        {
            children: 'Actions',
            dataCellDecorator: _actionsDecorator,
            key: 'id',
            disableSorting: true,
            headingProps: {
                style: {
                    width: '125px',
                },
            },
        },
    ];

    return (
        <>
            <TableWithLoading
                data={blockedUrls}
                columns={_getTableColumns()}
                isStriped
                size="md"
                isLoading={isLoading}
            />
            {isModalOpen && (
                <Alert
                    title={t('common.proceedWithCaution')}
                    onAccept={handleUrlDelete}
                    onReject={closeModal}
                />
            )}
        </>
    );
};

ZoneBlockUrlsList.propTypes = {
    zoneId: PropTypes.string.isRequired,
    blockedUrls: PropTypes.array,
};

ZoneBlockUrlsList.defaultProps = {
    blockedUrls: [],
};

ZoneBlockUrlsList.displayName = 'ZoneBlockUrlsList';

export default ZoneBlockUrlsList;

import React, { FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../../../components/common/Loader';
import SubmitButton from '../../../components/common/SubmitButton';
import FormGroupInput from '../../../components/layouts/FormGroupInput';

import parseJWT from '../../../helpers/parseJWT';
import { isEmptyCustom } from '../../../helpers/utils';
import { AppDispatch } from '../../../store';
import useEmailValidator from '../../../validators/useEmailValidator';
import useInputField from '../../../validators/useInputField';
import useSimplePasswordValidator from '../../../validators/useSimplePasswordValidator';

import { clearPublisher } from '../../Browse/publisherReducer';
import { useLoginMutation } from '../authApiSlice';
import { logout, setUserData } from '../authReducer';
import BasicForm from '../BasicForm/BasicForm';

import {
    clearNotifications,
    sendNotification,
} from '../../../components/notifications/notificationsReducer';

const LoginForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();
    const emailValidators = useEmailValidator();
    const passwordValidators = useSimplePasswordValidator();

    const { onChange: onEmailChange, inputState: emailState } = useInputField({
        initialValue: '',
        validators: emailValidators,
    });
    const { onChange: onPasswordChange, inputState: passwordState } = useInputField({
        initialValue: '',
        validators: passwordValidators,
    });

    const isFormValid = emailState.valid && passwordState.valid;
    const goToForgotPage = () => {
        navigate('/forgot');
    };

    useEffect(() => {
        // By default, if this page is displayed, empty the storage
        dispatch(logout());
        dispatch(clearPublisher());

        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (isFormValid) {
            try {
                const { jwt } = await login({
                    email: emailState.value,
                    password: passwordState.value,
                }).unwrap();
                const { user } = parseJWT(jwt);
                if (isEmptyCustom(user) || !user.enabled) {
                    dispatch(
                        sendNotification({
                            text: t('auth.login.validations.accountLocked'),
                            context: 'danger',
                            msToClose: 0,
                        }),
                    );
                } else {
                    // Save info in store
                    dispatch(
                        setUserData({
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            isInternal: false,
                            jwt,
                        }),
                    );

                    return navigate('/browse', { replace: true });
                }
            } catch (e: unknown) {
                dispatch(
                    sendNotification({
                        text: t('auth.login.validations.invalidEmailOrPassword'),
                        context: 'danger',
                        msToClose: 0,
                    }),
                );
            }
        }
    };

    return (
        <BasicForm title={t('auth.login.title') as string} subtitle={t('auth.login.subtitle')}>
            <form onSubmit={handleSubmit}>
                {/* EMAIL */}
                <FormGroupInput
                    name="email"
                    type="text"
                    value={emailState.value}
                    inputState={emailState}
                    label={t('auth.login.form.email')}
                    onChange={onEmailChange}
                />
                {/* PASSWORD */}
                <FormGroupInput
                    name="password"
                    type="password"
                    value={passwordState.value}
                    inputState={passwordState}
                    onChange={onPasswordChange}
                    label={t('auth.login.form.password')}
                />
                {/* SUBMIT */}
                <SubmitButton disabled={!isFormValid || isLoading}>
                    {isLoading
                        ? (t('common.loading') as string)
                        : (t('auth.login.form.submitButton') as string)}
                </SubmitButton>

                {/* FORGOT PASSWORD LINK */}
                <div className="-m-t-3 -text-center">
                    <button className="gds-text-button" onClick={goToForgotPage}>
                        {t('auth.login.forgotPassLink') as string}
                    </button>
                </div>
            </form>

            <Loader show={isLoading} />
        </BasicForm>
    );
};

export default LoginForm;

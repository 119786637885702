// External libraries
import * as Sentry from '@sentry/react';

// Styles
import './index.scss';

// Translations
import './translations/i18n';

// React
import React from 'react';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

// React Router
import {
    BrowserRouter as Router,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

// Application-specific modules
import Routes from './features/main/Routes';
import { createRoot } from 'react-dom/client';

// Helpers
import { getEnv } from './helpers/getEnv';

const env = getEnv();

Sentry.init({
    dsn: 'https://ea89e6efaef7dad904cf59fcd62adba4@o130095.ingest.sentry.io/4506552807456768',
    environment: env,
    release: `v${process.env.npm_package_version}`,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <SentryRoutes />
            </Router>
        </PersistGate>
    </Provider>,
);

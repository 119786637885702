import { baseApi } from '../../api/api';
import { IABCategoryResponse, IABParentCategory } from '../../types/IABCategories';

interface Filters {
    language?: string;
}

export const iabCategoriesApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getIabCategories: builder.query<IABParentCategory[], Filters>({
            query: filters => ({
                url: '/external/iab-categories-v1',
                params: filters,
            }),
            transformResponse: (response: IABCategoryResponse[]) => {
                return (
                    response
                        // get only parent categories
                        .filter(category => category.subId === null)
                        // map them to proper object
                        .map(category => {
                            return {
                                id: category.id,
                                name: category.name,
                                label: `${category.name} (${category.id})`,
                                subCategories: response
                                    // get subcategories only for current parent category
                                    .filter(
                                        subCategory =>
                                            subCategory.id.substring(
                                                0,
                                                subCategory.id.indexOf('-'),
                                            ) === category.id,
                                    )
                                    // map them to proper object
                                    .map(subCategory => {
                                        return {
                                            id: subCategory.id,
                                            name: subCategory.name,
                                            parentId: `IAB${subCategory.parentId}`,
                                            label: `${subCategory.name} (${subCategory.id})`,
                                        };
                                    }),
                            } as IABParentCategory;
                        })
                        .sort((a, b) => a.name.localeCompare(b.name)) as IABParentCategory[]
                );
            },
        }),
    }),
});

export const { useGetIabCategoriesQuery } = iabCategoriesApiSlice;

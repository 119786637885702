import Button from 'gumdrops/Button';
import Checkbox from 'gumdrops/Checkbox';
import Column from 'gumdrops/Column';
import FormGroup from 'gumdrops/FormGroup';
import Row from 'gumdrops/Row';
// Components
import Tag from 'gumdrops/Tag';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NoData from '../../components/common/NoData';
import SectionHeader from '../../components/common/SectionHeader';
// Constants
import { DEFAULT_IMPLEMENTATION_TYPE_ID } from '../../constants/app';
import { getAvailableServices } from '../../helpers/getAvailableServices';
// Hooks
import {
    useAddZoneBulkServicesMutation,
    useDeleteZoneServiceMutation,
    useGetZoneServicesQuery,
} from './zoneApiSlice';

const ZoneDetails = ({ zoneId, isApp }) => {
    const { t } = useTranslation();
    const { data: zoneServices = [] } = useGetZoneServicesQuery(zoneId);
    const [addZoneBulkServices, { isLoading: isAddingZoneServices }] =
        useAddZoneBulkServicesMutation();
    const [deleteZoneService, { isLoading: isDeletingZoneService }] =
        useDeleteZoneServiceMutation();
    const [services, setServices] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (zoneServices.length) {
            setServices(zoneServices.map(item => item?.service?.id));
        }
    }, [zoneServices]);

    const handleToggleService = serviceId => () =>
        setServices(
            services.includes(serviceId)
                ? services.filter(item => item != serviceId)
                : [...services, serviceId],
        );

    const handleSave = async () => {
        const servicesToDelete = zoneServices.reduce((servicesToDelete, { service: { id } }) => {
            if (!services.includes(id)) servicesToDelete.push(id);
            return servicesToDelete;
        }, []);

        const servicesToAdd = {
            trackingIds: [zoneId],
            services: services.map(item => ({
                serviceId: item,
                implementationTypeId: DEFAULT_IMPLEMENTATION_TYPE_ID,
            })),
        };

        if (servicesToDelete.length) {
            await Promise.all(
                servicesToDelete.map(serviceId =>
                    deleteZoneService({ serviceId, zoneId, servicesToDelete }),
                ),
            );
        }

        if (services.length) await addZoneBulkServices(servicesToAdd);

        setIsEditing(false);
    };

    return (
        <div className="-m-b-4" data-cy="zone-details">
            <SectionHeader
                title={t('zone.overview.enabledProducts')}
                headerButton={
                    isEditing ? (
                        <div className="gds-flex__item gds-flex--justify-end -text-right">
                            <Button
                                size="xs"
                                context="danger"
                                onClick={handleSave}
                                disabled={isAddingZoneServices || isDeletingZoneService}>
                                {t('common.save')}
                            </Button>
                            <Button
                                className="-m-l-2"
                                size="xs"
                                disabled={isAddingZoneServices || isDeletingZoneService}
                                onClick={() => setIsEditing(false)}>
                                {t('common.cancel')}
                            </Button>
                        </div>
                    ) : (
                        <Button size="xs" onClick={() => setIsEditing(true)}>
                            {t('common.edit')}
                        </Button>
                    )
                }
            />
            <Row className="-p-a-2">
                <Column md={12}>
                    <FormGroup>
                        {isEditing ? (
                            getAvailableServices({ isApp }).map(({ id, key }, index) => (
                                <Checkbox
                                    key={index}
                                    size="xs"
                                    placeholder=""
                                    name="services"
                                    label={t('unit.' + key)}
                                    checked={services.includes(parseInt(id))}
                                    onChange={handleToggleService(parseInt(id))}
                                />
                            ))
                        ) : (
                            <div>
                                {zoneServices.length > 0 ? (
                                    zoneServices.map(({ service: { name } }, i) => (
                                        <Tag
                                            key={i}
                                            size="xs"
                                            context="primary"
                                            text={name
                                                .replace(/ Ads$/, '')
                                                .replace(/Native$/, 'In-Content')}
                                            className="-pointer-events--none"
                                        />
                                    ))
                                ) : (
                                    <NoData
                                        message={t('zone.overview.notFoundProducts')}
                                        size="xs"
                                    />
                                )}
                            </div>
                        )}
                    </FormGroup>
                </Column>
            </Row>
        </div>
    );
};

ZoneDetails.propTypes = {
    zoneId: PropTypes.string.isRequired,
};

export default ZoneDetails;

export const isValidDomain = (v: string | unknown, onlyWildcardAllowed = false) => {
    // this is the standard followed in order to determine if a domain is valid or not
    // https://www.ietf.org/rfc/rfc2181.txt

    if (typeof v !== 'string') return false;

    if (onlyWildcardAllowed && v === '*') return true;

    const parts = v.split('.');
    if (parts.length <= 1 || parts.length > 127) return false;

    //Jira : BD-1613
    //If restriction has only 1 ".", then it should not begin with a wildcard . Example *.com, *.org. We need a report of these for Ad Ops to make decision.
    //Restrictions should not contain a * in middle (only acceptable before leftmost ".").
    if (parts[0] === '*' && parts.length === 2) return false;

    //checks the end of the domain
    const tld = parts.pop();
    const tldRegex = /^[a-zA-Z0-9]+$/gi;
    if (!tldRegex.test(<string>tld)) return false;
    const isValid = parts.map((item, index) => {
        const hostRegex = /^(?!:\/\/)([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/gi;
        return index === 0 && item === '*' ? true : hostRegex.test(item);
    });

    return !isValid.includes(false);
};

export const isValidUrl = (value: string) => {
    const regexp =
        /^(?:(?:(http|https)):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(value);
};

export const isValidEmail = (value: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};

import { ReactNode } from 'react';

interface AuthorizedRouteProps {
    isAuthorized: boolean;
    element: ReactNode;
}

const AuthorizedRoute = ({ isAuthorized, element }: AuthorizedRouteProps) =>
    isAuthorized ? element : null;
export default AuthorizedRoute;

import NoData from '../../components/common/NoData';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { Row, LoadingDots } from 'gumdrops';
import RestrictionButton from '../../components/restrictions/RestrictionButton';
import { IABBlockedCategory, IABParentCategory } from '../../types/IABCategories';

interface BlockedIABCategoriesProps {
    iabCategories: IABParentCategory[];
    isLoading: boolean;
    blockedIABParentCategories: string[];
    blockedIABCategories: IABBlockedCategory[];
}

const BlockedIABCategoriesList = ({
    iabCategories,
    isLoading,
    blockedIABParentCategories,
    blockedIABCategories,
}: BlockedIABCategoriesProps) => {
    const { t } = useTranslation();

    /* return message when loading */
    if (isLoading) {
        return (
            <Row className={'-p-a-4'}>
                <div className="loading-overlay">
                    <LoadingDots />
                </div>
            </Row>
        );
    }

    /* return No Data when no restrictions and categories returned */
    if (!isLoading && (!blockedIABCategories.length || !iabCategories.length)) {
        return <NoData message={t('blocking.iabCategories.none')} />;
    }

    const getBlockedRestrictionsCount = (blockedCategory: string) => {
        if (blockedIABCategories.some(category => category.id === blockedCategory)) {
            return (
                iabCategories.find(category => category.id === blockedCategory)?.subCategories
                    .length || 0
            );
        }

        return (
            blockedIABCategories.filter(category => category.parentId === blockedCategory).length ||
            0
        );
    };

    return (
        <Row className={'-p-a-2'} data-cy="blocked-iab-categories-list">
            {blockedIABParentCategories.map((blockedCategory, index) => (
                <RestrictionButton
                    key={index}
                    modalPath={`iab/${blockedCategory}/edit`}
                    text={
                        iabCategories.find(category => category.id === blockedCategory)?.label || ''
                    }
                    restrictionCount={getBlockedRestrictionsCount(blockedCategory)}
                />
            ))}
        </Row>
    );
};

export default BlockedIABCategoriesList;

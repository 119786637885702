import { useCreateRestrictionsMutation } from '../blocks/restrictionApiSlice';
import { IAB_CATEGORY_ID_V1, PUBLISHERS, ZONES } from '../../constants/app';
import { useAppSelector } from '../../store';
import { useParams } from 'react-router-dom';

const useCreateIABRestriction = (): {
    createRestrictions: (categoryIds: string[]) => Promise<void>;
    isLoading: boolean;
} => {
    const { zoneId } = useParams();
    const [createRestrictions, { isLoading }] = useCreateRestrictionsMutation();
    const { id: publisherId } = useAppSelector(state => state.publisher);
    const { locale } = useAppSelector(state => state.auth);

    const createIABRestriction = async (categoryIds: string[]) => {
        const basePayload = {
            entity: zoneId ? ZONES : PUBLISHERS,
            entityId: zoneId || publisherId,
            restrictionType: IAB_CATEGORY_ID_V1,
            language: locale,
        };

        await createRestrictions({ ...basePayload, restrictionIds: categoryIds });
    };

    return { createRestrictions: createIABRestriction, isLoading };
};

export default useCreateIABRestriction;

// Components
import LoadingDots from 'gumdrops/LoadingDots';
import PropTypes from 'prop-types';

const ExportGenerating = ({ title, generating, handleDownload, handleClose }) => (
    <main
        className="gds-flex gds-flex--direction-row gds-flex--justify-center gds-flex--align-center -text-center"
        style={{ height: '100%' }}>
        <article className="gds-card gds-card--white -p-a-6">
            {generating ? (
                <div>
                    <h3 className="gds-text--header-sm -m-v-4-md">{title}</h3>
                    <LoadingDots className="-p-a-3" />
                </div>
            ) : (
                <div>
                    <h3 className="gds-text--header-sm -m-v-4-md">
                        Your file should be automatically downloaded.
                    </h3>
                    <p className="gds-text--body-md -m-v-4-md -text-center -m-t-4">
                        <button
                            className="gds-button-group__button gds-button--primary gds-button--block-xs "
                            onClick={handleDownload}>
                            Download Again
                        </button>
                        <button
                            className="-m-l-2 gds-button-group__button gds-button--danger gds-button--block-xs"
                            onClick={handleClose}>
                            Close Window
                        </button>
                    </p>
                </div>
            )}
        </article>
    </main>
);

ExportGenerating.propTypes = {
    title: PropTypes.string,
    generating: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDownload: PropTypes.func.isRequired,
};

export default ExportGenerating;
